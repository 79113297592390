import React from 'react';
import { connect } from 'react-redux';

import { Accordion, AccordionItem, MultiSelect } from 'carbon-components-react';
import { updateSelectedSubscriptionCategories } from '../../../reducers/SubscriptionReducer';

const EmailSubscriptionCategoriesAccordion = (props) => {
  const generateSubscriptionCategoriesMultiSelectors = () => {
    return props.subscriptionCategories.map(subscriptionCategory =>
      <div className="bottomPadding" key={subscriptionCategory.categoryId}>
        <div className="bold">
          {subscriptionCategory.categoryName}
        </div>
        &nbsp;
        <MultiSelect
          className='cccMultiSelect'
          key={subscriptionCategory.categoryId}
          id={subscriptionCategory.categoryId}
          label="Select the subscription categories"
          initialSelectedItems={getInitialSelectedItems(subscriptionCategory.categoryId)}
          items={formatAttributeMultiSelectorItems(subscriptionCategory.categoryAttributes)}
          itemToString={item => (item ? item.text : '')}
          onChange={(event) => {
            prepareToUpdateSelectedSubscriptionCategories(props.selectedSubscriptionCategories, subscriptionCategory.categoryId, event.selectedItems)
          }} />
      </div>
    );
  }

  const generateWarningText = () => {
    return (
      <p className="bold red textAlignCenter">
        If you select a subscription category, you will only receive notifications assigned to the desired categories.
      </p>
    );
  }

  const getInitialSelectedItems = (categoryId) => {
    return props.currentSubscriptions && props.currentSubscriptions.length > 0 ? props.currentSubscriptions[0].subscribedCategoryAttributes[categoryId] : [];
  }

  const formatAttributeMultiSelectorItems = (attributeArray) => {
    const arrayOfItems = attributeArray.map(object => {
      return { id: object.attributeId, text: object.attributeName };
    });


    return arrayOfItems;
  }

  const prepareToUpdateSelectedSubscriptionCategories = (subscriptionCategories, categoryId, newValue) => {
    let newSubscriptionCategories = { ...subscriptionCategories, [categoryId]: newValue };

    props.updateSelectedSubscriptionCategories(newSubscriptionCategories);
  }


  return (
    <Accordion className="subscriptionCategoryAccordionContainer">
      <AccordionItem title='Subscription Categories'>
        {generateSubscriptionCategoriesMultiSelectors()}
        &nbsp;
        {props.currentSubscriptions.length > 0 && generateWarningText()}
      </AccordionItem>
    </Accordion>
  );
}

export default connect(
  (state) => ({
    subscriptionCategories: state.subscription.subscriptionCategories,
    currentSubscriptions: state.subscription.currentSubscriptions
  }), {
  updateSelectedSubscriptionCategories
}
)(EmailSubscriptionCategoriesAccordion);