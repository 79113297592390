import React from 'react';
import { connect } from 'react-redux';

import { ComboBox } from 'carbon-components-react';
import { requestToChangeOrg, updateSelectedServiceType } from "../../reducers/UserReducer";
import { compareAlphabetically, createAudienceItems, filterComboItem } from '../Helper/ComponentHelper';

const SolutionCombobox = (props) => {
  const selectInitialValue = () => {
    let selectedText;
    let selectedId = props.envSchema.id;

    for (var index = 0; index < props.userGroupSubOrgs.length; index++) {
      if (props.userGroupSubOrgs[index].id === selectedId) {
        selectedText = props.userGroupSubOrgs[index].name;

        return { id: selectedId, text: selectedText }
      }
    }
  }

  const checkBeforeRequestingOrg = (selectedItem) => {
    if (selectedItem) {
      props.requestToChangeOrg(selectedItem.id);
    }
  }

  return <ComboBox
    className="audienceCombo"
    placeholder='Select the dashboard'
    id='organization-search-combo'
    items={createAudienceItems(props.userGroupSubOrgs).sort(compareAlphabetically)}
    light
    itemToString={item => (item ? item.text : '')}
    shouldFilterItem={(object) => filterComboItem(object)}
    onChange={(event) => checkBeforeRequestingOrg(event.selectedItem)}
  />
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    userGroupSubOrgs: state.user.userGroupSubOrgs
  }),
  {
    updateSelectedServiceType,
    requestToChangeOrg
  }
)(SolutionCombobox);
