import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  SkeletonText
} from 'carbon-components-react';

import { calculateInitialAndFinalDates } from './TimeControlHelper';
import { SUBMISSION_STATUS_SUBMITTED, SUBMISSION_STATUS_FAILED } from '../../reducers/BaseReducer';
import { requestChartData, updateSelectedChartInfoData, updateIsDownloadAllChartsModalOpen, updateTriggerToUpdateCharts } from '../../reducers/StatisticsChartsReducer';
import ChartContainer from './ChartContainer';
import { DownloadIcon } from './IconHelper';
import { transformData } from './StatisticsChartsHelper';

const StatisticsChartsMonitorAccordionItem = (props) => {
  const [charts, setCharts] = useState([]);
  const [initialTimeInMilliseconds, setInitialTimeInMilliseconds] = useState(null);
  const [finalTimeInMilliseconds, setFinalTimeInMilliseconds] = useState(null);
  const [mustUpdate, setMustUpdate] = useState(true);

  useEffect(() => {
    if (props.isOpen) {
      prepareToRequestChartData();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props.chartData && props.chartData[props.assetNumber] && props.chartData[props.assetNumber].charts) {
      setMustUpdate(true);

      setCharts(transformData(props.chartData[props.assetNumber].charts, initialTimeInMilliseconds, finalTimeInMilliseconds))
    }
  }, [props.chartData, props.isOpen, initialTimeInMilliseconds, finalTimeInMilliseconds]);

  useEffect(() => {
    if (props.isOpen && mustUpdate) {
      setMustUpdate(false);

      if (verifyModal()) {
        prepareToRequestChartData();
      }
    }
  }, [props.triggerToUpdateCharts]);

  useEffect(() => {
    if (props.reloadCharts && charts.length > 0) {
      setCharts([]);
    }
  }, [props.reloadCharts]);

  const prepareToRequestChartData = () => {
    let timeObject = {
      ...calculateInitialAndFinalDates(props.selectedTimeControlOption, props.selectedTimeOptionTab,
        props.initialFilterDate, props.initialFilterTime, props.finalFilterDate, props.finalFilterTime)
    };

    setInitialTimeInMilliseconds(timeObject.initialTimeInMilliseconds);
    setFinalTimeInMilliseconds(timeObject.finalTimeInMilliseconds);

    if (verifyModal()) {
      props.requestChartData(timeObject.isoTimeStart, timeObject.isoTimeEnd, props.assetNumber);
    }
  }

  const verifyModal = () => {
    if (props.isModal) {
      return props.isStatisticsChartsModalOpen;
    } else {
      return !props.isStatisticsChartsModalOpen;
    }
  }

  const createCharts = (chart, index) => {
    return <ChartContainer
      chartData={chart}
      index={index}
      initialTimeInMilliseconds={initialTimeInMilliseconds}
      finalTimeInMilliseconds={finalTimeInMilliseconds}
      onClickInfo={onClickInfo}
      reorderDataToMax={reorderDataToMax}
      reorderDataToMin={reorderDataToMin}
      reorderDataToAverage={reorderDataToAverage}
      reorderDataToCurrent={reorderDataToCurrent}
      //TODO: The datasets hidden array is a way to force a re-render trigger since the chart data is equal to the prev value in a '===' comparison
      //We can figure out a way to change the datasets to avoid the '===' comparison between them to be always true, 
      //ensuring the react re-render is triggered
      datasetsHiddenArray={chart.datasets.map(dataset => dataset.hidden)} />
  }

  const createListOfChartsIds = () => {
    let listOfIds = [];

    for (let index = 0; index < charts.length; index++) {
      listOfIds.push(props.assetNumber + charts[index].name.replace(/\s/g, ''));
    }

    return listOfIds;
  }

  const onClickInfo = (chartIndex, datasetIndex) => {
    setCharts(prevCharts => {
      let newCharts = [...prevCharts];
      newCharts[chartIndex].datasets[datasetIndex].hidden = !newCharts[chartIndex].datasets[datasetIndex].hidden;
      return newCharts;
    });
  };

  const reorderDataToMin = (minOrder, chartIndex) => {
    let data = { ...charts[chartIndex] };

    if (minOrder) {
      data.datasets.sort((a, b) => (Math.min(...a.data) > Math.min(...b.data) ? 1 : -1));
    } else {
      data.datasets.sort((a, b) => (Math.min(...a.data) < Math.min(...b.data) ? 1 : -1));
    }

    setCharts(prevCharts => {
      prevCharts[chartIndex] = data;

      return prevCharts;
    });
  }

  const reorderDataToMax = (maxOrder, chartIndex) => {
    let data = { ...charts[chartIndex] };

    if (maxOrder) {
      data.datasets.sort((a, b) => (Math.max(...a.data) > Math.max(...b.data) ? 1 : -1));
    } else {
      data.datasets.sort((a, b) => (Math.max(...a.data) < Math.max(...b.data) ? 1 : -1));
    }

    setCharts(prevCharts => {
      prevCharts[chartIndex] = data;

      return prevCharts;
    });
  }

  const reorderDataToAverage = (averageOrder, chartIndex) => {
    let data = { ...charts[chartIndex] };

    if (averageOrder) {
      data.datasets.sort((a, b) => ((a.data.reduce((c, d) => c + d, null) / a.data.length) > (b.data.reduce((c, d) => c + d, null) / b.data.length) ? 1 : -1));
    } else {
      data.datasets.sort((a, b) => ((a.data.reduce((c, d) => c + d, null) / a.data.length) < (b.data.reduce((c, d) => c + d, null) / b.data.length) ? 1 : -1));
    }

    setCharts(prevCharts => {
      prevCharts[chartIndex] = data;

      return prevCharts;
    });
  }

  const reorderDataToCurrent = (currentOrder, chartIndex) => {
    let data = { ...charts[chartIndex] };

    if (currentOrder) {
      data.datasets.sort((a, b) => (a.data[a.data.length - 1] > b.data[b.data.length - 1] ? 1 : -1));
    } else {
      data.datasets.sort((a, b) => (a.data[a.data.length - 1] < b.data[b.data.length - 1] ? 1 : -1));
    }

    setCharts(prevCharts => {
      prevCharts[chartIndex] = data;

      return prevCharts;
    });
  }

  const openDownloadAllChartsModal = () => {
    props.updateSelectedChartInfoData({
      chartName: charts.name,
      assetNumber: props.selectedMonitorAssetNumber,
      chartIds: createListOfChartsIds(),
      initialTimeInMilliseconds: initialTimeInMilliseconds,
      finalTimeInMilliseconds: finalTimeInMilliseconds
    });

    props.updateIsDownloadAllChartsModalOpen(true);
  }

  return (
    <div>
      {(props.loadChartDataStatus === SUBMISSION_STATUS_SUBMITTED || props.loadChartDataStatus === SUBMISSION_STATUS_FAILED) ?
        <SkeletonText /> :
        <React.Fragment>
          <div className="reverseRow">
            <Button
              renderIcon={DownloadIcon}
              onClick={() => openDownloadAllChartsModal()}>
              Download All Charts
            </Button>
          </div>
          {charts.map((chart, index) => createCharts(chart, index))}
        </React.Fragment>
      }
    </div>
  );
}

export default connect(
  (state) => ({
    chartData: state.statisticCharts.chartData,
    selectedTimeControlOption: state.statisticCharts.selectedTimeControlOption,
    selectedTimeOptionTab: state.statisticCharts.selectedTimeOptionTab,
    initialFilterDate: state.statisticCharts.initialFilterDate,
    initialFilterTime: state.statisticCharts.initialFilterTime,
    finalFilterDate: state.statisticCharts.finalFilterDate,
    finalFilterTime: state.statisticCharts.finalFilterTime,
    selectedMonitorAssetNumber: state.statisticCharts.selectedMonitorAssetNumber,
    loadChartDataStatus: state.statisticCharts.loadChartDataStatus,
    triggerToUpdateCharts: state.statisticCharts.triggerToUpdateCharts,
    reloadCharts: state.statisticCharts.reloadCharts
  }), {
  requestChartData,
  updateIsDownloadAllChartsModalOpen,
  updateTriggerToUpdateCharts,
  updateSelectedChartInfoData
}
)(StatisticsChartsMonitorAccordionItem);