import { isManualMonitoring } from "../components/Helper/ComponentHelper";
import {headers} from "./BaseServices" ;

export const fetchMonitorData = (orgId, org) => {
  let url = `/monitor/iotm/api/v1/status/environment/${orgId}`;

  if(org) {
    if (isManualMonitoring(org)) {
      url += '/manual';
    }
  }

  return fetch(url, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}