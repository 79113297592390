import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import CommunicationsToolbarDatePickerArea from './CommunicationsToolbarDatePickerArea';
import CommunicationsToolbarCategoryButton from './CommunicationsToolbarCategoryButton';
import { BANNER_CONTENT_SWITCHER_VALUES, STATUS_CONTENT_SWITCHER_VALUES } from '../CommunicationsHelper';
import CommunicationsToolbarSubCategoryButton from './CommunicationsToolbarSubCategoryButton';
import CommunicationsToolbarStatusButton from './CommunicationsToolbarStatusButton';

const CommunicationsToolbarFilterArea = (props) => {
  const [formattedSubCategoryArray, setFormattedSubCategoryArray] = useState([]);

  useEffect(() => {
    if (props.categoryFilters.length > 0 && props.notificationSubCategories.length > 0) {
      let formattedArray = [];

      const selectedCategories = Object.keys(props.categoryFilters)
        .filter(key => props.categoryFilters[key])
        .map(key => ["Announcement", "Maintenance", "Incident", "Security"][key]);

      formattedArray = props.notificationSubCategories
        .filter(item => selectedCategories.includes(item.parentCategory))
        .map(item => ({ parentCategory: item.parentCategory, subCategory: item.subCategory, id: item.id }));

      setFormattedSubCategoryArray(formattedArray);
    }
  }, [props.categoryFilters, props.notificationSubCategories]);

  const createCategoryRadioButtons = () => {
    return BANNER_CONTENT_SWITCHER_VALUES.map(item =>
      <CommunicationsToolbarCategoryButton item={item} />
    );
  }

  const createSubCategoryRadioButtons = () => {
    return formattedSubCategoryArray.map(({ id, parentCategory, subCategory }) => (
      <CommunicationsToolbarSubCategoryButton
        id={id}
        parentCategory={parentCategory}
        subCategory={subCategory} />
    ));
  }

  const createStatusRadioButtons = () => {
    return STATUS_CONTENT_SWITCHER_VALUES.map(item =>
      <CommunicationsToolbarStatusButton
        item={item}
        statusFilters={props.statusFilters}
        updateStatusFiltersControl={props.updateStatusFiltersControl} />
    );
  }

  return (
    <div className="filterContainer">
      <div className="filterArea">
        <CommunicationsToolbarDatePickerArea
          initialDateFilter={props.initialDateFilter}
          initialTimeFilter={props.initialTimeFilter}
          finalDateFilter={props.finalDateFilter}
          finalTimeFilter={props.finalTimeFilter}
          updateInitialDateFilter={props.updateInitialDateFilter}
          updateInitialTimeFilter={props.updateInitialTimeFilter}
          updateFinalDateFilter={props.updateFinalDateFilter}
          updateFinalTimeFilter={props.updateFinalTimeFilter} />
        {props.isNotificationData && <React.Fragment><div className="radioButtonTitle">
          Categories:
        </div>
          <div className="radioButtonArea">
            {createCategoryRadioButtons()}
          </div>
          {formattedSubCategoryArray.length > 0 && <React.Fragment>
            <div className="radioButtonTitle">
              Sub Categories:
            </div>
            <div className="radioButtonArea">
              {createSubCategoryRadioButtons()}
            </div>
          </React.Fragment>}
        </React.Fragment>}
        <div className="radioButtonTitle">
          Status:
        </div>
        <div className="radioButtonArea">
          {createStatusRadioButtons()}
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    categoryFilters: state.communications.categoryFiltersControl,
    notificationSubCategories: state.communications.notificationSubCategories
  }), {
}
)(CommunicationsToolbarFilterArea);