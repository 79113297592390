import React from 'react';
import { connect } from 'react-redux';

import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  Tabs,
  Tab,
  Button
} from 'carbon-components-react';

import BigTableDataTable from './BigTableDataTable';
import CurrentStatusComponent from './CurrentStatusComponent';
import WarningDetailsComponent from './WarningDetailsComponent';
import PerformanceTablesMonitorCombobox from './PerformanceTablesMonitorCombobox';
import { shouldShowPerformanceTablesComponent } from './PerformanceTablesHelper';

const PerformanceTablesModal = (props) => {
  return (
    <div className="desktopOnly">
      <ComposedModal
        open={props.isModalOpen}
        onClose={() => props.closeModal(false)}>
        <ModalHeader
          title='Performance Tables'
          iconDescription='Close Modal'
          buttonOnClick={() => props.closeModal(false)} />
        <ModalBody className="modalBody scrollY">
          <React.Fragment>
            <div className='flex'>
              <PerformanceTablesMonitorCombobox
                requestPerformanceTablesData={props.requestPerformanceTablesData} />
              &nbsp;
              {props.selectedMonitorId &&
                <Button onClick={() => props.requestPerformanceTablesData(props.selectedMonitorId)}>
                  Reload
                </Button>
              }
            </div>
            <div className={props.selectedMonitorId ? 'height16' : 'height400'} />
            {props.selectedMonitorId &&
              <Tabs className='horizontalScroll' selected={props.tabIndex} fullWidth onSelectionChange={(index) => props.setTabIndex(index)}>
                {shouldShowPerformanceTablesComponent(props.envSchema.performanceTables, props.envSchema.performanceTables.shouldShowCurrentStatus) &&
                  <Tab key="0" label="Current Status">
                    <CurrentStatusComponent />
                  </Tab>}
                {shouldShowPerformanceTablesComponent(props.envSchema.performanceTables, props.envSchema.performanceTables.shouldShowCurrentStatus) &&
                  <Tab key="1" label="Warning Details">
                    <WarningDetailsComponent />
                  </Tab>}
                {shouldShowPerformanceTablesComponent(props.envSchema.performanceTables, props.envSchema.performanceTables.shouldShowCurrentStatus) &&
                  <Tab key="2" label="Big Tables">
                    <BigTableDataTable />
                  </Tab>}
              </Tabs>
            }
          </React.Fragment>
        </ModalBody>
      </ComposedModal>
    </div>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    selectedMonitorId: state.performanceTables.selectedMonitorId
  }), {
}
)(PerformanceTablesModal);