import { DEFAULT_TIME_CONTROL_OPTION } from '../components/StatisticsCharts/TimeControlHelper';
import { fetchMonitorNames, fetchChartData } from '../lib/StatisticChartsServices';
import {
  SUBMISSION_STATUS_INITIAL_STATUS,
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_SUCCESS,
  SUBMISSION_STATUS_SUBMITTED
} from './BaseReducer';

const initState = {
  chartData: {},
  monitorNamesData: [],
  selectedMonitorAssetNumber: null,
  chartsNamesData: [],
  tooltipEnabled: true,
  selectedTimeControlOption: DEFAULT_TIME_CONTROL_OPTION,
  timeControlAreaOpened: false,
  selectedTimeOptionTab: 0,
  initialFilterDate: '',
  initialFilterTime: '',
  finalFilterDate: '',
  finalFilterTime: '',
  isChartUpdateConfirmationModalOpen: false,
  triggerToUpdateCharts: false,
  reloadCharts: false,
  chartsToUpdateParameter: '',
  isDownloadChartModalOpen: false,
  shouldDownloadAllCharts: false,
  selectedChartInfoData: {},
  isChartInfoModalOpen: false,

  loadChartDataStatus: SUBMISSION_STATUS_INITIAL_STATUS
}

const STATISTICS_CHARTS_UPDATE_CHART_DATA = 'STATISTICS_CHARTS_UPDATE_CHART_DATA';
export const loadChartDataAction = (newChartData) => ({ type: STATISTICS_CHARTS_UPDATE_CHART_DATA, payload: newChartData });

const STATISTICS_CHARTS_UPDATE_MONITOR_NAMES_DATA = 'STATISTICS_CHARTS_UPDATE_MONITOR_NAMES_DATA';
export const loadMonitorNamesDataAction = (newMonitorNamesData) => ({ type: STATISTICS_CHARTS_UPDATE_MONITOR_NAMES_DATA, payload: newMonitorNamesData });

const STATISTICS_CHARTS_UPDATE_MONITOR_NAMES_DATA_VISIBILITY = 'STATISTICS_CHARTS_UPDATE_MONITOR_NAMES_DATA_VISIBILITY';
export const loadMonitorNamesDataVisibilityAction = (newMonitorNamesData) => ({ type: STATISTICS_CHARTS_UPDATE_MONITOR_NAMES_DATA_VISIBILITY, payload: newMonitorNamesData });

const STATISTICS_CHARTS_UPDATE_SELECTED_MONITOR_ASSET_NUMBER = 'STATISTICS_CHARTS_UPDATE_SELECTED_MONITOR_ASSET_NUMBER';
export const updateSelectedMonitorAssetAction = (newValue) => ({ type: STATISTICS_CHARTS_UPDATE_SELECTED_MONITOR_ASSET_NUMBER, payload: newValue });

const STATISTICS_CHARTS_UPDATE_CHART_NAMES_DATA = 'STATISTICS_CHARTS_UPDATE_CHART_NAMES_DATA';
export const loadChartNamesDataAction = (newChartNamesData) => ({ type: STATISTICS_CHARTS_UPDATE_CHART_NAMES_DATA, payload: newChartNamesData });

const STATISTICS_CHARTS_UPDATE_TOOLTIP_ENABLED = 'STATISTICS_CHARTS_UPDATE_TOOLTIP_ENABLED';
export const updateTooltipEnabledAction = (newTooltipValue) => ({ type: STATISTICS_CHARTS_UPDATE_TOOLTIP_ENABLED, payload: newTooltipValue });

const STATISTICS_CHARTS_UPDATE_SELECTED_TIME_CONTROL_OPTION = 'STATISTICS_CHARTS_UPDATE_SELECTED_TIME_CONTROL_OPTION';
export const updateSelectedTimeControlOptionAction = (newValue) => ({ type: STATISTICS_CHARTS_UPDATE_SELECTED_TIME_CONTROL_OPTION, payload: newValue });

const STATISTICS_CHARTS_UPDATE_TIME_CONTROL_AREA_OPENED = 'STATISTICS_CHARTS_UPDATE_TIME_CONTROL_AREA_OPENED';
export const updateTimeControlAreaOpenedAction = (isTimeControlAreaOpened) => ({ type: STATISTICS_CHARTS_UPDATE_TIME_CONTROL_AREA_OPENED, payload: isTimeControlAreaOpened });

const STATISTICS_CHARTS_UPDATE_SELECTED_TIME_OPTION_TAB = 'STATISTICS_CHARTS_UPDATE_SELECTED_TIME_OPTION_TAB';
export const updateSelectedTimeOptionTabAction = (newIndex) => ({ type: STATISTICS_CHARTS_UPDATE_SELECTED_TIME_OPTION_TAB, payload: newIndex });

const STATISTICS_CHARTS_UPDATE_INITIAL_FILTER_DATE = 'STATISTICS_CHARTS_UPDATE_INITIAL_FILTER_DATE';
export const updateInitialFilterDateAction = (newIndex) => ({ type: STATISTICS_CHARTS_UPDATE_INITIAL_FILTER_DATE, payload: newIndex });

const STATISTICS_CHARTS_UPDATE_INITIAL_FILTER_TIME = 'STATISTICS_CHARTS_UPDATE_INITIAL_FILTER_TIME';
export const updateInitialFilterTimeAction = (newIndex) => ({ type: STATISTICS_CHARTS_UPDATE_INITIAL_FILTER_TIME, payload: newIndex });

const STATISTICS_CHARTS_UPDATE_FINAL_FILTER_DATE = 'STATISTICS_CHARTS_UPDATE_FINAL_FILTER_DATE';
export const updateFinalFilterDateAction = (newIndex) => ({ type: STATISTICS_CHARTS_UPDATE_FINAL_FILTER_DATE, payload: newIndex });

const STATISTICS_CHARTS_UPDATE_FINAL_FILTER_TIME = 'STATISTICS_CHARTS_UPDATE_FINAL_FILTER_TIME';
export const updateFinalFilterTimeAction = (newIndex) => ({ type: STATISTICS_CHARTS_UPDATE_FINAL_FILTER_TIME, payload: newIndex });

const STATISTICS_CHARTS_UPDATE_IS_CHART_UPDATE_CONFIRMATION_MODAL_OPEN = 'STATISTICS_CHARTS_UPDATE_IS_CHART_UPDATE_CONFIRMATION_MODAL_OPEN';
export const updateIsChartUpdateConfirmationModalOpenAction = (newIndex) => ({ type: STATISTICS_CHARTS_UPDATE_IS_CHART_UPDATE_CONFIRMATION_MODAL_OPEN, payload: newIndex });

const STATISTICS_CHARTS_UPDATE_FILTER_DATA = 'STATISTICS_CHARTS_UPDATE_FILTER_DATA';
export const updateFilterDataAction = (newFilterData) => ({ type: STATISTICS_CHARTS_UPDATE_FILTER_DATA, payload: newFilterData });

const STATISTICS_CHARTS_UPDATE_SINGLE_CHART_DATA = 'STATISTICS_CHARTS_UPDATE_SINGLE_CHART_DATA';
export const loadSingleChartDataAction = (newChartData) => ({ type: STATISTICS_CHARTS_UPDATE_SINGLE_CHART_DATA, payload: newChartData });

const STATISTICS_CHARTS_UPDATE_SINGLE_CHART_DATA_WITH_PARAMETER = 'STATISTICS_CHARTS_UPDATE_SINGLE_CHART_DATA_WITH_PARAMETER';
export const loadSingleChartDataWithParameterAction = (newChartData) => ({ type: STATISTICS_CHARTS_UPDATE_SINGLE_CHART_DATA_WITH_PARAMETER, payload: newChartData });

const STATISTICS_CHARTS_UPDATE_TRIGGER_UPDATE_CHARTS_FLAG = 'STATISTICS_CHARTS_UPDATE_TRIGGER_UPDATE_CHARTS_FLAG';
export const updateTriggerToUpdateChartsAction = (newFlag) => ({ type: STATISTICS_CHARTS_UPDATE_TRIGGER_UPDATE_CHARTS_FLAG, payload: newFlag });

const STATISTICS_CHARTS_UPDATE_RELOAD_CHARTS_FLAG = 'STATISTICS_CHARTS_UPDATE_RELOAD_CHARTS_FLAG';
export const updateReloadChartsAction = (newFlag) => ({ type: STATISTICS_CHARTS_UPDATE_RELOAD_CHARTS_FLAG, payload: newFlag });

const STATISTICS_CHARTS_UPDATE_CHARTS_TO_UPDATE_PARAMETER = 'STATISTICS_CHARTS_UPDATE_CHARTS_TO_UPDATE_PARAMETER';
export const updateChartsToUpdateParameterAction = (newValue) => ({ type: STATISTICS_CHARTS_UPDATE_CHARTS_TO_UPDATE_PARAMETER, payload: newValue });

const STATISTICS_CHARTS_UPDATE_IS_DOWNLOAD_CHART_MODAL_OPEN = 'STATISTICS_CHARTS_UPDATE_IS_DOWNLOAD_CHART_MODAL_OPEN';
export const updateIsDownloadChartModalOpenAction = (newValue) => ({ type: STATISTICS_CHARTS_UPDATE_IS_DOWNLOAD_CHART_MODAL_OPEN, payload: newValue });

const STATISTICS_CHARTS_UPDATE_SHOULD_DOWNLOAD_ALL_CHARTS = 'STATISTICS_CHARTS_UPDATE_SHOULD_DOWNLOAD_ALL_CHARTS';
export const updateShouldDownloadAllChartsAction = (newValue) => ({ type: STATISTICS_CHARTS_UPDATE_SHOULD_DOWNLOAD_ALL_CHARTS, payload: newValue });

const STATISTICS_CHARTS_UPDATE_SELECTED_CHART_INFO_DATA = 'STATISTICS_CHARTS_UPDATE_SELECTED_CHART_INFO_DATA';
export const updateSelectedChartInfoDataAction = (newValue) => ({ type: STATISTICS_CHARTS_UPDATE_SELECTED_CHART_INFO_DATA, payload: newValue });

const STATISTICS_CHARTS_UPDATE_LOAD_CHART_DATA_STATUS = 'STATISTICS_CHARTS_UPDATE_LOAD_CHART_DATA_STATUS';
export const loadChartDataStatusAction = (newChartDataStatus) => ({ type: STATISTICS_CHARTS_UPDATE_LOAD_CHART_DATA_STATUS, payload: newChartDataStatus });

const STATISTICS_CHARTS_UPDATE_IS_CHART_INFO_MODAL_OPEN = 'STATISTICS_CHARTS_UPDATE_IS_CHART_INFO_MODAL_OPEN';
export const updateIsChartInfoModalOpenAction = (newValue) => ({ type: STATISTICS_CHARTS_UPDATE_IS_CHART_INFO_MODAL_OPEN, payload: newValue });

export const getMonitorNamesData = (envSchemaId, isARefresherAction) => {
  return (dispatch, getState) => {
    dispatch(updateTimeControlAreaOpenedAction(false));
    dispatch(loadChartDataStatusAction(SUBMISSION_STATUS_SUBMITTED));

    let prevMonitorNamesData = getState().statisticCharts.monitorNamesData;

    fetchMonitorNames(envSchemaId)
      .then(data => {
        if (Array.isArray(data) && typeof (data[0]) === 'object') {
          let chartObject = {};
          data.map((chartData, index) => {
            //We maintain the accordion open if it's a refresher
            chartData.visibility = isARefresherAction ? prevMonitorNamesData[index].visibility : false;
            chartObject[chartData.assetNum] = [];
          });
          dispatch(loadChartDataStatusAction(SUBMISSION_STATUS_SUCCESS));
          dispatch(loadMonitorNamesDataAction(data));
          dispatch(loadChartDataAction(chartObject));
        } else {
          dispatch(loadChartDataStatusAction(SUBMISSION_STATUS_FAILED));
          dispatch(loadMonitorNamesDataAction([]));
          dispatch(loadChartDataAction([]));
        }
      })
      .catch(error => {
        dispatch(loadChartDataStatusAction(SUBMISSION_STATUS_FAILED));
        dispatch(loadMonitorNamesDataAction([]));
        dispatch(loadChartDataAction([]));
      });
  }
}

export const updateMonitorNamesDataVisibility = (index, value) => {
  return (dispatch) => {
    dispatch(loadMonitorNamesDataVisibilityAction({ index, value }));
  }
}

export const updateSelectedMonitorAssetNumber = (value) => {
  return (dispatch) => {
    dispatch(updateSelectedMonitorAssetAction(value));
  }
}

export const requestChartData = (isoTime, isoTimeEnd, assetNumber) => {
  return (dispatch, getState) => {
    let chartsToUpdateParameter = getState().statisticCharts.chartsToUpdateParameter;

    dispatch(updateTimeControlAreaOpenedAction(false));
    dispatch(loadChartDataStatusAction(SUBMISSION_STATUS_SUBMITTED));

    if (!chartsToUpdateParameter) {
      dispatch(updateReloadChartsAction(true));
    }

    fetchChartData(assetNumber, isoTime, isoTimeEnd, chartsToUpdateParameter)
      .then(data => {
        dispatch(loadChartDataStatusAction(SUBMISSION_STATUS_SUCCESS));

        if (chartsToUpdateParameter) {
          dispatch(loadSingleChartDataWithParameterAction({ assetNumber: assetNumber, data: data }));
          //Reload must be after the data request in the parameter case
          dispatch(updateReloadChartsAction(true));
        } else {
          dispatch(loadSingleChartDataAction({ assetNumber: assetNumber, data: data }));
          //If no parameter was specified, we can consider that all of the charts were downloaded in the correct order. We get their names from that
          if (data.charts) {
            dispatch(loadChartNamesDataAction(data.charts.map(chart => chart.name)));
          }
        }

        dispatch(updateReloadChartsAction(false));

        dispatch(updateTriggerToUpdateChartsAction(false));
      })
      .catch(error => {
        dispatch(loadChartDataStatusAction(SUBMISSION_STATUS_FAILED));
        dispatch(updateReloadChartsAction(false));
        dispatch(loadSingleChartDataAction({ assetNumber: assetNumber, data: [] }));
        dispatch(loadChartNamesDataAction([]));
        dispatch(updateTriggerToUpdateChartsAction(false));
      });
  }
}

export const resetChartNamesData = () => {
  return (dispatch) => {
    dispatch(loadChartNamesDataAction([]));
  }
}

export const toggleTooltipEnabled = () => {
  return (dispatch, getState) => {
    let tooltipEnabled = !getState().statisticCharts.tooltipEnabled;

    dispatch(updateTooltipEnabledAction(tooltipEnabled));
  }
}

export const updateSelectedTimeControlOption = (value) => {
  return (dispatch) => {
    dispatch(updateSelectedTimeControlOptionAction(value));
  }
}

export const toggleTimeControlAreaOpened = () => {
  return (dispatch, getState) => {
    let timeControlAreaOpened = !getState().statisticCharts.timeControlAreaOpened;

    dispatch(updateTimeControlAreaOpenedAction(timeControlAreaOpened));
  }
}

export const updateSelectedTimeOptionTab = (index) => {
  return (dispatch) => {
    dispatch(updateSelectedTimeOptionTabAction(index));
  }
}

export const updateInitialFilterDate = (value) => {
  return (dispatch) => {
    dispatch(updateInitialFilterDateAction(value));
  }
}

export const updateInitialFilterTime = (value) => {
  return (dispatch) => {
    dispatch(updateInitialFilterTimeAction(value));
  }
}

export const updateFinalFilterDate = (value) => {
  return (dispatch) => {
    dispatch(updateFinalFilterDateAction(value));
  }
}

export const updateFinalFilterTime = (value) => {
  return (dispatch) => {
    dispatch(updateFinalFilterTimeAction(value));
  }
}

export const updateIsChartUpdateConfirmationModalOpen = (value) => {
  return (dispatch) => {
    dispatch(updateIsChartUpdateConfirmationModalOpenAction(value));
  }
}

export const applyTimeControlData = (value) => {
  return (dispatch) => {
    dispatch(updateTimeControlAreaOpenedAction(false));
    dispatch(updateFilterDataAction(value));
  }
}

export const updateTriggerToUpdateCharts = (value) => {
  return (dispatch) => {
    dispatch(updateTriggerToUpdateChartsAction(value));
  }
}

export const resetDateFilterValues = () => {
  return (dispatch) => {
    dispatch(updateFilterDataAction({ initialFilterDate: '', initialFilterTime: '', finalFilterDate: '', finalFilterTime: '' }));
  }
}

export const updateChartsToUpdateParameter = (parameter) => {
  return (dispatch) => {
    dispatch(updateChartsToUpdateParameterAction(parameter));
  }
}

export const updateIsDownloadChartModalOpen = (value) => {
  return (dispatch) => {
    dispatch(updateShouldDownloadAllChartsAction(false));
    dispatch(updateIsDownloadChartModalOpenAction(value));
  }
}

export const updateIsDownloadAllChartsModalOpen = (value) => {
  return (dispatch) => {
    dispatch(updateShouldDownloadAllChartsAction(true));
    dispatch(updateIsDownloadChartModalOpenAction(value));
  }
}

export const updateSelectedChartInfoData = (value) => {
  return (dispatch) => {
    dispatch(updateSelectedChartInfoDataAction(value));
  }
}

export const updateIsChartInfoModalOpen = (value) => {
  return (dispatch) => {
    dispatch(updateIsChartInfoModalOpenAction(value));
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case STATISTICS_CHARTS_UPDATE_CHART_DATA:
      return { ...state, chartData: action.payload }

    case STATISTICS_CHARTS_UPDATE_MONITOR_NAMES_DATA:
      return { ...state, monitorNamesData: action.payload }

    case STATISTICS_CHARTS_UPDATE_MONITOR_NAMES_DATA_VISIBILITY:
      let newMonitorData = [...state.monitorNamesData];

      newMonitorData[action.payload.index].visibility = action.payload.value;

      return { ...state, monitorNamesData: newMonitorData };

    case STATISTICS_CHARTS_UPDATE_SELECTED_MONITOR_ASSET_NUMBER:
      return { ...state, selectedMonitorAssetNumber: action.payload }

    case STATISTICS_CHARTS_UPDATE_CHART_NAMES_DATA:
      return { ...state, chartsNamesData: action.payload }

    case STATISTICS_CHARTS_UPDATE_SINGLE_CHART_DATA:
      let chartSingleData = { ...state.chartData };

      chartSingleData[action.payload.assetNumber] = action.payload.data

      return { ...state, chartData: { ...chartSingleData } };

    case STATISTICS_CHARTS_UPDATE_SINGLE_CHART_DATA_WITH_PARAMETER:
      let oldData = { ...state.chartData };

      let newDataCharts = action.payload.data.charts;

      let chartNames = state.chartsToUpdateParameter.split('&chart=');
      let chartsNotUpdated = oldData[action.payload.assetNumber].charts
        .filter(chart => !chartNames.includes(chart.name))
        .map(chart => { return { ...chart, notUpdated: true } });

      newDataCharts.push(...chartsNotUpdated);

      oldData[action.payload.assetNumber].charts = newDataCharts;

      return { ...state, chartData: { ...oldData } };

    case STATISTICS_CHARTS_UPDATE_TOOLTIP_ENABLED:
      return { ...state, tooltipEnabled: action.payload };

    case STATISTICS_CHARTS_UPDATE_SELECTED_TIME_CONTROL_OPTION:
      return { ...state, selectedTimeControlOption: action.payload }

    case STATISTICS_CHARTS_UPDATE_TIME_CONTROL_AREA_OPENED:
      return { ...state, timeControlAreaOpened: action.payload };

    case STATISTICS_CHARTS_UPDATE_SELECTED_TIME_OPTION_TAB:
      return { ...state, selectedTimeOptionTab: action.payload }

    case STATISTICS_CHARTS_UPDATE_INITIAL_FILTER_DATE:
      return { ...state, initialFilterDate: action.payload }

    case STATISTICS_CHARTS_UPDATE_INITIAL_FILTER_TIME:
      return { ...state, initialFilterTime: action.payload }

    case STATISTICS_CHARTS_UPDATE_FINAL_FILTER_DATE:
      return { ...state, finalFilterDate: action.payload }

    case STATISTICS_CHARTS_UPDATE_FINAL_FILTER_TIME:
      return { ...state, finalFilterTime: action.payload }

    case STATISTICS_CHARTS_UPDATE_IS_CHART_UPDATE_CONFIRMATION_MODAL_OPEN:
      return { ...state, isChartUpdateConfirmationModalOpen: action.payload }

    case STATISTICS_CHARTS_UPDATE_FILTER_DATA:
      return { ...state, ...action.payload };

    case STATISTICS_CHARTS_UPDATE_TRIGGER_UPDATE_CHARTS_FLAG:
      return { ...state, triggerToUpdateCharts: action.payload }

    case STATISTICS_CHARTS_UPDATE_RELOAD_CHARTS_FLAG:
      return { ...state, reloadCharts: action.payload }

    case STATISTICS_CHARTS_UPDATE_CHARTS_TO_UPDATE_PARAMETER:
      return { ...state, chartsToUpdateParameter: action.payload }

    case STATISTICS_CHARTS_UPDATE_IS_DOWNLOAD_CHART_MODAL_OPEN:
      return { ...state, isDownloadChartModalOpen: action.payload }

    case STATISTICS_CHARTS_UPDATE_SHOULD_DOWNLOAD_ALL_CHARTS:
      return { ...state, shouldDownloadAllCharts: action.payload }

    case STATISTICS_CHARTS_UPDATE_SELECTED_CHART_INFO_DATA:
      return { ...state, selectedChartInfoData: action.payload }

    case STATISTICS_CHARTS_UPDATE_LOAD_CHART_DATA_STATUS:
      return { ...state, loadChartDataStatus: action.payload }

    case STATISTICS_CHARTS_UPDATE_IS_CHART_INFO_MODAL_OPEN:
      return { ...state, isChartInfoModalOpen: action.payload }

    default:
      return state;
  }
}