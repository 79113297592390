import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody
} from 'carbon-components-react';

import { presentValue } from './OptionHelper';
import { generateIcon, generateUpArrow, generateDownArrow } from '../Helper/graphIconGenerator'

const UNITS = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const StatisticsChartDataVisualization = (props) => {
  const [minOrder, setMinOrder] = useState(null);
  const [maxOrder, setMaxOrder] = useState(null);
  const [averageOrder, setAverageOrder] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(true);

  const listMinHeader = (datasetData) => {
    if (datasetData && datasetData.displayMinColumn) {
      return props.type === 'LINE' && <StructuredListCell className="chartDataHeaderCell" head onClick={() => onClickMin()}>
        {createTitle('Min', minOrder)}
      </StructuredListCell>
    }
  }

  const listMinValue = (datasetData, isDatasetNull) => {
    if (datasetData.displayMinColumn) {
      return props.type === 'LINE' && <StructuredListCell className="chartDataCell">
        {displayMinValue(datasetData, isDatasetNull)}
      </StructuredListCell>
    }
  }

  const createTitle = (text, property) => {
    return (
      <div className="chartDataHeaderTitleText">
        {text}
        {createLegendIcon(property)}
      </div>
    );
  }

  const createLegendIcon = (property) => {
    if (property !== null) {
      if (property) {
        return (generateUpArrow());
      } else {
        return (generateDownArrow());
      }
    }
  }

  const formatData = (dataValue, formatType, isNull) => {
    if (formatType === "TIME" && dataValue) {
      return isNull ? null : presentValue(dataValue) + "ms";
    }
    if (formatType === "PERCENTAGE" && dataValue) {
      return isNull ? null : presentValue((100 * dataValue) / 100) + "%";
    }
    if (formatType === "BYTES" && dataValue) {
      let l = 0, number = parseInt(dataValue, 10) || 0;
      while (number >= 1024 && ++l) {
        number = number / 1024;
      }
      return isNull ? null : (number.toFixed(number < 10 && l > 0 ? 1 : 0)) + UNITS[l];
    }
    else return isNull ? null : presentValue(dataValue);
  }

  const determineClassName = () => {
    if (props.type === 'TABLE') {
      return props.isModal ? "tableChartDataAreaModal" : "tableChartDataArea"
    } else {
      return props.isModal ? "chartDataAreaModal" : "chartDataArea"
    }
  }

  const displayMinValue = (dataSet, isNull) => {
    return formatData(Math.min(...dataSet.data), dataSet.formatType, isNull, false);
  }

  const displayMaxValue = (dataSet, isNull) => {
    return formatData(Math.max(...dataSet.data), dataSet.formatType, isNull, false);
  }

  const displayAverageValue = (dataSet, isNull) => {
    let data = dataSet.data;

    return formatData((data.reduce((a, b) => a + b, null) / data.length), dataSet.formatType, isNull, false);
  }

  const displayCurrentValue = (dataSet, isNull) => {
    let data = dataSet.data;

    if (!isNull && data[data.length - 1] == null) {
      isNull = true;
    }

    return formatData(data[data.length - 1], dataSet.formatType, isNull, true)
  }

  const onClickMin = () => {
    setMinOrder(prevMinOrder => !prevMinOrder);
    setMaxOrder(null);
    setAverageOrder(null);
    setCurrentOrder(null);

    props.reorderDataToMin(minOrder, props.chartIndex);
  }

  const onClickMax = () => {
    setMinOrder(null);
    setMaxOrder(prevMaxOrder => !prevMaxOrder);
    setAverageOrder(null);
    setCurrentOrder(null);

    props.reorderDataToMax(maxOrder, props.chartIndex);
  }

  const onClickAverage = () => {
    setMinOrder(null);
    setMaxOrder(null);
    setAverageOrder(prevAverageOrder => !prevAverageOrder);
    setCurrentOrder(null);

    props.reorderDataToAverage(averageOrder, props.chartIndex);
  }

  const onClickCurrent = () => {
    setMinOrder(null);
    setMaxOrder(null);
    setAverageOrder(null);
    setCurrentOrder(currentOrder => !currentOrder);

    props.reorderDataToCurrent(currentOrder, props.chartIndex);
  }

  const isDatasetNull = (data) => {
    return data.every(element => element === null);
  }

  return (
    <div className={determineClassName()}>
      <StructuredListWrapper className="chartDataList" selection>
        <StructuredListHead>
          <StructuredListRow head>
            <StructuredListCell className="chartDataHeaderTitle" head></StructuredListCell>
            {listMinHeader(props.datasets[0])}
            {props.type === 'LINE' && <StructuredListCell className="chartDataHeaderCell" head onClick={() => onClickMax()}>
              {createTitle('Max', maxOrder)}
            </StructuredListCell>}
            {props.type === 'LINE' && <StructuredListCell className="chartDataHeaderCell" head onClick={() => onClickAverage()}>
              {createTitle('Average', averageOrder)}
            </StructuredListCell>}
            <StructuredListCell className="chartDataHeaderCell" head onClick={() => onClickCurrent()}>
              {createTitle('Current', currentOrder)}
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListHead>
        <StructuredListBody>
          {props.datasets.map((datasetData, index) => {
            let isDatasetNullFlag = isDatasetNull(datasetData.data);
            let opacity = datasetData.hidden ? '0.5' : '1';

            return <StructuredListRow key={index} onClick={() => props.onClickInfo(props.chartIndex, index)} style={{ opacity: opacity }}>
              <StructuredListCell>
                <div className="chartDataTitleCell">
                  {generateIcon(datasetData.borderColor)}
                  &nbsp;
                  {datasetData.label}
                </div>
              </StructuredListCell>
              {listMinValue(datasetData, isDatasetNullFlag)}
              {props.type === 'LINE' && <StructuredListCell className="chartDataCell">
                {displayMaxValue(datasetData, isDatasetNullFlag)}
              </StructuredListCell>}
              {props.type === 'LINE' && <StructuredListCell className="chartDataCell">
                {displayAverageValue(datasetData, isDatasetNullFlag)}
              </StructuredListCell>}
              <StructuredListCell className="chartDataCell">
                {displayCurrentValue(datasetData, isDatasetNullFlag)}
              </StructuredListCell>
            </StructuredListRow>
          })}
        </StructuredListBody>
      </StructuredListWrapper>
    </div>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
  })
)(StatisticsChartDataVisualization);