import React from 'react';
import { connect } from 'react-redux';

import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'carbon-components-react';

import { ALERT_HEADERS } from '../HeaderHelper';
import { clearAlertFilters, toggleAlertFilterAreaOpened, updateAlertInitialDateFilter, updateAlertInitialTimeFilter, updateAlertFinalDateFilter, updateAlertFinalTimeFilter, updateAlertSearchValue } from '../../../reducers/CommunicationsReducer';
import CommunicationsDataTable from '../CommunicationsDataTable';
import Banner from '../../Banner/Banner';

const AlertsDataTableModal = (props) => {
  const createModalBody = () => {
    return (
      <React.Fragment>
        <Banner />
        <CommunicationsDataTable
          isNotificationData={false}
          displayCommunications={props.displayAlerts}
          filteredCommunications={props.filteredAlerts}
          headers={ALERT_HEADERS}
          initialDateFilter={props.alertInitialDateFilter}
          initialTimeFilter={props.alertInitialTimeFilter}
          finalDateFilter={props.alertFinalDateFilter}
          finalTimeFilter={props.alertFinalTimeFilter}
          updateInitialDateFilter={props.updateAlertInitialDateFilter}
          updateInitialTimeFilter={props.updateAlertInitialTimeFilter}
          updateFinalDateFilter={props.updateAlertFinalDateFilter}
          updateFinalTimeFilter={props.updateAlertFinalTimeFilter}
          statusFilters={props.alertStatusFilters}
          updateStatusFiltersControl={props.updateAlertStatusFiltersControl}
          categoryFilters={props.categoryFilters}
          subCategoryFilters={props.subCategoryFilters}
          searchValue={props.alertSearchValue}
          updateCommunicationSearchValue={props.updateAlertSearchValue}
          loadCommunicationStatus={props.loadNotificationStatus} />
      </React.Fragment>
    );
  }

  const closeModal = () => {
    clearFilters();

    props.closeModal(false);
  }

  const clearFilters = () => {
    if (props.alertFilterAreaOpened) {
      props.toggleAlertFilterAreaOpened();
    }

    props.updateAlertSearchValue('');
    props.clearAlertFilters();
  }

  return (
    <div>
      <ComposedModal
        open={props.isModalOpen}
        onClose={() => closeModal()}>
        <ModalHeader
          title='Notifications'
          iconDescription='Close Modal'
          buttonOnClick={() => closeModal()} />
        <ModalBody className="modalBody scrollY">
          {createModalBody()}
        </ModalBody>
      </ComposedModal>
    </div>
  );
}

export default connect(
  (state) => ({
    displayAlerts: state.communications.displayAlerts,
    filteredAlerts: state.communications.filteredAlerts,
    alertFilterAreaOpened: state.communications.alertFilterAreaOpened,
    alertInitialDateFilter: state.communications.alertInitialDateFilter,
    alertInitialTimeFilter: state.communications.alertInitialTimeFilter,
    alertFinalDateFilter: state.communications.alertFinalDateFilter,
    alertFinalTimeFilter: state.communications.alertFinalTimeFilter,
    alertSearchValue: state.communications.alertSearchValue,
    alertStatusFilters: state.communications.alertStatusFiltersControl,
    loadNotificationStatus: state.communications.loadNotificationStatus
  }), {
  clearAlertFilters,
  toggleAlertFilterAreaOpened,
  updateAlertInitialDateFilter,
  updateAlertInitialTimeFilter,
  updateAlertFinalDateFilter,
  updateAlertFinalTimeFilter,
  updateAlertSearchValue
}
)(AlertsDataTableModal);