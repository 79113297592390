import React from "react";

const rotate = (isOpen) => {
  if (isOpen) {
    return { transform: 'rotate(270deg)' }
  } else {
    return { transform: 'rotate(90deg)'}
  }
}

const generateIcon = (fillColor) => {
  return (
    <svg className="graph-ball-icon" width="20" height="20" viewBox="0 0 16 16" transform="translate(0, 4)">
      <circle cx="8" cy="8" r="8" fill={fillColor} />
    </svg>
  );
}

const generateCurrentStatusIcon = (fillColor) => {
  return (
    <svg className="graph-ball-icon" width="25" height="25" viewBox="0 0 16 16" transform="translate(0, 4)">
      <circle cx="8" cy="8" r="8" fill={fillColor} />
    </svg>
  );
}

const generateArrow = (isOpen, onClick) => {
  return (
    <svg className="componentArrow" fillRule="evenodd" height="12" viewBox="0 0 7 12" width="7" style={rotate(isOpen)}
      onClick={() => { onClick() }}>
      <path d="M5.569 5.994L0 .726.687 0l6.336 5.994-6.335 6.002L0 11.27z" />
    </svg>
  );
}

const generateDownArrow = () => {
  return (
    <svg className="bx--accordion__arrow" fillRule="evenodd" height="12" viewBox="0 0 7 14" style={{ transform: 'rotate(90deg)', marginBottom: '-10px' }}>
      <path d="M5.569 5.994L0 .726.687 0l6.336 5.994-6.335 6.002L0 11.27z" />
    </svg>
  );
}

const generateCurrentStatusDownArrow = () => {
  return (
    <svg className="bx--accordion__arrow performanceTableArrow" fillRule="evenodd" height="32" viewBox="0 0 32 32">
      <path d="M24.59 16.59L17 24.17 17 4 15 4 15 24.17 7.41 16.59 6 18 16 28 26 18 24.59 16.59z" />
    </svg>
  )
}

const generateUpArrow = () => {
  return (
    <svg className="bx--accordion__arrow" fillRule="evenodd" height="12" viewBox="0 0 7 14" style={{ transform: 'rotate(-90deg)', marginBottom: '-10px' }}>
      <path d="M5.569 5.994L0 .726.687 0l6.336 5.994-6.335 6.002L0 11.27z" />
    </svg>
  );
}

const generateCurrentStatusUpArrow = () => {
  return (
    <svg className="bx--accordion__arrow performanceTableArrow" fillRule="evenodd" height="32" viewBox="0 0 32 32">
      <path d="M16 4L6 14 7.41 15.41 15 7.83 15 28 17 28 17 7.83 24.59 15.41 26 14 16 4z" />
    </svg>
  )
}

export { generateIcon, generateCurrentStatusIcon, generateArrow, generateDownArrow, generateCurrentStatusDownArrow, generateUpArrow, generateCurrentStatusUpArrow };