import React from 'react';
import { connect } from 'react-redux';

import { ContentSwitcher, Switch } from 'carbon-components-react';
import { updateSelectedSubscriptionOption } from '../../../reducers/SubscriptionReducer';
import { SUBSCRIPTION_OPTIONS } from './SubscriptionHelper';

const EmailSubscriptionContentSwitcher = (props) => {
  const createBannerContentSwitchers = () => {
    return SUBSCRIPTION_OPTIONS.map(item =>
      <Switch
        key={item}
        name={item}
        text={item} />
    );
  }

  return (
    <div className="containerContentSwitcher">
      <ContentSwitcher
        selectedIndex={SUBSCRIPTION_OPTIONS.indexOf(props.selectedSubscriptionOption)}
        onChange={(event) => props.updateSelectedSubscriptionOption(SUBSCRIPTION_OPTIONS[event.index])}>
        {createBannerContentSwitchers()}
      </ContentSwitcher>
    </div>
  );
}

export default connect(
  (state) => ({
    selectedSubscriptionOption: state.subscription.selectedSubscriptionOption
  }), {
  updateSelectedSubscriptionOption
}
)(EmailSubscriptionContentSwitcher);