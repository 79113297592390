import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Accordion, AccordionItem, ComposedModal, ModalBody, ModalHeader } from 'carbon-components-react';
import { updateIsCommunicationsCalendarModalOpen } from '../../reducers/CommunicationsReducer';
import { BANNER_CONTENT_SWITCHER_VALUES } from './CommunicationsHelper';
import CommunicationsToolbarCategoryButton from './Toolbar/CommunicationsToolbarCategoryButton';

import "react-big-calendar/lib/css/react-big-calendar.css";
import CommunicationsCalendar from './CalendarComponents/CommunicationsCalendar';

const CommunicationsCalendarModal = (props) => {
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    prepareCalendarEvents(props.displayCommunications);
  }, [props.displayCommunications]);

  const prepareCalendarEvents = (communications) => {
    let result = communications.map(comm => {
      let eventDates = determineDate(comm);

      return {
        ...comm,
        title: comm.title,
        start: eventDates.startDate,
        end: eventDates.endDate
      }
    });

    setCalendarEvents(result);
  }

  const determineDate = (communication) => {
    if (communication.announcementEventStartTime && communication.announcementEventEndTime) {
      return {
        startDate: new Date(communication.announcementEventStartTime),
        endDate: new Date(communication.announcementEventEndTime)
      }
    } else {
      if (communication.status === 'published') {
        return {
          startDate: new Date(communication.date),
          endDate: new Date(communication.date)
        }
      } else {
        return {
          startDate: new Date(communication.updateDate),
          endDate: new Date(communication.updateDate)
        }
      }
    }
  }

  const closeModal = () => {
    setCalendarEvents(props.displayCommunications);

    props.updateIsCommunicationsCalendarModalOpen(false);
  }

  const createCategoryRadioButtons = () => {
    return BANNER_CONTENT_SWITCHER_VALUES.map((item, key) =>
      <CommunicationsToolbarCategoryButton item={item} key={key} />
    );
  }

  return (
    <ComposedModal
      open={props.isCommunicationsCalendarModalOpen}
      onClose={() => closeModal()}>
      <ModalHeader
        title="Calendar"
        label='All dates below are displayed in UTC format'
        iconDescription="Close Modal"
        buttonOnClick={() => closeModal()} />
      <ModalBody className='scrollY'>
        <React.Fragment>
          <Accordion className="accordionContainer padding0">
            <AccordionItem title='Filters' key={0}>
              <div className="radioButtonTitle">
                Categories:
              </div>
              <div className="radioButtonArea">
                {createCategoryRadioButtons()}
              </div>
            </AccordionItem>
          </Accordion>
          <div className="height50" />
          <div className='calendarContainer'>
            <CommunicationsCalendar
              calendarEvents={calendarEvents}
              filterCommunications={props.filterCommunications}
              closeModal={closeModal}
              setNotificationModalOpen={props.setNotificationModalOpen} />
          </div>
        </React.Fragment>
      </ModalBody>
    </ComposedModal>
  );
}

export default connect(
  (state) => ({
    isCommunicationsCalendarModalOpen: state.communications.isCommunicationsCalendarModalOpen
  }), {
  updateIsCommunicationsCalendarModalOpen
}
)(CommunicationsCalendarModal);