import {headers} from "./BaseServices" ;

export const fetchMonitorNames = (envSchemaId) => {
  return fetch(`/spid/iotm/api/v1/config/${envSchemaId}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}

export const fetchChartData = (assetNumber, isoTime, isoTimeEnd, chartsToUpdateParameter) => {
  let GET_SINGLE_CHART_DATA_URL = '';

  if (isoTimeEnd) {
    GET_SINGLE_CHART_DATA_URL = '/spid/iotm/api/v1/metrics/' + assetNumber + '?start=' + isoTime + '&end=' + isoTimeEnd + chartsToUpdateParameter;
  } else {
    GET_SINGLE_CHART_DATA_URL = '/spid/iotm/api/v1/metrics/' + assetNumber + '?start=' + isoTime + chartsToUpdateParameter;
  }
  return fetch(GET_SINGLE_CHART_DATA_URL, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}