import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import { Button } from 'carbon-components-react';
import html2canvas from "html2canvas";
import { generateChartId } from './OptionHelper';

import { jsPDF } from "jspdf";

const RATIO = 0.75;

const StatisticsChartsDownloader = (props) => {
  const [isCreatingPDF, setIsCreatingPDF] = useState(false);

  const treatData = () => {
    var processedChart = [];

    if (props.chartData
      && props.chartData.hasOwnProperty(props.selectedChartInfoData.assetNumber)
      && props.chartData[props.selectedChartInfoData.assetNumber].hasOwnProperty('charts')) {
      var rawData = JSON.parse(JSON.stringify(props.chartData[props.selectedChartInfoData.assetNumber].charts));

      rawData.forEach((chart) => {
        if (chart.name === props.selectedChartInfoData.chartName) {
          let name = chart.name;

          if (chart.datasets !== undefined && chart.datasets[0] && chart.datasets[0].hasOwnProperty("data")
            && chart.datasets[0].data && chart.datasets[0].data.hasOwnProperty("length")) {
            let datasetsSize = chart.datasets.length;
            let dataSize = chart.datasets[0].data.length;
            let i, j;

            let interval = (props.selectedChartInfoData.initialTimeInMilliseconds - props.selectedChartInfoData.finalTimeInMilliseconds + 1) / dataSize;
            let startTime = props.selectedChartInfoData.finalTimeInMilliseconds;

            var timezoneOffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

            for (i = 0; i < dataSize; i++) {
              let json = {};

              if (i < dataSize - 1) {
                let startTimeFormated = new Date(startTime - timezoneOffset);

                json["timestamp"] = startTimeFormated.toISOString().split(':')[0] + ":" + startTimeFormated.toISOString().split(':')[1];
                startTime += interval;
              } else {
                let endTimeFormated = new Date(props.selectedChartInfoData.initialTimeInMilliseconds - timezoneOffset);

                json["timestamp"] = endTimeFormated.toISOString().split(':')[0] + ":" + endTimeFormated.toISOString().split(':')[1];
              }

              for (j = 0; j < datasetsSize; j++) {
                json[name.replace(/ /g, "") + chart.datasets[j].label.replace(/ /g, "")] = chart.datasets[j].data[i];
              }

              processedChart.push(json);
            }
          }
        }
      });
    }

    return processedChart;
  }

  const treatDataAll = () => {
    var processedChart = [];

    if (props.chartData
      && props.chartData.hasOwnProperty(props.selectedChartInfoData.assetNumber)
      && props.chartData[props.selectedChartInfoData.assetNumber].hasOwnProperty('charts')) {
      var rawData = JSON.parse(JSON.stringify(props.chartData[props.selectedChartInfoData.assetNumber].charts));

      rawData.forEach((chart) => {
        let name = chart.name;

        if (chart.datasets !== undefined && chart.datasets[0] && chart.datasets[0].hasOwnProperty("data")
          && chart.datasets[0].data && chart.datasets[0].data.hasOwnProperty("length")) {
          let datasetsSize = chart.datasets.length;
          let dataSize = chart.datasets[0].data.length;
          let i, j;

          let interval = (props.selectedChartInfoData.initialTimeInMilliseconds - props.selectedChartInfoData.finalTimeInMilliseconds + 1) / dataSize;
          let startTime = props.selectedChartInfoData.finalTimeInMilliseconds;

          var timezoneOffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

          for (i = 0; i < dataSize; i++) {
            let json = {};

            if (i < dataSize - 1) {
              let startTimeFormated = new Date(startTime - timezoneOffset);

              json["timestamp"] = startTimeFormated.toISOString().split(':')[0] + ":" + startTimeFormated.toISOString().split(':')[1];
              startTime += interval;
            } else {
              let endTimeFormated = new Date(props.selectedChartInfoData.initialTimeInMilliseconds - timezoneOffset);

              json["timestamp"] = endTimeFormated.toISOString().split(':')[0] + ":" + endTimeFormated.toISOString().split(':')[1];
            }

            for (j = 0; j < datasetsSize; j++) {
              json[name.replace(/ /g, "") + chart.datasets[j].label.replace(/ /g, "")] = chart.datasets[j].data[i];
            }

            processedChart.push(json);
          }
        }
      });
    }

    return processedChart;
  }

  const selectHeaders = () => {
    var statisticsChartHeader = [];

    if (props.chartData && props.chartData.hasOwnProperty(props.selectedChartInfoData.assetNumber)
      && props.chartData[props.selectedChartInfoData.assetNumber].hasOwnProperty('charts')) {
      var rawData = JSON.parse(JSON.stringify(props.chartData[props.selectedChartInfoData.assetNumber].charts));

      var timestampColumnAdded = false;

      rawData.forEach((chart) => {
        if (chart.name === props.selectedChartInfoData.chartName) {
          let name = chart.name;

          chart.datasets.forEach((dataset) => {
            if (!timestampColumnAdded) {
              let jsonTimestampColumn = {};

              jsonTimestampColumn["key"] = "timestamp";
              jsonTimestampColumn["label"] = "Timestamp";
              statisticsChartHeader.push(jsonTimestampColumn);
              timestampColumnAdded = true;
            }

            let label = dataset.label;

            let json = {};

            json["key"] = name.replace(/ /g, "") + label.replace(/ /g, "");
            json["label"] = label;
            statisticsChartHeader.push(json);
          });
        }
      });
    }

    return statisticsChartHeader.length ? statisticsChartHeader : [{ "label": "No data found" }];
  }

  const selectHeadersAll = () => {
    var statisticsChartHeader = [];

    if (props.chartData && props.chartData.hasOwnProperty(props.selectedChartInfoData.assetNumber)
      && props.chartData[props.selectedChartInfoData.assetNumber].hasOwnProperty('charts')) {
      var rawData = JSON.parse(JSON.stringify(props.chartData[props.selectedChartInfoData.assetNumber].charts));

      var timestampColumnAdded = false;

      rawData.forEach((chart) => {
        let name = chart.name;

        chart.datasets.forEach((dataset) => {
          if (!timestampColumnAdded) {
            let jsonTimestampColumn = {};

            jsonTimestampColumn["key"] = "timestamp";
            jsonTimestampColumn["label"] = "Timestamp";
            statisticsChartHeader.push(jsonTimestampColumn);
            timestampColumnAdded = true;
          }

          let label = dataset.label;

          let json = {};

          json["key"] = name.replace(/ /g, "") + label.replace(/ /g, "");
          json["label"] = label + " (" + name + "-" + props.selectedChartInfoData.assetNumber + ")";
          statisticsChartHeader.push(json);
        });
      });
    }

    return statisticsChartHeader.length ? statisticsChartHeader : [{ "label": "No data found" }];
  }

  const downloadSinglePDFChart = () => {
    let input = window.document.getElementById(generateChartId(props.selectedChartInfoData.assetNumber, props.selectedChartInfoData.chartName));

    setIsCreatingPDF(true);

    html2canvas(input)
      .then(canvas => {
        var imgData = canvas.toDataURL("image/png");
        var doc = new jsPDF({orientation: 'landscape', unit: 'pt', format: [canvas.width * RATIO, canvas.height * RATIO]});
        var pdfWidth = doc.internal.pageSize.getWidth() * RATIO;
        var pdfHeight = doc.internal.pageSize.getHeight() * RATIO;

        doc.internal.pageSize.setWidth(pdfWidth);
        doc.internal.pageSize.setHeight(pdfHeight);

        doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        doc.save('StatisticsCharts-information-' + props.selectedChartInfoData.assetNumber + '-' + props.selectedChartInfoData.chartName + '.pdf');

        setIsCreatingPDF(false);
      })
      .catch(err => console.log(err.message));
  }

  const downloadAllPdfChart = () => {
    let input;
    let index = 0;

    var doc;

    setIsCreatingPDF(true);

    let loopForPDFData = () => {
      input = window.document.getElementById(props.selectedChartInfoData.chartIds[index]);

      html2canvas(input)
        .then(canvas => {
          var imgData = canvas.toDataURL("image/png");
          var pdfWidth, pdfHeight;

          if (index === 0) {
            //First page
            doc = new jsPDF({orientation: 'landscape', unit: 'pt', format: [canvas.width, canvas.height]});

            pdfWidth = doc.internal.pageSize.getWidth();
            pdfHeight = doc.internal.pageSize.getHeight();

            doc.internal.pageSize.setWidth(doc.internal.pageSize.getWidth() * RATIO);
            doc.internal.pageSize.setHeight(doc.internal.pageSize.getHeight() * RATIO);

            doc.addImage(imgData, 'PNG', 0, 0, pdfWidth * RATIO, pdfHeight * RATIO);
          } else {
            doc.addPage([canvas.width, canvas.height], 'landscape');
            doc.setPage(doc.internal.getCurrentPageInfo().pageNumber + 1);

            pdfWidth = doc.internal.pageSize.getWidth() * RATIO;
            pdfHeight = doc.internal.pageSize.getHeight() * RATIO;
            
            doc.internal.pageSize.setWidth(doc.internal.pageSize.getWidth() * RATIO);
            doc.internal.pageSize.setHeight(doc.internal.pageSize.getHeight() * RATIO);

            if (pdfWidth > pdfHeight) {
              doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            } else {
              doc.internal.pageSize.setWidth(pdfHeight);
              doc.internal.pageSize.setHeight(pdfWidth);

              doc.addImage(imgData, 'PNG', 0, 0, pdfHeight, pdfWidth);
            }
          }

          if (index !== props.selectedChartInfoData.chartIds.length - 1) {
            //Continue the loop
            index++;
            loopForPDFData();
          } else {
            doc.save('StatisticsCharts-information-' + props.selectedChartInfoData.assetNumber + '.pdf');
            setIsCreatingPDF(false);
          }
        })
        .catch(err => console.log(err.message));
    }

    loopForPDFData();
  }

  if (props.shouldDownloadAllCharts) {
    return (
      <React.Fragment>
        <CSVLink
          data={treatDataAll()}
          headers={selectHeadersAll()}
          filename={`StatisticsCharts-information-${props.selectedChartInfoData.assetNumber}.csv`}
          className="downloadCSV"
          target="_blank">
          <Button
            disabled={isCreatingPDF}
            kind='tertiary'>
            Yes, Download all of the charts' data in .CSV
          </Button>
        </CSVLink>
        <Button
          className="downloadButton"
          disabled={isCreatingPDF}
          onClick={() => downloadAllPdfChart()}>
          {isCreatingPDF ? 'Creating...' : 'Yes, Download all of the charts in .PDF'}
        </Button>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <CSVLink
          data={treatData()}
          headers={selectHeaders()}
          filename={`StatisticsCharts-information-${props.selectedChartInfoData.assetNumber}-${props.selectedChartInfoData.chartName}.csv`}
          className="downloadCSV"
          target="_blank">
          <Button
            className='downloadButton'
            disabled={isCreatingPDF}
            kind='tertiary'>
            Yes, Download the chart data in .CSV
          </Button>
        </CSVLink>
        <Button
          className="downloadButton"
          disabled={isCreatingPDF}
          onClick={() => downloadSinglePDFChart()}>
          {isCreatingPDF ? 'Creating...' : 'Yes, Download the chart in .PDF'}
        </Button>
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    chartData: state.statisticCharts.chartData,
    selectedChartInfoData: state.statisticCharts.selectedChartInfoData,
    shouldDownloadAllCharts: state.statisticCharts.shouldDownloadAllCharts
  }),
  {}
)(StatisticsChartsDownloader);