import React from 'react';
import { connect } from 'react-redux';

import { Button } from 'carbon-components-react';

import { updateSelectedServiceType, requestToChangeOrg } from '../../reducers/UserReducer';
import { requestRssEncryptedId } from '../../reducers/RssFeedReducer';
import { requestEnvIdForNotice, requestEnvIdForMonitor } from '../../reducers/NoticeAPIReducer'
import NoticeAPIModal from './NoticeAPIModal';
import RssFeedModal from './RSSFeedModal';
import EmailSubscriptionModal from './EmailSubscription/EmailSubscriptionModal';
import { SUBMISSION_STATUS_SUBMITTED } from '../../reducers/BaseReducer';
import { updateIsSubscriptionModalOpenFlag } from '../../reducers/SubscriptionReducer';
import MonitorAPIModal from './MonitorAPIModal';
import TabsArea from './TabsArea';
import SolutionCombobox from './SolutionCombobox';
import { isManualMonitoring } from '../Helper/ComponentHelper';

const ComponentBar = (props) => {
  const checkForTabs = () => {
    return props.envSchema.tabs && props.envSchema.serviceTypes && props.envSchema.serviceTypes.length >= 2;
  }

  const checkForSolutionCombobox = () => {
    return props.userGroupSubOrgs.length > 0;
  }

  const checkForMonitorsButton = () => {
    return (isManualMonitoring(props.envSchema.solution)) && (props.envSchema.visibility && !props.envSchema.visibility.shouldHideCommunicationsSectionButtons)
  }

  const checkForGeneralSubscriptionsButton = () => {
    return !(props.envSchema.visibility && props.envSchema.visibility.shouldHideSubscriptionButtons);
  }

  return (
    <div className="dashboardComponentArea">
      <div className="serviceTypeTab">
        {checkForTabs() && <TabsArea />}

        {checkForSolutionCombobox() && <SolutionCombobox />}

        <div className="spaceBetweenItems" />

        <div className="comboAndButtonArea">
          {checkForMonitorsButton() && <Button
            kind="tertiary"
            className="dashboardButton"
            disabled={props.envIdStatus === SUBMISSION_STATUS_SUBMITTED}
            onClick={() => props.requestEnvIdForMonitor(props.envSchema.id)}>
            {props.envIdStatus === SUBMISSION_STATUS_SUBMITTED ? 'Loading' : 'Get Monitors'}
          </Button>}

          {checkForGeneralSubscriptionsButton() && <Button
            kind="tertiary"
            className="dashboardButton"
            disabled={props.envIdStatus === SUBMISSION_STATUS_SUBMITTED}
            onClick={() => props.requestEnvIdForNotice(props.envSchema.id)}>
            {props.envIdStatus === SUBMISSION_STATUS_SUBMITTED ? 'Loading' : 'Get Notices'}
          </Button>}

          {checkForGeneralSubscriptionsButton() && <Button
            kind="tertiary"
            className="dashboardButton"
            disabled={props.encryptingStatus === SUBMISSION_STATUS_SUBMITTED}
            onClick={() => props.requestRssEncryptedId(props.envSchema.id, props.selectedServiceType.name)}>
            {props.encryptingStatus === SUBMISSION_STATUS_SUBMITTED ? 'Loading' : 'RSS Feed'}
          </Button>}

          {checkForGeneralSubscriptionsButton() && <Button
            kind='tertiary'
            className="dashboardButton"
            onClick={() => props.updateIsSubscriptionModalOpenFlag(true)}>
            E-Mail Subscription
          </Button>}

          <EmailSubscriptionModal />
          <RssFeedModal />
          <NoticeAPIModal />
          <MonitorAPIModal />
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    selectedServiceType: state.user.selectedServiceType,
    userGroupSubOrgs: state.user.userGroupSubOrgs,
    encryptingStatus: state.rssFeed.encryptingStatus,
    envIdStatus: state.noticeAPI.envIdStatus
  }),
  {
    updateSelectedServiceType,
    requestToChangeOrg,
    updateIsSubscriptionModalOpenFlag,
    requestRssEncryptedId,
    requestEnvIdForNotice,
    requestEnvIdForMonitor
  }
)(ComponentBar);
