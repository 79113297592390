import React, { useState } from 'react';
import { connect } from 'react-redux';

import { DataTable, PaginationV2, Button, Accordion, AccordionItem, SkeletonPlaceholder } from 'carbon-components-react';
import { SUBMISSION_STATUS_SUBMITTED } from '../../reducers/BaseReducer';
import { DownloadIcon } from '../Communications/Toolbar/ButtonIcons';
import PerformanceTablesDownloadModal from './PerformanceTablesDownloadModal';
import { updateIsDownloadModalOpen } from '../../reducers/PerformanceTablesReducer';
import BigTableDatePickerArea from './BigTableDatePickerArea';
import { convertBytes, displayDate, displayNumber } from './PerformanceTablesHelper';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableExpandRow,
  TableExpandedRow,
  TableBody,
  TableCell,
  TableHeader,
  TableExpandHeader,
  TableToolbar
} = DataTable;

const BIG_TABLE_HEADERS = [{
  key: 'id',
  header: 'Table Id',
}, {
  key: 'totalRecord',
  header: 'Total Record'
}, {
  key: 'tableSize',
  header: 'Table Size',
}, {
  key: 'lastRunStatsDate',
  header: 'Last Run Stats Date',
}, {
  key: 'avgRowSize',
  header: 'Avg Row Size',
}, {
  key: 'tableStorageSize',
  header: 'Table Storage Size',
}];

const BigTableDataTable = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const getRows = () => {
    let result = [];

    if (props.bigTableValues) {
      result = props.bigTableValues.sort(compareBigTableValues);
    }

    return result;
  }

  const compareBigTableValues = (a, b) => {
    if (a.tableSize < b.tableSize) {
      return 1;
    } else if (a.tableSize > b.tableSize) {
      return -1;
    } else {
      return 0;
    }
  }

  const renderHeader = (getHeaderProps, header) => {
    let c;

    let className = header.key.includes('id') ? 'headerCellTitle' : 'headerCell';

    if (header.key.includes('id') || header.key.includes('tableSize') || header.key.includes('totalRecord')) {
      c = <TableHeader {...getHeaderProps({ header })} className={className}>{header.header}</TableHeader>;
    }

    return c;
  }

  const renderCell = (cell) => {
    let c;

    if (cell.id.includes('id')) {
      c = <TableCell key={cell.id}>{cell.value}</TableCell>;
    } else if (cell.id.includes('tableSize')) {
      c = <TableCell key={cell.id}>{displayNumber(convertBytes(cell.value))}</TableCell>;
    } else if (cell.id.includes('totalRecord')) {
      c = <TableCell key={cell.id}>{displayNumber(cell.value)}</TableCell>;
    }

    return c;
  }

  const renderExpandedCell = (row, cell) => {
    let lastRunStatsDateCell = row.cells.find(c => c.id === row.id + ':lastRunStatsDate');
    let avgRowSizeCell = row.cells.find(c => c.id === row.id + ':avgRowSize');
    let tableStorageSize = row.cells.find(c => c.id === row.id + ':tableStorageSize');
    let c;

    if (cell.id.includes('id')) {
      c = <div className="expandedArea" key={cell.id}>
        <div className="bx--row">
          <div className="bx--col-xs-4 bx--col-md-4 bx--col-lg-4 expandedCategoryTitle">
            <div className="bold">Last Run Stats Date</div>
          </div>
          <div className="bx--col-xs-4 bx--col-md-4 bx--col-lg-4 expandedCategoryTitle">
            <div className="bold">Average Row Size</div>
          </div>
          <div className="bx--col-xs-4 bx--col-md-4 bx--col-lg-4 expandedCategoryTitle">
            <div className="bold">Table Storage Size</div>
          </div>
        </div>
        &nbsp;
        <div className="bx--row">
          <div className="bx--col-xs-4 bx--col-md-4 bx--col-lg-4 expandedCategoryText">
            <div className='expandedCellText'>{displayDate(lastRunStatsDateCell.value)}</div>
          </div>
          <div className="bx--col-xs-4 bx--col-md-4 bx--col-lg-4 expandedCategoryText">
            <div className='expandedCellText'>{displayNumber(convertBytes(avgRowSizeCell.value))}</div>
          </div>
          <div className="bx--col-xs-4 bx--col-md-4 bx--col-lg-4 expandedCategoryText">
            <div className='expandedCellText'>{displayNumber(convertBytes(tableStorageSize.value))}</div>
          </div>
        </div>
      </div>;
    }

    return c;
  }

  let rows = getRows();

  if (!props.selectedMonitorId) {
    return <div />;
  } else if (props.loadBigTableStatus === SUBMISSION_STATUS_SUBMITTED) {
    return <div className="dataTableSkeletonComponent">
      <SkeletonPlaceholder />
    </div>;
  } else {
    return (
      <React.Fragment>
        <DataTable
          headers={BIG_TABLE_HEADERS}
          rows={rows}
          useZebraStyles={false}
          render={({ rows, headers, getHeaderProps, getRowProps }) => {
            rows = rows.slice((pageNumber - 1) * pageSize, (pageNumber) * pageSize)
            return (
              <TableContainer>
                <TableToolbar className="bigTableDataTableToolbar">
                  <div className="reverseRow">
                    <Button
                      className="toolbarButton"
                      renderIcon={DownloadIcon}
                      iconDescription="Download BigTable Data Button"
                      onClick={() => props.updateIsDownloadModalOpen(true)}>
                      Download
                    </Button>
                  </div>
                </TableToolbar>

                <Accordion className="bigTableAccordionContainer padding0">
                  <AccordionItem className="bigTableFilterContainer" title={<strong>{props.bigTableCaption}</strong>} key={0}>
                    <div className="filterArea">
                      <BigTableDatePickerArea />
                    </div>
                  </AccordionItem>
                </Accordion>
                &nbsp;
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableExpandHeader />
                      {headers.map(header => (
                        renderHeader(getHeaderProps, header)
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <React.Fragment key={row.id}>
                        <TableExpandRow {...getRowProps({ row })} className="notificationsTableExpandRow">
                          {row.cells.map(cell => (
                            renderCell(cell)
                          ))}
                        </TableExpandRow>
                        {row.isExpanded && (
                          <TableExpandedRow>
                            <TableCell className="expandedRow" colSpan={headers.length + 1}>
                              <div>
                                {row.cells.map(cell => (
                                  renderExpandedCell(row, cell)
                                ))}
                              </div>
                            </TableCell>
                          </TableExpandedRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          }}
        />
        <PaginationV2
          id='pagination-0'
          className='pagination'
          onChange={({ page, pageSize }) => {
            setPageNumber(page);
            setPageSize(pageSize);
          }}
          pageSizes={[10, 20, 30, 40, 50]}
          page={pageNumber}
          pageInputDisabled={false}
          totalItems={rows.length}
        />
        <PerformanceTablesDownloadModal
          data={JSON.parse(JSON.stringify(rows))} />
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    filteredBigTable: state.performanceTables.filteredBigTable,
    bigTableValues: state.performanceTables.bigTableValues,
    selectedMonitorId: state.performanceTables.selectedMonitorId,
    loadBigTableStatus: state.performanceTables.loadBigTableStatus,
    bigTableCaption: state.performanceTables.bigTableCaption
  }), {
  updateIsDownloadModalOpen
}
)(BigTableDataTable);