import { requestEncryptedId } from '../lib/EncryptedEnvIdServices';
import  {
    SUBMISSION_STATUS_INITIAL_STATUS,
    SUBMISSION_STATUS_FAILED,
    SUBMISSION_STATUS_SUCCESS,
    SUBMISSION_STATUS_SUBMITTED  
  } from'./BaseReducer' ;

const initState = {
  openModal: false,
  url: '',
  encryptingStatus: SUBMISSION_STATUS_INITIAL_STATUS
}

const RSS_FEED_UPDATE_OPEN_MODAL_FLAG = 'RSS_FEED_UPDATE_OPEN_MODAL_FLAG';
export const updateOpenModalFlagAction = (newFlag) => ({type: RSS_FEED_UPDATE_OPEN_MODAL_FLAG, payload: newFlag});

const RSS_FEED_UPDATE_FEED_URL = 'RSS_FEED_UPDATE_FEED_URL';
export const updateFeedUrlAction = (newUrl) => ({type: RSS_FEED_UPDATE_FEED_URL, payload: newUrl});

const RSS_FEED_UPDATE_ENCRYPTING_STATUS = 'RSS_FEED_UPDATE_ENCRYPTING_STATUS';
export const updateEncryptingStatusAction = (newStatus) => ({type: RSS_FEED_UPDATE_ENCRYPTING_STATUS, payload: newStatus});

export const requestRssEncryptedId = (envSchemaId, serviceType) => {
  return (dispatch) => {
    dispatch(updateEncryptingStatusAction(SUBMISSION_STATUS_SUBMITTED));

    requestEncryptedId(envSchemaId)
    .then(data => {
      dispatch(updateEncryptingStatusAction(SUBMISSION_STATUS_SUCCESS));
      dispatch(updateOpenModalFlagAction(true));
      dispatch(updateFeedUrlAction("/feed/iotm/api/v1/feedtype/rss/environment/" + data.value + "/servicetype/" + serviceType + "/rss.xml"))
    })
    .catch(error => {
      dispatch(updateEncryptingStatusAction(SUBMISSION_STATUS_FAILED));
      dispatch(updateOpenModalFlagAction(false));
    });
  }
}

export const closeModal = () => {
  return (dispatch) => {
    dispatch(updateOpenModalFlagAction(false));
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case RSS_FEED_UPDATE_OPEN_MODAL_FLAG:
      return {...state, openModal: action.payload}

    case RSS_FEED_UPDATE_FEED_URL:
      return {...state, url: action.payload}

    case RSS_FEED_UPDATE_ENCRYPTING_STATUS:
      return {...state, encryptingStatus: action.payload}
  
    default:
      return state;
  }
}