import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Tooltip,
  StructuredListRow,
  StructuredListCell
} from 'carbon-components-react';
import { GoodIcon, CircledMaintenanceIcon, PartiallyAvailableIcon, UnknownIcon, BadIcon } from './listIcons';

import './HealthStatus.css';
import '../Dashboard/Dashboard.css';
import { isCDS, isManualMonitoring } from '../Helper/ComponentHelper';

const HealthStatusMonitor = (props) => {
  const [monitorStatusArray, setMonitorStatusArray] = useState([]);

  useEffect(() => {
    setMonitorStatusArray(determineStatusArray(props.data, props.monitorValues));
  }, [props.data])

  const determineStatusArray = (rawData, monitorValues) => {
    let monitorStatusArray = [];

    if (Array.isArray(monitorValues)) {
      monitorValues.forEach((monitor) => {
        let correctRawData = rawData.filter((data) => data.datacenter === monitor.key)[0];

        monitorStatusArray.push({
          key: monitor.key,
          status: determineStatus(correctRawData, monitor)
        });
      });
    }

    return monitorStatusArray;
  }

  const determineStatus = (rawData, monitor) => {
    if (monitor.enabled && rawData) {
      return rawData.datacenter === monitor.key ? rawData.status : monitor.status;
    } else {
      return 'dummy';
    }
  }

  const generateIconCells = () => {
    let iconCells = [];

    monitorStatusArray.forEach((monitor, index) => {
      iconCells.push(
        <StructuredListCell key={index} className="monitorIcon">
          {iconGenerator(monitor.status)}
        </StructuredListCell>
      );
    });

    return iconCells;
  }

  const createMonitorTooltip = (monitorName) => {
    if (props.appSpecText && props.appSpecText.length > 0) {
      return (
        <div className="monitorGrouping">
          <Tooltip
            direction="bottom"
            tabIndex={0}
            triggerText={<p className="monitorDescriptionText">
              {monitorName}
            </p>}>
            {props.appSpecText}
          </Tooltip>
          {props.description && <div className="monitorDescriptionWebsite">
            {props.description}
          </div>}
        </div>
      );
    } else if (isCDS(props.envSchema.solution)) {
      return (
        <div className="monitorGrouping">
          <Tooltip
            direction="bottom"
            tabIndex={0}
            triggerText={<p className="monitorDescriptionText">
              {monitorName}
            </p>}>
            No version info available
          </Tooltip>
          {props.description && <div className="monitorDescriptionWebsite">
            {props.description}
          </div>}
        </div>
      );
    } else if (isManualMonitoring(props.envSchema.solution)) {
      return (
        <div className="monitorGrouping">
          <Tooltip
            direction="bottom"
            tabIndex={0}
            triggerText={<p className="monitorDescriptionText">{monitorName}</p>}>
            {props.description}
          </Tooltip>
        </div>
      );
    } else {
      return <p className="monitorDescriptionText">
        {monitorName}
      </p>
    }
  }

  const iconGenerator = (status) => {
    switch (status) {
      case "good":
        return <GoodIcon />;
      case "bad":
        return <BadIcon />;
      case "available":
        return <GoodIcon />;
      case "not_available":
        return <BadIcon />;
      case "maintenance":
        return <CircledMaintenanceIcon />;
      case "partially_available":
        return <PartiallyAvailableIcon />
      case "unknown":
        return <UnknownIcon />;
      case "dummy":
        return '-';
      default:
        return '-';
    }
  }

  return (
    <StructuredListRow label key={props.index} htmlFor={props.index}>
      <StructuredListCell className="monitorDescriptionText">
        {createMonitorTooltip(props.name)}
      </StructuredListCell>
      {generateIconCells()}
    </StructuredListRow>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema
  })
)(HealthStatusMonitor);