import {headers} from "./BaseServices" ;

export const requestEncryptedId = (envSchemaId) => {
  return fetch(`/config/iotm/api/v1/encryption/encrypt?encoded=true`, {
    method: 'POST',
      headers: {
        ...headers,
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
      },
    body: JSON.stringify({value: envSchemaId})
  })
  .then(res => res.json());
}