import {headers} from "./BaseServices" ;

export const fetchNotifications = (environmentId, serviceType) => {
  return fetch(`/announcement/iotm/api/v1/notices/environment/${environmentId}/servicetype/${serviceType}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}

export const fetchNotificationSubCategories = (solutionId) => {
    return fetch(`/announcement/iotm/api/v1/noticesubcategory/solutionid/${solutionId}`, {
      method: 'GET',
      headers: {
        ...headers,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json());
  }