import {headers} from "./BaseServices" ;

export const getUserGroup = () => {
  return fetch(`/usergrouporgs`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}

export const getOrgFromServer = () => {
  return fetch(`/currentorg`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}

export const getLoginUserId = () => {
  return fetch(`/loginuser`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}

export const getUserEmail = () => {
  return fetch(`/useremail`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}

export const getLoginInfo = () => {
  return fetch(`/logininfo`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}

export const changeCurrentOrg = (id) => {
  return fetch(`/updatecurrentorg/${id}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}