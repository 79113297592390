import { Button } from 'carbon-components-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Page from './Page';
import PageHeader from './PageHeader';

class MaintenancePage extends Component {

  redirectToUserStatusDashboard = () => {
    let solution = window.location.pathname.split('/maintenance')[1];

    window.open('https://' + window.location.hostname + solution);
  }

  render() {
    return (
      <div className="app">
        <PageHeader isLoginFailurePage={true} />
        <main id="content" className="app-main-content">
          <Page>
            <div className='maintenancePageArea'>
              <div className='maintenancePageMainText'>
                We have a situation
              </div>
              <Button
                className='maintenancePageMainButton'
                onClick={() => this.redirectToUserStatusDashboard()}>
                Send me to the Status Dashboard
              </Button>
            </div>
          </Page>
        </main>
      </div>
    );
  }
}

export default connect(
  (state) => ({
  }), {
}
)(MaintenancePage);