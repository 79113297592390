import React, { Component } from 'react';
import { connect } from 'react-redux';

import StatisticsChartsModal from './StatisticsChartsModal';

import { getMonitorNamesData, toggleTimeControlAreaOpened, updateChartsToUpdateParameter, resetChartNamesData, updateSelectedMonitorAssetNumber } from '../../reducers/StatisticsChartsReducer';

import './StatisticsCharts.css';
import TitleComponent from '../Helper/TitleComponent';
import StatisticsChartsFilterArea from './StatisticsChartsFilterArea';
import StatisticsChartsMonitorAccordion from './StatisticsChartsMonitorAccordion';
import StatisticsChartButtonsRow from './StatisticsChartButtonsRow';
import ChartDownloadModal from './ChartDownloadModal';
import ChartInfoModal from './ChartInfoModal';
import ChartUpdateConfirmationModal from './ChartUpdateConfirmationModal';

class StatisticsChartsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      chartVisibilityControl: [],
      isStatisticsChartsModalOpen: window.localStorage.getItem('isStatisticsChartsModalOpen') === "true" || false
    };

    this.setOpen = this.setOpen.bind(this);
    this.setComponentModalOpen = this.setComponentModalOpen.bind(this);
    this.updateChartVisibilityControl = this.updateChartVisibilityControl.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.envSchema && this.props.envSchema && prevProps.envSchema.id !== this.props.envSchema.id) {
      //We reset the chart visibility control array
      this.setState(prevState => ({ chartVisibilityControl: new Array(prevState.chartVisibilityControl.length).fill(false) }));

      this.props.getMonitorNamesData(this.props.envSchema.id);
    }

    if (prevProps.monitorNames && this.props.monitorNames && prevProps.monitorNames.length !== this.props.monitorNames.length) {
      this.setState({ chartVisibilityControl: new Array(this.props.monitorNames.length).fill(false) });
    }
  }

  checkStyleForChartsData = () => {
    if (this.props.monitorNames && this.props.monitorNames.length > 0) {
      return {};
    } else {
      return { display: 'none' }
    }
  }

  updateChartVisibilityControl = (index, value) => {
    this.setState(prevState => {
      let newChartVisibilityControl = Array(prevState.chartVisibilityControl.length).fill(false);

      newChartVisibilityControl.splice(index, 1, value);

      return { chartVisibilityControl: newChartVisibilityControl }
    });

    this.props.updateSelectedMonitorAssetNumber(value ? this.props.monitorNames[index].assetNum : null);
    this.props.updateChartsToUpdateParameter('');
    this.props.resetChartNamesData();
  }

  setOpen = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  }

  setComponentModalOpen = (value) => {
    this.setState({ chartVisibilityControl: new Array(this.props.monitorNames.length).fill(false) });

    window.localStorage.setItem('isStatisticsChartsModalOpen', value);

    this.setState({ isStatisticsChartsModalOpen: value });
  }

  render() {
    return (
      <React.Fragment>
        <div className="pageComponentArea" style={this.checkStyleForChartsData()}>
          <TitleComponent
            open={this.state.open}
            setOpen={this.setOpen}
            message="Performance Statistics"
            setModalOpen={this.setComponentModalOpen} />
          {this.state.open &&
            <React.Fragment>
              <StatisticsChartButtonsRow />

              {this.props.timeControlAreaOpened && <StatisticsChartsFilterArea isDesktop={true} />}

              <StatisticsChartsMonitorAccordion
                isModal={false}
                isStatisticsChartsModalOpen={this.state.isStatisticsChartsModalOpen}
                chartVisibilityControl={this.state.chartVisibilityControl} />
            </React.Fragment>
          }
        </div>

        <ChartDownloadModal />
        <ChartInfoModal />
        <ChartUpdateConfirmationModal
          assetNumber={this.state.selectedMonitorId}
          setModalOpen={this.setChartConfirmationModalOpen}
          applyTimeControlChanges={this.applyTimeControlChanges} />
        <StatisticsChartsModal
          isStatisticsChartsModalOpen={this.state.isStatisticsChartsModalOpen}
          closeModal={this.setComponentModalOpen}
          chartVisibilityControl={this.state.chartVisibilityControl} />
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    monitorNames: state.statisticCharts.monitorNamesData,
    timeControlAreaOpened: state.statisticCharts.timeControlAreaOpened
  }), {
  getMonitorNamesData,
  toggleTimeControlAreaOpened,
  updateChartsToUpdateParameter,
  updateSelectedMonitorAssetNumber,
  resetChartNamesData
}
)(StatisticsChartsComponent);