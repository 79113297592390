import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button } from 'carbon-components-react';
import { BUTTON_COLOR, generateAutoZoomIcon, generateChartInfoIcon, generateDownloadIcon, generateResetZoomIcon } from './IconHelper';
import StatisticsChartDataVisualization from './StatisticsChartDataVisualization';
import ChartComponent from './ChartComponent';
import { updateIsChartInfoModalOpen, updateIsDownloadChartModalOpen, updateSelectedChartInfoData } from '../../reducers/StatisticsChartsReducer';
import { generateChartId } from './OptionHelper';

const ChartContainer = (props) => {
  const [minYAxisValue, setMinYAxisValue] = useState(0);
  const [overridenThresholdChartIndex, setOverridenThresholdChartIndex] = useState(-1);

  useEffect(() => {
    if (minYAxisValue > 0) {
      setMinYAxisValue(0);
    }
  }, [minYAxisValue]);

  useEffect(() => {
    console.log(props.chartData.datasets);
  }, [props.chartData.datasets]);

  const openDownloadChartModal = () => {
    props.updateSelectedChartInfoData({
      chartName: props.chartData.name,
      assetNumber: props.selectedMonitorAssetNumber,
      chartIds: [],
      initialTimeInMilliseconds: props.initialTimeInMilliseconds,
      finalTimeInMilliseconds: props.finalTimeInMilliseconds
    });
    props.updateIsDownloadChartModalOpen(true);
  }

  const openInfoChartModal = () => {
    props.updateSelectedChartInfoData({
      chartDescription: props.chartData.description
    });
    props.updateIsChartInfoModalOpen(true);
  }

  const resetZoom = () => {
    setMinYAxisValue(1);
    setOverridenThresholdChartIndex(-1);
  }

  if (props.chartData.notUpdated) {
    return (
      <div className={props.isModal ? "chartNotUpdatedAreaModal" : "chartNotUpdatedArea"} key={props.index}>
        Chart {props.chartData.name} was not updated in your request.
      </div>
    )
  } else {
    return (
      <div className={props.isModal ? "chartAreaModal" : "chartArea"} key={props.index}
        title={props.tooltipEnabled ? 'You can scroll to zoom in and out the graph! Feel free to move across the zoomed graph, too!' : ''}>
        <div className="reverseRow">
          <Button
            className="toolbarButtonChart"
            hasIconOnly
            title="Press this button to download the chart"
            onClick={() => openDownloadChartModal()}>
            {generateDownloadIcon(BUTTON_COLOR)}
          </Button>
          &nbsp;
          {props.chartData.threshold !== 0 && <div>
            <Button
              className="toolbarButtonChart"
              title="Press this button to automatically zoom the chart"
              disabled={props.chartData.threshold === 0}
              onClick={() => setOverridenThresholdChartIndex(props.index)}>
              {generateAutoZoomIcon(BUTTON_COLOR)}
            </Button>
            &nbsp;
          </div>}
          {props.chartData.type === 'LINE' && <Button
            className="toolbarButtonChart"
            title="Press this button to reset the zoom"
            onClick={() => resetZoom()}>
            {generateResetZoomIcon(BUTTON_COLOR)}
          </Button>}
          &nbsp;
          <Button
            className="toolbarButtonChart"
            title="Press this button to display the chart's info"
            disabled={props.chartData.description === '' || props.chartData.description === null}
            onClick={() => openInfoChartModal()}>
            {generateChartInfoIcon(BUTTON_COLOR)}
          </Button>
        </div>
        <div id={generateChartId(props.selectedMonitorAssetNumber, props.chartData.name)}>
          <ChartComponent
            chartData={props.chartData}
            overridenThresholdChartIndex={overridenThresholdChartIndex}
            minYAxisValue={minYAxisValue}
            datasetsHiddenArray={props.datasetsHiddenArray} />
        </div>
        <br />
        <StatisticsChartDataVisualization
          isModal={props.isModal}
          type={props.chartData.type}
          chartIndex={props.index}
          datasets={props.chartData.datasets}
          onClickInfo={props.onClickInfo}
          reorderDataToMax={props.reorderDataToMax}
          reorderDataToMin={props.reorderDataToMin}
          reorderDataToAverage={props.reorderDataToAverage}
          reorderDataToCurrent={props.reorderDataToCurrent}
          datasetsHiddenArray={props.datasetsHiddenArray} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    tooltipEnabled: state.statisticCharts.tooltipEnabled,
    selectedMonitorAssetNumber: state.statisticCharts.selectedMonitorAssetNumber
  }), {
  updateSelectedChartInfoData,
  updateIsDownloadChartModalOpen,
  updateIsChartInfoModalOpen
}
)(ChartContainer);