import React from 'react';
import { connect } from 'react-redux';

import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-react';
import StatisticsChartsDownloader from './StatisticsChartsDownloader';
import { updateIsDownloadChartModalOpen } from '../../reducers/StatisticsChartsReducer';

const ChartDownloadModal = (props) => {
  const createTitle = () => {
    if (props.shouldDownloadAllCharts) {
      return (<div>
        You will now download <span className='bold'>all the charts of monitor {props.selectedMonitorAssetNumber}</span>.
      </div>);
    } else {
      return (<div>
        You will now download the chart <span className='bold'>{getDocumentTitle()}</span>.
      </div>);
    }
  }

  const getDocumentTitle = () => {
    return props.selectedChartInfoData.chartName + " (" + props.selectedChartInfoData.assetNumber + ")";
  }

  return (
    <ComposedModal
      open={props.isDownloadChartModalOpen}
      onClose={() => props.updateIsDownloadChartModalOpen(false)}>
      <ModalHeader
        title='Download Statistic Chart'
        iconDescription='Download Modal'
        buttonOnClick={() => props.updateIsDownloadChartModalOpen(false)}>
      </ModalHeader>
      <ModalBody>
        <React.Fragment>
          {createTitle()}
          <br />
          <br />
          <span>Do you wish to proceed?</span>
        </React.Fragment>
      </ModalBody>
      <ModalFooter>
        <Button
          kind="secondary"
          onClick={() => props.updateIsDownloadChartModalOpen(false)}>
          Cancel
        </Button>
        {props.isDownloadChartModalOpen &&
          <StatisticsChartsDownloader />
        }
      </ModalFooter>
    </ComposedModal>
  );
}

export default connect(
  (state) => ({
    chartData: state.statisticCharts.chartData,
    selectedMonitorAssetNumber: state.statisticCharts.selectedMonitorAssetNumber,
    isDownloadChartModalOpen: state.statisticCharts.isDownloadChartModalOpen,
    shouldDownloadAllCharts: state.statisticCharts.shouldDownloadAllCharts,
    selectedChartInfoData: state.statisticCharts.selectedChartInfoData
  }), {
  updateIsDownloadChartModalOpen
}
)(ChartDownloadModal);