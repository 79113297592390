import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, DatePicker, DatePickerInput, Tab, Tabs } from 'carbon-components-react';
import { resetDateFilterValues, toggleTimeControlAreaOpened, updateFinalFilterDate, updateFinalFilterTime, updateInitialFilterDate, updateInitialFilterTime, updateIsChartUpdateConfirmationModalOpen, updateTriggerToUpdateCharts, updateSelectedTimeControlOption, updateSelectedTimeOptionTab } from '../../reducers/StatisticsChartsReducer';
import { MILLISECONDS_IN_ONE_DAY, TIME_CONTROL_OPTIONS } from './TimeControlHelper';
import ReactInputMask from 'react-input-mask';

const MAX_NUMBER_OF_DAYS = 90;

const StatisticsChartFilterArea = (props) => {
  const [areDatesEmpty, setAreDatesEmpty] = useState(false);
  const [isTimeRangeInvalid, setIsTimeRangeInvalid] = useState(false);
  const [isFinalDateBeforeInitialDate, setIsFinalDateBeforeInitialDate] = useState(false);

  useEffect(() => {
    let filterData = {
      initialFilterDate: props.initialFilterDate,
      initialFilterTime: props.initialFilterTime,
      finalFilterDate: props.finalFilterDate,
      finalFilterTime: props.finalFilterTime
    }

    if (props.selectedTimeOptionTab === 1 &&
      !Object.values(filterData).every(atribute => atribute === '') && !Object.values(filterData).every(atribute => atribute !== '')) {
      setAreDatesEmpty(true);
    } else {
      setAreDatesEmpty(false);
    }
  }, [props.selectedTimeOptionTab, props.initialFilterDate, props.initialFilterTime, props.finalFilterDate, props.finalFilterTime]);

  useEffect(() => {
    if (props.selectedTimeOptionTab === 1) {
      let initialDate = new Date(props.initialFilterDate);
      let finalDate = new Date(props.finalFilterDate);

      if (props.initialFilterDate && props.finalFilterDate &&
        Math.round(props.finalFilterDate.getTime() - props.initialFilterDate.getTime()) / (MILLISECONDS_IN_ONE_DAY) > MAX_NUMBER_OF_DAYS) {
        setIsTimeRangeInvalid(true);
      } else {
        setIsTimeRangeInvalid(false);
      }

      if (props.selectedTimeOptionTab === 1 && initialDate > finalDate) {
        setIsFinalDateBeforeInitialDate(true);
      } else {
        setIsFinalDateBeforeInitialDate(false);
      }
    }
  }, [props.selectedTimeOptionTab, props.initialFilterDate, props.finalFilterDate]);

  const createTimeControlOptions = () => {
    return TIME_CONTROL_OPTIONS.map((timeControlOption, index) =>
      <Button
        kind={props.selectedTimeControlOption === index ? "primary" : "tertiary"}
        className="timeControlOptionButton"
        key={index}
        onClick={() => props.updateSelectedTimeControlOption(index)}>
        {timeControlOption.text}
      </Button>
    );
  }

  const createDatePickers = () => {
    return (
      <div className="datePickerContainer">
        <div className="dateArea">
          <div>
            <div>
              From Date:
            </div>
            &nbsp;
            <div className="dateItemsArea">
              <div className="datePickerArea">
                <DatePicker
                  id="date-picker-time-range-start"
                  datePickerType="single"
                  value={props.initialFilterDate}
                  onChange={(value) => props.updateInitialFilterDate(value[0])}>
                  <DatePickerInput
                    id="date-picker-time-range-input-id-start"
                    labelText=""
                    pattern="d{1,2}/d{4}"
                    placeholder="mm/dd/yyyy"
                    invalidText="A valid value is required"
                  />
                </DatePicker>
              </div>
              <div className="timePickerChartArea">
                <ReactInputMask
                  id="time-picker-time-range-start"
                  className="timePicker"
                  placeholder="hh:mm"
                  mask="29:59"
                  formatChars={{
                    '9': '[0-9]',
                    '2': '[0-2]',
                    '5': '[0-5]'
                  }}
                  value={props.initialFilterTime}
                  onChange={(value) => props.updateInitialFilterTime(value.target.value)}>
                </ReactInputMask>
              </div>
            </div>
          </div>
        </div>
        <div className="dateArea">
          <div>
            <div>
              To Date:
            </div>
            &nbsp;
            <div className="dateItemsArea">
              <div className="datePickerArea">
                <DatePicker
                  id="date-picker-time-range-end"
                  datePickerType="single"
                  value={props.finalFilterDate}
                  maxDate={new Date().toISOString()}
                  onChange={(value) => props.updateFinalFilterDate(value[0])}>
                  <DatePickerInput
                    id="date-picker-time-range-input-id-end"
                    labelText=""
                    pattern="d{1,2}/d{4}"
                    placeholder="mm/dd/yyyy"
                    invalidText="A valid value is required"
                  />
                </DatePicker>
              </div>
              <div className="timePickerChartArea">
                <ReactInputMask
                  id="time-picker-time-range-end"
                  className="timePicker"
                  placeholder="hh:mm"
                  mask="29:59"
                  formatChars={{
                    '9': '[0-9]',
                    '2': '[0-2]',
                    '5': '[0-5]'
                  }}
                  value={props.finalFilterTime}
                  onChange={(value) => props.updateFinalFilterTime(value.target.value)}>
                </ReactInputMask>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onClickApply = () => {
    props.selectedMonitorAssetNumber ? props.updateIsChartUpdateConfirmationModalOpen(true) : props.updateTriggerToUpdateCharts(true);
  }

  const areDatesInvalid = () => {
    return props.selectedTimeOptionTab === 1 && (areDatesEmpty || isTimeRangeInvalid || isFinalDateBeforeInitialDate);
  }

  return (
    <div className="filterContainer">
      <div className="filterArea">
        <Tabs className="timeFilterTabsArea"
          selected={props.selectedTimeOptionTab}
          onSelectionChange={(index) => props.updateSelectedTimeOptionTab(index)}>
          <Tab id="tab-quick-selection" label="Quick time selection">
            <div className="timeOptionButtonsArea">
              {createTimeControlOptions()}
            </div>
          </Tab>
          <Tab id="tab-absolute-selection" label="Absolute time selection">
            {createDatePickers()}
            &nbsp;
            {areDatesEmpty && <div className="redText">
              The date values must be completely filled
            </div>}
            {isTimeRangeInvalid && <div className="redText">
              The time range period must be lower than 90 days
            </div>}
            {isFinalDateBeforeInitialDate && <div className="redText">
              The initial date must be more lower than the final date
            </div>}
            <br />
          </Tab>
        </Tabs>

        {props.isDesktop ?
          <div className="reverseRow statisticConfirmationButtonRow">
            <Button kind="primary" className="statisticConfirmationButton" disabled={areDatesInvalid()} onClick={() => onClickApply()}>
              Apply
            </Button>
            {props.selectedTimeOptionTab === 1 &&
              <Button kind="tertiary" className="statisticConfirmationButton" onClick={() => props.resetDateFilterValues()}>
                Reset
              </Button>
            }
            <Button kind="secondary" className="statisticConfirmationButton" onClick={() => props.toggleTimeControlAreaOpened()}>
              Cancel
            </Button>
          </div>
          :
          <div>
            <Button kind="secondary" className="statisticConfirmationButton" onClick={() => props.toggleTimeControlAreaOpened()}>
              Cancel
            </Button>
            <div className="spaceBetweenFilterButtons" />
            {props.selectedTimeOptionTab === 1 && <Button kind="tertiary" className="statisticConfirmationButton" onClick={() => props.resetDateFilterValues()}>
              Reset
            </Button>}
            <div className="spaceBetweenFilterButtons" />
            <Button kind="primary" className="statisticConfirmationButton" disabled={areDatesInvalid()} onClick={() => onClickApply()}>
              Apply
            </Button>
          </div>
        }
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    selectedTimeOptionTab: state.statisticCharts.selectedTimeOptionTab,
    selectedTimeControlOption: state.statisticCharts.selectedTimeControlOption,
    selectedMonitorAssetNumber: state.statisticCharts.selectedMonitorAssetNumber,
    initialFilterDate: state.statisticCharts.initialFilterDate,
    initialFilterTime: state.statisticCharts.initialFilterTime,
    finalFilterDate: state.statisticCharts.finalFilterDate,
    finalFilterTime: state.statisticCharts.finalFilterTime
  }), {
  toggleTimeControlAreaOpened,
  updateSelectedTimeOptionTab,
  updateSelectedTimeControlOption,
  updateInitialFilterDate,
  updateInitialFilterTime,
  updateFinalFilterDate,
  updateFinalFilterTime,
  updateIsChartUpdateConfirmationModalOpen,
  updateTriggerToUpdateCharts,
  resetDateFilterValues
}
)(StatisticsChartFilterArea);