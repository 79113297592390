import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CommunicationsDataTable from '../CommunicationsDataTable';
import { STATUS_CONTENT_SWITCHER_VALUES } from '../CommunicationsHelper';
import { filterAlerts, updateAlertFinalDateFilter, updateAlertFinalTimeFilter, updateAlertInitialDateFilter, updateAlertInitialTimeFilter, updateAlertSearchValue, updateAlertStatusFiltersControl } from '../../../reducers/CommunicationsReducer';
import CommunicationsCalendarModal from '../CommunicationsCalendarModal';
import CommunicationsDownloadModal from '../CommunicationsDownloadModal';
import { ALERT_HEADERS } from '../HeaderHelper';
import AlertsDataTableModal from './AlertsDataTableModal';

const AlertsDataTable = (props) => {
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  useEffect(() => {
    let initialFilterData, finalFilterData, hours, minutes;
    let subCategories = props.notificationSubCategories;
    let statusAllowed = [];
    let subCategoriesAllowed = [];
    let subCategoriesFiltered = subCategories
      .filter(item => "Alert".includes(item.parentCategory))
      .map(item => item.subCategory);
    let isSubCategorySelected = false;

    if (props.alertInitialDateFilter) {
      initialFilterData = new Date(props.alertInitialDateFilter);

      if (props.alertInitialTimeFilter) {
        hours = props.alertInitialTimeFilter.split(':')[0];
        minutes = props.alertInitialTimeFilter.split(':')[1];

        initialFilterData.setHours(hours, minutes);
      }
    }

    if (props.alertFinalDateFilter) {
      finalFilterData = new Date(props.alertFinalDateFilter);

      if (props.alertFinalTimeFilter) {
        hours = props.alertFinalTimeFilter.split(':')[0];
        minutes = props.alertFinalTimeFilter.split(':')[1];

        finalFilterData.setHours(hours, minutes);
      }
    }

    if (props.subCategoryFilters.every(item => !item.index)) {
      subCategoriesAllowed = subCategoriesFiltered;
    } else {
      subCategoriesAllowed = props.subCategoryFilters
        .filter(item => item.index === true)
        .map(item => item.subCategory);

      isSubCategorySelected = true;
    }

    if (props.alertStatusFilters.every(value => value === false)) {
      statusAllowed = STATUS_CONTENT_SWITCHER_VALUES.map(v => v.toLowerCase());
    } else {
      props.alertStatusFilters.forEach((value, key) => {
        if (value) {
          statusAllowed.push(STATUS_CONTENT_SWITCHER_VALUES[key].toLowerCase());
        }
      });
    }

    props.filterAlerts({
      initialFilterData: initialFilterData,
      finalFilterData: finalFilterData,
      statusAllowed: statusAllowed,
      subCategoriesAllowed: subCategoriesAllowed,
      isSubCategorySelected: isSubCategorySelected
    });
  }, [props.alertInitialDateFilter, props.alertInitialTimeFilter, props.alertFinalDateFilter, props.alertFinalTimeFilter,
  props.subCategoryFilters, props.alertStatusFilters]);
  
  return (
    <React.Fragment>
      <CommunicationsDataTable
        isNotificationData={false}
        displayCommunications={props.displayAlerts}
        filteredCommunications={props.filteredAlerts}
        headers={ALERT_HEADERS}
        isMobile={props.isMobile}
        isDownloadModalOpen={isDownloadModalOpen}
        setIsDownloadModalOpen={setIsDownloadModalOpen}
        initialDateFilter={props.alertInitialDateFilter}
        initialTimeFilter={props.alertInitialTimeFilter}
        finalDateFilter={props.alertFinalDateFilter}
        finalTimeFilter={props.alertFinalTimeFilter}
        updateInitialDateFilter={props.updateAlertInitialDateFilter}
        updateInitialTimeFilter={props.updateAlertInitialTimeFilter}
        updateFinalDateFilter={props.updateAlertFinalDateFilter}
        updateFinalTimeFilter={props.updateAlertFinalTimeFilter}
        statusFilters={props.alertStatusFilters}
        updateStatusFiltersControl={props.updateAlertStatusFiltersControl}
        upateCommunicationsStatusFilter={props.updateAlertStatusFiltersControl}
        subCategoryFilters={props.subCategoryFilters}
        searchValue={props.alertSearchValue}
        updateCommunicationSearchValue={props.updateAlertSearchValue}
        loadCommunicationStatus={props.loadNotificationStatus} />
      <CommunicationsCalendarModal
        displayCommunications={props.displayAlerts}
        filterCommunications={props.filterAlerts} />
      <CommunicationsDownloadModal
        isNotificationData={false}
        data={props.displayAlerts} />
    </React.Fragment>
  )
}

export default connect(
  (state) => ({
    displayAlerts: state.communications.displayAlerts,
    filteredAlerts: state.communications.filteredAlerts,
    alertPageNumber: state.communications.alertPageNumber,
    alertPageSize: state.communications.alertPageSize,
    alertFilterAreaOpened: state.communications.alertFilterAreaOpened,
    alertInitialDateFilter: state.communications.alertInitialDateFilter,
    alertInitialTimeFilter: state.communications.alertInitialTimeFilter,
    alertFinalDateFilter: state.communications.alertFinalDateFilter,
    alertFinalTimeFilter: state.communications.alertFinalTimeFilter,
    alertSearchValue: state.communications.alertSearchValue,
    alertStatusFilters: state.communications.alertStatusFiltersControl,
    subCategoryFilters: state.communications.subCategoryFiltersControl,
    notificationSubCategories: state.communications.notificationSubCategories,
    loadNotificationStatus: state.communications.loadNotificationStatus
  }), {
  filterAlerts,
  updateAlertInitialDateFilter,
  updateAlertInitialTimeFilter,
  updateAlertFinalDateFilter,
  updateAlertFinalTimeFilter,
  updateAlertSearchValue,
  updateAlertStatusFiltersControl
}
)(AlertsDataTable);