import { ComboBox } from 'carbon-components-react';
import React from 'react';
import { connect } from 'react-redux';
import { createComboboxValues } from './PerformanceTablesHelper';
import { compareAlphabetically, filterComboItem } from '../Helper/ComponentHelper';

const PerformanceTablesMonitorCombobox = (props) => {
  const onUpdateCombobox = (selectedItem) => {
    if (selectedItem) {
      props.requestPerformanceTablesData(selectedItem.id);
    }
  }

  return (
    <ComboBox
      className="cccCombobox width50"
      placeholder='Select the monitor'
      id='monitor-bigtable-combo'
      items={createComboboxValues(props.monitorNames).sort(compareAlphabetically)}
      light
      itemToString={item => (item ? item.text : '')}
      shouldFilterItem={(object) => filterComboItem(object)}
      onChange={(event) => onUpdateCombobox(event.selectedItem)}
    />
  );
}

export default connect(
  (state) => ({
    monitorNames: state.statisticCharts.monitorNamesData
  }), {
}
)(PerformanceTablesMonitorCombobox);