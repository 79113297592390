import React from "react";
import { connect } from 'react-redux';

import { DataTable, Tag } from "carbon-components-react";
import { getTagType } from "../Helper/ComponentHelper";

const {
  TableCell
} = DataTable;

const TagCell = (props) => {
  return (
    <React.Fragment>
      <TableCell key={props.cellId}>
        <div className='tagCellRow'>
          <Tag className='cellDateText' type={getTagType(props.cellValue)}>
            <span className='bold textAlignCenter'>{props.cellValue}</span>
          </Tag>
          {props.subCategoryCell && props.subCategoryCell.value && <Tag type={getTagType(props.cellValue)}>
            <span className='textAlignCenter cellDateText'>{props.subCategoryCell.value}</span>
          </Tag>}
        </div>
      </TableCell>
    </React.Fragment>
  );
}

export default connect(
  (state) => ({
  }), {
}
)(TagCell);