import React from 'react';
import { connect } from 'react-redux';

import {
  ComposedModal,
  ModalHeader,
  ModalBody
} from 'carbon-components-react';

import { loadMonitorData } from '../../reducers/HealthStatusReducer';
import { updateDate, updateMustUpdateFlag } from '../../reducers/UserReducer';

import './HealthStatus.css';
import '../Dashboard/Dashboard.css';

import Banner from '../Banner/Banner';
import { createLastUpdateText } from '../Helper/ComponentHelper';
import HealthStatusTabsComponent from './HealthStatusTabsComponent';

const HealthStatusModal = (props) => {
  const createModalBody = () => {
    return (
      <React.Fragment>
        {createLastUpdateText(props.date)}
        <Banner />
        <HealthStatusTabsComponent />
      </React.Fragment>
    );
  }

  return (
    <div className="desktopOnly">
      <ComposedModal
        open={props.isModalOpen}
        onClose={() => props.closeModal(false)}>
        <ModalHeader
          title="Health Status"
          iconDescription='Close Modal'
          buttonOnClick={() => props.closeModal(false)} />
        <ModalBody className="modalBodyWithOverflow">
          {createModalBody()}
        </ModalBody>
      </ComposedModal>
    </div>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    date: state.user.date,
    serviceType: state.user.selectedServiceType,
    updateFlag: state.user.mustUpdateHealthStatus,
    monitorData: state.healthStatus.monitorData
  }), {
  loadMonitorData,
  updateMustUpdateFlag,
  updateDate
}
)(HealthStatusModal);