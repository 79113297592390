import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-react';
import { CSVLink } from 'react-csv';
import { updateIsDownloadModalOpen } from '../../reducers/PerformanceTablesReducer';
import { convertBytes, displayDate, displayNumber } from './PerformanceTablesHelper';

const DOWNLOAD_HEADERS = [{
  key: 'id',
  label: 'Table Id',
}, {
  key: 'totalRecord',
  label: 'Total Record'
}, {
  key: 'tableSize',
  label: 'Table Size',
}, {
  key: 'lastRunStatsDate',
  label: 'Last Run Stats Date',
}, {
  key: 'avgRowSize',
  label: 'Avg Row Size',
}, {
  key: 'tableStorageSize',
  label: 'Table Storage Size',
}];

const PerformanceTablesDownloadModal = (props) => {
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    if (props.data && props.isDownloadModalOpen) {
      setDownloadData(treatData([...props.data]));
    }
  }, [props.data, props.isDownloadModalOpen]);

  const treatData = (rawData) => {
    rawData.forEach((value) => {
      value.totalRecord = displayNumber(convertBytes(value.totalRecord));
      value.tableSize = displayNumber(convertBytes(value.tableSize));
      value.lastRunStatsDate = displayDate(value.lastRunStatsDate);
      value.avgRowSize = displayNumber(convertBytes(value.avgRowSize));
      value.tableStorageSize = displayNumber(convertBytes(value.tableStorageSize));
    });

    return rawData;
  }

  const createModalBody = () => {
    return (
      <div>
        <p>
          <span>You will now download all of the Big Tables data presented in this data table.</span>
          <br />
          <br />
          <span>Do you wish to proceed?</span>
        </p>
      </div>
    );
  }

  return (
    <ComposedModal
      open={props.isDownloadModalOpen}
      onClose={() => props.updateIsDownloadModalOpen(false)}>
      <ModalHeader
        title='Download Warning'
        iconDescription='Download Modal'
        buttonOnClick={() => props.updateIsDownloadModalOpen(false)}
      />
      <ModalBody>
        {createModalBody()}
      </ModalBody>
      <ModalFooter>
        <Button
          kind="secondary"
          onClick={() => props.updateIsDownloadModalOpen(false)}>
          Cancel
        </Button>
        <CSVLink
          data={downloadData}
          headers={DOWNLOAD_HEADERS}
          filename={`bigtables-data-${props.envSchema.id}.csv`}
          className="downloadCSV"
          target="_blank">
          <Button
            className="downloadButton"
            onClick={() => props.updateIsDownloadModalOpen(false)}>
            Yes, Download the Big Tables data
          </Button>
        </CSVLink>
      </ModalFooter>
    </ComposedModal>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    isDownloadModalOpen: state.performanceTables.isDownloadModalOpen
  }), {
  updateIsDownloadModalOpen
}
)(PerformanceTablesDownloadModal);