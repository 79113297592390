import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-react';
import React, { Component } from 'react';
import {connect} from 'react-redux' ;

import '../Dashboard/Dashboard.css';
import { loadUserEmail } from '../../reducers/UserReducer';
import Page from './Page';
import PageHeader from './PageHeader';

class LoginFailurePage extends Component {
  constructor(props) {
    super(props);

    this.state = {logoutHasBeenPressed: false, isCompleteLogoutModalOpen: false};
  }

  componentDidMount() {
    this.props.loadUserEmail();
  }

  openModal = () => {
    this.setState({isCompleteLogoutModalOpen: true});
  }

  onLogoutClick = () => {
    this.setState({logoutHasBeenPressed: true});
    this.callIdaasLogout();
  }

  callIdaasLogout() {
    var ibmLogout = window.open("https://idaas.iam.ibm.com/pkmslogout", '_blank');
    var openModal = this.openModal;

    setTimeout(function () { ibmLogout.close(); openModal(); }, 2000);
  }

  completeLogout() {
    var ibmLogout = window.open("https://login.ibm.com/pkmslogout", '_blank');
    var submitInternalLogout = this.submitInternalLogout;

    setTimeout(function () { ibmLogout.close(); submitInternalLogout()}, 3000);
  }

  submitInternalLogout() {
    let arrayUrl = window.location.href.split('.com/');

    window.location.href = arrayUrl[0] + '.com/logoutfromloginfailure';
  }

  render() {
    return (
      <div className="app">
          <PageHeader isLoginFailurePage={true}/>
          <main id="content" className="app-main-content">
          <Page>
            <div>
              Oops, the login user {this.props.user.userEmail} is not authorized for this page.
              <br/>
              <br/>
              Please, click on the button below to logout from the page and enter a valid e-mail.
              <br/>
              <br/>
              <Button onClick={() => this.onLogoutClick()}>Login Again</Button>
            </div>
            <ComposedModal
              open={this.state.isCompleteLogoutModalOpen}
              onClose={() => this.completeLogout()}>
              <ModalHeader
                title='Confirm the logout'
                iconDescription='Close Modal'
                buttonOnClick={() => this.completeLogout()}/>
              <ModalBody className="modalBody">
                <div className="modalTextContainer">
                  <div>
                    Please, click the button below to complete the logout.
                  </div>
                  &nbsp;
                  <div>
                    You will be redirected to the dashboard you tried to access before.
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => this.completeLogout()}>Complete the logout</Button>
              </ModalFooter>
            </ComposedModal>
          </Page>
        </main>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user
  }), {
    loadUserEmail
  }
)(LoginFailurePage);