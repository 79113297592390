import React from 'react';
import { connect } from 'react-redux';

import {
  SUBMISSION_STATUS_INITIAL_STATUS,
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_SUCCESS,
  SUBMISSION_STATUS_SUBMITTED
} from '../../../reducers/BaseReducer';

import { Link, TextInputSkeleton } from 'carbon-components-react';
import EmailSubscriptionContentSwitcher from './EmailSubscriptionContentSwitcher';
import EmailSubscriptionStatusText from './EmailSubscriptionStatusText';
import EmailSubscriptionTextField from './EmailSubscriptionTextField';
import EmailSubscriptionCategoryOptionsArea from './EmailSubscriptionCategoryOptionsArea';
import { IBM_POLICE_URL, SUBSCRIPTION_OPTIONS } from './SubscriptionHelper';
import EmailSubscriptionRegionCombobox from './EmailSubscriptionRegionCombobox';
import EmailSubscriptionCategoriesAccordion from './EmailSubscriptionCategoriesAccordion';

const EmailSubscriptionModalBody = (props) => {
  const isSetToSubscribe = () => {
    return props.selectedSubscriptionOption === SUBSCRIPTION_OPTIONS[0];
  }

  const shouldShowRegionCombobox = () => {
    return props.envSchema.visibility && props.envSchema.visibility.supportsRegionSubscription && props.regionAvailabilityStatus;
  }

  const shouldShowSubscriptionCategoriesAccordion = () => {
    return isSetToSubscribe() && props.subscriptionCategories.length > 0;
  }

  if (props.subscriptionStatus === SUBMISSION_STATUS_SUBMITTED) {
    return <TextInputSkeleton />;
  } else if (props.subscriptionStatus === SUBMISSION_STATUS_FAILED || props.subscriptionStatus === SUBMISSION_STATUS_SUCCESS) {
    return props.responseText;
  } else if (props.subscriptionStatus === SUBMISSION_STATUS_INITIAL_STATUS) {
    return (
      <div className="containerModalBody">
        <EmailSubscriptionContentSwitcher />

        <div className='containerItems'>
          <EmailSubscriptionStatusText />
        </div>

        <div className='containerItems'>
          <EmailSubscriptionTextField />
        </div>

        {isSetToSubscribe() && <div className='containerCheckboxes'>
          <EmailSubscriptionCategoryOptionsArea />
        </div>}

        {shouldShowRegionCombobox() && <div className="containerItems">
          <EmailSubscriptionRegionCombobox />
        </div>}

        {shouldShowSubscriptionCategoriesAccordion() && <div className="containerItems">
          <EmailSubscriptionCategoriesAccordion />
        </div>}

        <div className="containerItems">
          <Link
            className="formItems"
            href={IBM_POLICE_URL}>
            IBM Privacy Policy
          </Link>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    userEmail: state.user.userEmail,
    responseText: state.subscription.responseText,
    selectedSubscriptionOption: state.subscription.selectedSubscriptionOption,
    currentSubscriptions: state.subscription.currentSubscriptions,
    subscriptionCategories: state.subscription.subscriptionCategories,
    subscriptionStatus: state.subscription.subscriptionStatus,
    regionAvailabilityStatus: state.subscription.regionAvailabilityStatus,
    checkSubscriptionStatus: state.subscription.checkSubscriptionStatus
  }), {
}
)(EmailSubscriptionModalBody);