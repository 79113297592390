import React from 'react';
import { connect } from 'react-redux';

import { Line } from 'react-chartjs-2';
import { createOptions } from './OptionHelper';

const ChartContainer = (props) => {
  const chooseChart = () => {
    switch (props.chartData.type) {
      case 'LINE':
        return <Line
          useRef="chart"
          id={props.chartData.name}
          data={props.chartData}
          options={createOptions(props.chartData, props.overridenThresholdChartIndex, props.minYAxisValue)} />
      case 'TABLE':
        return <p className="textAlignCenter bold">
          {props.chartData.name}
        </p>
      default:
        return <Line
          useRef="chart"
          id={props.chartData.name}
          data={props.chartData}
          options={createOptions(props.chartData, props.overridenThresholdChartIndex, props.minYAxisValue)} />
    }
  }

  return (
    <React.Fragment>
      {chooseChart()}
    </React.Fragment>
  );
}

export default connect(
  (state) => ({
  }), {
}
)(ChartContainer);