import React from 'react';
import { connect } from 'react-redux';

import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Link
} from 'carbon-components-react';

import { closeModal } from '../../reducers/NoticeAPIReducer';

const MonitorAPIModal = (props) => {
  const createBody = () => {
    return (
      <div>
        <div>
          The Monitor API will allow the retrieval of the monitor status via this link:
        </div>
        <br />
        <div>
          {createDisplayUrl()}
        </div>
      </div>
    );
  }

  const createDisplayUrl = () => {
    let url = window.location.href;
    let displayUrl = '';

    if (props.fileType) {
      displayUrl += url.split('.com')[0] + '.com/monitor/iotm/api/v1/ext/' + props.encryptedEnvId;
    }

    return (
      <Link
        className="formItems"
        href={displayUrl}
        target="_blank">
        {displayUrl}
      </Link>
    );
  }

  return (
    <ComposedModal
      open={props.openModal}
      onClose={() => props.closeModal()}>
      <ModalHeader
        title='Get Monitor Status'
        iconDescription='Close Modal'
        buttonOnClick={() => props.closeModal()} />
      <ModalBody className="modalBody noticeAPIModal">
        {createBody()}
      </ModalBody>
    </ComposedModal>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    openModal: state.noticeAPI.openMonitorModal,
    url: state.noticeAPI.url,
    fileType: state.noticeAPI.fileType,
    encryptedEnvId: state.noticeAPI.encryptedEnvId
  }), {
  closeModal
}
)(MonitorAPIModal);