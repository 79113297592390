import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  loadNotifications,
  requestNotificationSubCategories
} from '../../reducers/CommunicationsReducer';
import { Tabs, Tab } from 'carbon-components-react';
import TitleComponent from '../Helper/TitleComponent';

import './Communications.css';
import NotificationsDataTable from './Notifications/NotificationsDataTable';
import NotificationsDataTableModal from './Notifications/NotificationsDataTableModal';
import AlertsDataTableModal from './Alerts/AlertsDataTableModal';
import AlertsDataTable from './Alerts/AlertsDataTable';

const MINIMAL_WIDTH_FOR_DESKTOP = 991;

class CommunicationTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      tabIndex: window.localStorage.getItem('isAlertModalOpen') === "true" ? 1 : 0,
      isNotificationModalOpen: window.localStorage.getItem('isNotificationModalOpen') === "true" || false,
      isNotificationCalendarModalOpen: window.localStorage.getItem('isNotificationCalendarModalOpen') === "true" || false,
      isAlertModalOpen: window.localStorage.getItem('isAlertModalOpen') === "true" || false
    }
    this.setOpen = this.setOpen.bind(this);
    this.setCommunicationsModalOpen = this.setCommunicationsModalOpen.bind(this);
    this.setNotificationCalendarModalOpen = this.setNotificationCalendarModalOpen.bind(this);
    this.setNotificationModalOpen = this.setNotificationModalOpen.bind(this);
    this.setAlertModalOpen = this.setAlertModalOpen.bind(this);
  }

  componentDidMount() {
    if (this.props.envSchemaDataAlreadyLoaded) {
      //If the envSchema data is already loaded when this renders, 
      //not triggering the DidUpdate. We must load the notifications immediatedly then
      if (this.props.envSchema && this.props.serviceType && this.props.envSchema.id && this.props.envSchema.solution && this.props.serviceType.name) {
        this.props.requestNotificationSubCategories(this.props.envSchema.solution);
        this.props.loadNotifications(this.props.envSchema.id, this.props.serviceType.name);
      }
    }
  }

  componentDidUpdate(prevProps) {
    //If customer id changed OR service type changed, we update the notifications
    if (this.checkIfCustomerIdChangedOrNew(prevProps) && this.props.serviceType && this.props.serviceType.name) {
      this.props.requestNotificationSubCategories(this.props.envSchema.solution);
      this.props.loadNotifications(this.props.envSchema.id, this.props.serviceType.name);
    } else if (this.checkIfServiceTypeChanged(prevProps) && this.props.envSchema && this.props.envSchema.id) {
      this.props.requestNotificationSubCategories(this.props.envSchema.solution);
      this.props.loadNotifications(this.props.envSchema.id, this.props.serviceType.name);
    }
  }

  checkIfCustomerIdChangedOrNew = (prevProps) => {
    if (prevProps.envSchema && this.props.envSchema) {
      if (prevProps.envSchema.id !== this.props.envSchema.id) {
        return true;
      }
    } else if (!prevProps.envSchema) {
      if (this.props.envSchema && this.props.envSchema.id) {
        return true;
      }
    }
    return false;
  }

  checkIfServiceTypeChanged = (prevProps) => {
    if (prevProps.serviceType && this.props.serviceType) {
      if (prevProps.serviceType.name !== this.props.serviceType.name) {
        return true;
      }
    } else if (!prevProps.serviceType) {
      if (this.props.serviceType && this.props.serviceType.name) {
        return true;
      }
    }
    return false;
  }

  getCommunicationsTitleMessage = () => {
    if (this.props.envSchema.customizedTitles && this.props.envSchema.customizedTitles.customizedCommunicationsTitle) {
      return this.props.envSchema.customizedTitles.customizedCommunicationsTitle;
    } else {
      return "Communications";
    }
  }

  shouldShowAlerts = () => {
    return this.props.envSchema.visibility && !this.props.envSchema.visibility.shouldHideAlerts;
  }

  setOpen = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  }

  setCommunicationsModalOpen = (value) => {
    if (this.state.tabIndex === 0) {
      this.setNotificationModalOpen(value);
    } else {
      this.setAlertModalOpen(value);
    }
  } 

  setNotificationModalOpen = (value) => {
    window.localStorage.setItem('isNotificationModalOpen', value);

    this.setState({ isNotificationModalOpen: value });
  }

  setAlertModalOpen = (value) => {
    window.localStorage.setItem('isAlertModalOpen', value);

    this.setState({ isAlertModalOpen: value });
  }

  setNotificationCalendarModalOpen = (value) => {
    window.localStorage.setItem('isNotificationCalendarModalOpen', value);

    this.setState({ isNotificationCalendarModalOpen: value });
  }

  render() {
    return (
      <div className="pageComponentArea">
        <TitleComponent
          open={this.state.open}
          setOpen={this.setOpen}
          message={this.getCommunicationsTitleMessage()}
          setModalOpen={this.setCommunicationsModalOpen}
          
         />
        {this.state.open &&
          <Tabs selected={this.state.tabIndex}>
            <Tab label="Notifications" tabIndex={0} onClick={() => this.setState({ tabIndex: 0 })}>
              <NotificationsDataTable
                isMobile={this.props.width <= MINIMAL_WIDTH_FOR_DESKTOP}
                isNotificationModalOpen={this.state.isNotificationModalOpen}
                setNotificationCalendarModalOpen={this.setNotificationCalendarModalOpen}
                setNotificationModalOpen={this.setNotificationModalOpen} />
            </Tab>
            {this.shouldShowAlerts() && <Tab label="Alerts" tabIndex={1} onClick={() => this.setState({ tabIndex: 1 })}>
              <AlertsDataTable
                isMobile={this.props.width <= MINIMAL_WIDTH_FOR_DESKTOP}
                isAlertModalOpen={this.state.isAlertModalOpen}
                setNotificationCalendarModalOpen={this.setNotificationCalendarModalOpen}
                setAlertModalOpen={this.setAlertModalOpen} />
            </Tab>}
          </Tabs>
        }
        <NotificationsDataTableModal 
          isModalOpen={this.state.isNotificationModalOpen}
          closeModal={this.setNotificationModalOpen} />

          <AlertsDataTableModal
        isModalOpen={this.state.isAlertModalOpen}
        closeModal={this.setAlertModalOpen} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    loadNotificationStatus: state.communications.loadNotificationStatus,
    envSchema: state.user.environmentSchema,
    serviceType: state.user.selectedServiceType,
    notificationSubCategories: state.communications.notificationSubCategories
  }), {
  loadNotifications,
  requestNotificationSubCategories
}
)(CommunicationTabs);