import React from 'react';
import { connect } from 'react-redux';

import { StructuredListCell, Tooltip } from 'carbon-components-react';

export const HealthStatusHeaderCellsRow = (props) => {
  const formatDataCenterName = (dataCenterName) => {
    if (dataCenterName.includes("(Pod")) {
      var startSplitIndex = dataCenterName.indexOf("(");
      var lastIndex = dataCenterName.indexOf(")");

      return <React.Fragment>
        <div>
          {dataCenterName.substring(0, startSplitIndex)}
        </div>
        <div>
          {dataCenterName.substring(startSplitIndex, lastIndex + 1)}
        </div>
      </React.Fragment>
    }

    return dataCenterName;
  }

  return props.datacentersInfoArray[props.selectedTabIndex].map((datacenterInfo) => {
    if (props.isDesktop) {
      return (
        <StructuredListCell head key={datacenterInfo.name}>
          <Tooltip
            direction="bottom"
            tabIndex={0}
            triggerText={
              <div className="monitorHeadCell">
                {formatDataCenterName(datacenterInfo.name)}
              </div>
            }>
            <div>
              {datacenterInfo.desc}
            </div>
          </Tooltip>
        </StructuredListCell>
      );
    } else {
      return (
        <StructuredListCell head key={datacenterInfo.name}>
          <div className="monitorHeadCell">
            {formatDataCenterName(datacenterInfo.name)}
          </div>
        </StructuredListCell>
      );
    }
  });
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    selectedTabIndex: state.healthStatus.selectedTabIndex,
    datacentersInfoArray: state.healthStatus.datacentersInfoArray
  }), {
}
)(HealthStatusHeaderCellsRow);