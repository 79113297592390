import React from 'react';
import { connect } from 'react-redux';

import { RadioButton } from 'carbon-components-react';
import { updateSubCategoryFiltersControl } from '../../../reducers/CommunicationsReducer';

const CommunicationsToolbarSubCategoryButton = (props) => {
  const isSubCategorySelected = (parentCategory, id) => {
    let isSubCategorySelected = props.subCategoryFilters && Array.isArray(props.subCategoryFilters) ?
      props.subCategoryFilters.some(filter => filter.id === id && filter.parentCategory === parentCategory && filter.index) :
      false;

    return isSubCategorySelected;
  }

  return (
    <RadioButton
      key={`${props.parentCategory}-${props.subCategory}`}
      className="radioButton"
      labelText={props.subCategory}
      checked={isSubCategorySelected(props.parentCategory, props.id)}
      onClick={() => { props.updateSubCategoryFiltersControl(props.parentCategory, props.subCategory, props.id) }} />
  );
}

export default connect(
  (state) => ({
    categoryFilters: state.communications.categoryFiltersControl,
    subCategoryFilters: state.communications.subCategoryFiltersControl
  }), {
  updateSubCategoryFiltersControl
}
)(CommunicationsToolbarSubCategoryButton);