import React from 'react';
import { connect } from 'react-redux';

import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-react';
import { updateIsChartInfoModalOpen } from '../../reducers/StatisticsChartsReducer';

const ChartInfoModal = (props) => {
  return (
    <ComposedModal
      open={props.isChartInfoModalOpen}
      onClose={() => props.updateIsChartInfoModalOpen(false)}>
      <ModalHeader
        title='Chart Information'
        iconDescription='Chart Info'
        buttonOnClick={() => props.updateIsChartInfoModalOpen(false)}>
      </ModalHeader>
      <ModalBody>
        <div className="chartDescriptionText">
          {props.selectedChartInfoData.chartDescription}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="reverseRow">
          <Button
            kind="secondary"
            onClick={() => props.updateIsChartInfoModalOpen(false)}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </ComposedModal>
  );
}

export default connect(
  (state) => ({
    isChartInfoModalOpen: state.statisticCharts.isChartInfoModalOpen,
    selectedChartInfoData: state.statisticCharts.selectedChartInfoData
  }), {
  updateIsChartInfoModalOpen
}
)(ChartInfoModal);