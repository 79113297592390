import React from 'react';
import { connect } from 'react-redux';
import { cleanHTML } from '../Helper/ComponentHelper';
import parse from 'html-react-parser';

import './Banner.css';

const Banner = (props) => {
  return (
    <React.Fragment>
      {props.banner &&
        <div className="bannerContainer">
          <div className="bannerTitle">
            {props.banner.title}
          </div>
          <div className="bannerBody">
            {parse(cleanHTML(props.banner.body))}
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    banner: state.communications.bannerNotification
  })
)(Banner);