import React from 'react';
import { connect } from 'react-redux';

import { WARNING_DETAILS_QUERY_IDS } from './PerformanceTablesQueryIds';
import WarningDetailsDataDisplay from './WarningDetailsDataDisplay';

const WarningDetailsComponent = (props) => {
  const createWarningComponent = (id, index) => {
    return <WarningDetailsDataDisplay
      index={index}
      data={props.warningDetailsDataObject[id]}
    />
  };

  return (
    <div className='performanceTablesDataContainer'>
      <div className='performanceTablesDataDisplay'>
        {WARNING_DETAILS_QUERY_IDS.map((id, index) => createWarningComponent(id, index))}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    warningDetailsDataObject: state.performanceTables.warningDetailsDataObject
  }), {
}
)(WarningDetailsComponent);