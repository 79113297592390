import React from 'react';
import { connect } from 'react-redux';
import Agenda from 'react-big-calendar/lib/Agenda'
import { Navigate } from 'react-big-calendar'
import * as dates from 'date-arithmetic'
import { MONTH_NAMES } from '../../Helper/TimeHelper';

const AgendaView = (props) => {
  let range, newStartDate;
  let { date } = props;

  newStartDate = new Date(date.getFullYear(), date.getMonth(), 1)

  range = AgendaView.range(new Date(date.getFullYear(), date.getMonth(), 0))

  return <Agenda {...props} date={newStartDate} length={new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - 1} range={range} />
}

AgendaView.range = date => {
  let start, end, current, range;

  start = date;

  end = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  current = start
  range = []

  while (dates.lte(current, end, 'day')) {
    range.push(current)
    current = dates.add(current, 1, 'day')
  }

  return range
}

AgendaView.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return new Date(date.getFullYear(), date.getMonth() - 1, 1);

    case Navigate.NEXT:
      return new Date(date.getFullYear(), date.getMonth() + 1, 1);

    default:
      return date;
  }
}

AgendaView.title = date => {
  return 'Communications in ' + MONTH_NAMES[date.getMonth()] + ' ' + date.getFullYear();
}

export default connect(
  (state) => ({
  }),
  {}
)(AgendaView);