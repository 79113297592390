import React from "react";

const CalendarIcon = ({
  className = "calendar-icon",
  height = "32",
  width = "32",
  viewBox = "0 0 32 32"
}) => (
  <svg className={className} width={width} height={height} viewBox={viewBox}>
    <path d="M26,4H22V2H20V4H12V2H10V4H6A2.0025,2.0025,0,0,0,4,6V26a2.0025,2.0025,0,0,0,2,2H26a2.0025,2.0025,0,0,0,2-2V6A2.0025,2.0025,0,0,0,26,4ZM6,6h4V8h2V6h8V8h2V6h4l0,4H6Zm0,6h5v6H6ZM19,26H13V20h6Zm0-8H13V12h6Zm2,8V20h5l.0012,6Z">
    </path>
  </svg>
);

const DownloadIcon = ({
  className = "download-icon",
  height = "32",
  width = "32",
  viewBox = "0 0 32 32"
}) => (
  <svg className={className} width={width} height={height} viewBox={viewBox}>
    <path d="M26 15L24.59 13.59 17 21.17 17 2 15 2 15 21.17 7.41 13.59 6 15 16 25 26 15z">
    </path>
    <path d="M26,24v4H6V24H4v4H4a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2h0V24Z">
    </path>
  </svg>
);

const FilterIcon = ({
  className = "filter-icon",
  height = "32",
  width = "32",
  viewBox = "0 0 32 32"
}) => (
  <svg className={className} width={width} height={height} viewBox={viewBox}>
    <path d="M18,28H14a2,2,0,0,1-2-2V18.41L4.59,11A2,2,0,0,1,4,9.59V6A2,2,0,0,1,6,4H26a2,2,0,0,1,2,2V9.59A2,2,0,0,1,27.41,11L20,18.41V26A2,2,0,0,1,18,28ZM6,6V9.59l8,8V26h4V17.59l8-8V6Z">
    </path>
  </svg>
);

const ClearFilterIcon = ({
  className = "clear-filter-icon",
  height = "32",
  width = "32",
  viewBox = "0 0 32 32"
}) => (
  <svg className={className} width={width} height={height} viewBox={viewBox}>
    <path d="M22.5,9A7.4522,7.4522,0,0,0,16,12.792V8H14v8h8V14H17.6167A5.4941,5.4941,0,1,1,22.5,22H22v2h.5a7.5,7.5,0,0,0,0-15Z">
    </path>
    <path d="M26,6H4V9.171l7.4142,7.4143L12,17.171V26h4V24h2v2a2,2,0,0,1-2,2H12a2,2,0,0,1-2-2V18L2.5858,10.5853A2,2,0,0,1,2,9.171V6A2,2,0,0,1,4,4H26Z">
    </path>
  </svg>
);

export { CalendarIcon, DownloadIcon, FilterIcon, ClearFilterIcon }