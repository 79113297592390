export const HEALTH_STATUS_QUERY_IDS = [
  "CURRENT_STATUS_QUERY_SYSTEM",
  "CURRENT_STATUS_QUERY_JMX",
  "CURRENT_STATUS_QUERY_HTTP",
  "CURRENT_STATUS_QUERY_DB_CONNECTIONS",
  "CURRENT_STATUS_QUERY_CONCURRENT_USER",
  "CURRENT_STATUS_QUERY_TRANSACTIONS",
  "CURRENT_STATUS_QUERY_JVM",
  "CURRENT_STATUS_QUERY_ALERT",
  "CURRENT_STATUS_QUERY_SR"
];

export const WARNING_DETAILS_QUERY_IDS = [
  "LOW_FREE_DISK",
  "HIGH_CPU_USAGE",
  "LOW_AVAILABLE_MEMORY",
  "JVM_HIGH_RESPONSE_TIME",
  "JVM_LOW_FREE_HEAP",
  "JVM_HUNG_THREAD"
];