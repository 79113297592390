import React from 'react';
import { connect } from 'react-redux';

import { DataTable, Search } from 'carbon-components-react';
import { NOTIFICATION_DATA_TABLE_FILTER_ALERTS_BY_TEXT, NOTIFICATION_DATA_TABLE_FILTER_NOTIFICATIONS_BY_TEXT, toggleAlertFilterAreaOpened, toggleFilterAreaOpened, updatePageData } from '../../../reducers/CommunicationsReducer';
import { searchOnRows } from '../../../reducers/SearchReducer';
import CommunicationsToolbarButtonsRow from './CommunicationsToolbarButtonsRow';
import CommunicationsToolbarFilterArea from './CommunicationsToolbarFilterArea';

const {
  TableToolbar
} = DataTable;

const CommunicationsToolbar = (props) => {
  const shouldHideButtons = () => {
    return props.envSchema.visibility && !props.envSchema.visibility.shouldHideCommunicationsSectionButtons;
  }

  const isFilterAreaOpened = () => {
    return props.isNotificationData ? props.filterAreaOpened : props.alertFilterAreaOpened;
  }

  const onChangeSearch = (text) => {
    const collectionId = "id";
    const reduxTargetAction = props.isNotificationData ? NOTIFICATION_DATA_TABLE_FILTER_NOTIFICATIONS_BY_TEXT : NOTIFICATION_DATA_TABLE_FILTER_ALERTS_BY_TEXT;
    const rows = props.filteredCommunications;
    const searchParams = { collectionId, reduxTargetAction, rows };

    props.updatePageData(1, props.pageSize);

    props.updateCommunicationSearchValue(text);
    props.searchOnRows({ ...searchParams, text });
  }

  return (
    <TableToolbar className='communicationsTableToolbar'>
      {shouldHideButtons() && <CommunicationsToolbarButtonsRow
        filterAreaOpened={isFilterAreaOpened()}
        toggleFilterAreaOpened={props.isNotificationData ? props.toggleFilterAreaOpened : props.toggleAlertFilterAreaOpened}
        onChangeSearch={onChangeSearch} />}
      &nbsp;
      <div className="bx--row" >
        <div className="bx--col-xs-12 bx--col-md-12 bx--col-lg-12">
          <Search
            id="search-communications"
            size='lg'
            labelText='Search'
            placeholder="Find your items"
            value={props.searchValue}
            onChange={(event) => onChangeSearch(event.target.value)} />
        </div>
      </div>
      &nbsp;
      {isFilterAreaOpened() && <CommunicationsToolbarFilterArea
        isNotificationData={props.isNotificationData}
        initialDateFilter={props.initialDateFilter}
        initialTimeFilter={props.initialTimeFilter}
        finalDateFilter={props.finalDateFilter}
        finalTimeFilter={props.finalTimeFilter}
        updateInitialDateFilter={props.updateInitialDateFilter}
        updateInitialTimeFilter={props.updateInitialTimeFilter}
        updateFinalDateFilter={props.updateFinalDateFilter}
        updateFinalTimeFilter={props.updateFinalTimeFilter}
        statusFilters={props.statusFilters}
        updateStatusFiltersControl={props.updateStatusFiltersControl} />}
    </TableToolbar >
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    filterAreaOpened: state.communications.filterAreaOpened,
    alertFilterAreaOpened: state.communications.alertFilterAreaOpened
  }), {
  updatePageData,
  toggleFilterAreaOpened,
  toggleAlertFilterAreaOpened,
  searchOnRows
}
)(CommunicationsToolbar);