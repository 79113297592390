import React from "react";

export const renderExpandedInfo = (cellTitle, cellContent, extraStyle) => {
  return (
    <React.Fragment>
      {renderCellTitle(cellTitle)}
      <br />
      {renderCellContent(cellContent, extraStyle)}
      <br />
    </React.Fragment>
  );
}

const renderCellTitle = (text) => {
  return (
    <div className="bx--row">
      <div className="expandedCategoryTitle">
        {text}
      </div>
    </div>
  );
}

const renderCellContent = (content, extraStyle) => {
  return (
    <div className="bx--row">
      <div className={"bx--col-xs-12 bx--col-md-12 bx--col-lg-12 " + extraStyle}>
        {content}
      </div>
    </div>
  );
}
