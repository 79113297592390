import React, { useState } from 'react';
import { connect } from 'react-redux';
import { cleanHTML, customSort } from '../Helper/ComponentHelper';
import { DataTable, PaginationV2 } from 'carbon-components-react';

import './Incident.css';
import { renderExpandedInfo } from '../Helper/DataTableHelper';
import parse from 'html-react-parser';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow
} = DataTable;

const headers = [{
  key: 'incidentTitle',
  header: 'Incident Title',
}, {
  key: 'incidentId',
  header: 'Incident Id',
}, {
  key: 'id',
  header: 'Incident Id',
}, {
  key: 'realDate',
  header: 'Last Update:',
}, {
  key: 'lastUpdate',
  header: 'Last Update',
}, {
  key: 'incidentDescription',
  header: 'Description',
}, {
  key: 'type',
  header: 'Type',
}, {
  key: 'databaseName',
  header: 'Database',
}, {
  key: 'memberNumber',
  header: 'Member Number'
}, {
  key: 'invocationType',
  header: 'Invocation Type'
}, {
  key: 'priority',
  header: 'Priority'
}, {
  key: 'totalWorkBytes',
  header: 'Total Work (Bytes)'
}, {
  key: 'completedWorkBytes',
  header: 'Completed Work (Bytes)'
}, {
  key: 'percentageCompletion',
  header: 'Solving %',
}];

const ALLOWED_HEADERS = ['incidentId', 'incidentTitle', 'realDate', 'percentageCompletion'];

const IncidentDataTable = (props) => {
  let [pageNumber, setPageNumber] = useState(1);
  let [pageSize, setPageSize] = useState(10);

  const renderHeader = (getHeaderProps, header) => {
    let c;

    let shouldRenderHeader = ALLOWED_HEADERS.some(element => header.key.includes(element));

    if (shouldRenderHeader) {
      c = <TableHeader
        {...getHeaderProps({ header })}
        className={header.key.includes('problemName') ? "incidentNameHeaderCell" : "incidentHeaderCell"
        }
        isSortable={true} >
        {header.header}
      </TableHeader >;
    }

    return c;
  }

  const getRows = () => {
    let result = [];

    if (props.incidentList.length > 0) {
      result = props.incidentList.sort(compareIncidents);
    }

    return result;
  }

  const compareIncidents = (a, b) => {
    let dateA = new Date(a.realDate);
    let dateB = new Date(b.realDate);

    if (dateA > dateB) {
      return -1;
    } else if (dateA < dateB) {
      return 1;
    } else {
      return 0;
    }
  }

  const renderCell = (row, cell) => {
    let c;

    let shouldRenderCell = ALLOWED_HEADERS.some(element => cell.id.includes(element));

    if (shouldRenderCell) {
      let value = cell.value;

      c = <TableCell key={cell.id}>{value}</TableCell>;
    }

    return c;
  }

  const renderCellExpanded = (row, cell) => {
    let c;

    let typeCell = row.cells.find(c => c.id === row.id + ':type');
    let databaseCell = row.cells.find(c => c.id === row.id + ':databaseName');
    let memberCell = row.cells.find(c => c.id === row.id + ':memberNumber');
    let invocationCell = row.cells.find(c => c.id === row.id + ':invocationType');
    let priorityCell = row.cells.find(c => c.id === row.id + ':priority');
    let completedWorkCell = row.cells.find(c => c.id === row.id + ':completedWorkBytes');
    let totalWorkCell = row.cells.find(c => c.id === row.id + ':totalWorkBytes');

    if (cell.id.includes('incidentDescription')) {
      c =
        <div className="expandedArea" key={cell.id}>
          <br />
          {renderExpandedInfo('Description', parse(cleanHTML(cell.value)))}
          {renderExpandedInfo('Type', typeCell.value)}
          {renderExpandedInfo('Database', databaseCell.value)}
          {renderExpandedInfo('Member Number', memberCell.value)}
          {renderExpandedInfo('Invocation Type', invocationCell.value)}
          {renderExpandedInfo('Priority', priorityCell.value)}
          {renderExpandedInfo('Completed Work', completedWorkCell.value + " Bytes")}
          {renderExpandedInfo('Total Work', totalWorkCell.value + " Bytes")}
        </div>
    }

    return c;
  }

  return (
    <React.Fragment>
      <DataTable
        headers={headers}
        rows={getRows()}
        sortRow={customSort}
        render={({ rows, headers, getHeaderProps, getRowProps }) => {
          rows = rows.slice((pageNumber - 1) * pageSize, (pageNumber) * pageSize);

          return (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableExpandHeader />
                    {headers.map(header => (renderHeader(getHeaderProps, header)))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <React.Fragment key={row.id}>
                      <TableExpandRow {...getRowProps({ row })} className="incidentExpandRow">
                        {row.cells.map(cell => (
                          renderCell(row, cell)
                        ))}
                      </TableExpandRow>
                      {row.isExpanded && (
                        <TableExpandedRow>
                          <TableCell className="expandedRow" colSpan={headers.length + 1}>
                            {row.cells.map(cell => (renderCellExpanded(row, cell)))}
                          </TableCell>
                        </TableExpandedRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}>
      </DataTable>
      <PaginationV2
        id='paginationV2'
        className='pagination'
        onChange={({ page, pageSize }) => {
          setPageNumber(page);
          setPageSize(pageSize);
        }}
        pageSize={pageSize}
        pageSizes={[10, 20, 30, 40, 50]}
        page={pageNumber}
        pageInputDisabled={false}
        totalItems={props.incidentList.length}
      />
    </React.Fragment >
  );
}

export default connect(
  (state) => ({
    incidentList: state.incident.incidentList
  }), {
}
)(IncidentDataTable);