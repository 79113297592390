import { BANNER_CONTENT_SWITCHER_VALUE_ITEMS, SUBSCRIPTION_OPTIONS } from '../components/Dashboard/EmailSubscription/SubscriptionHelper';
import { submitSubscription, getRegionList, getSubscriptionByUser, requestEmailSubscriptionVerification, fetchSubscriptionCategories } from '../lib/SubscriptionServices';

import {
  SUBMISSION_STATUS_INITIAL_STATUS,
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_SUCCESS,
  SUBMISSION_STATUS_SUBMITTED
} from './BaseReducer';

const initState = {
  subscriptionStatus: SUBMISSION_STATUS_INITIAL_STATUS,
  pushSubscriptionStatus: SUBMISSION_STATUS_INITIAL_STATUS,
  checkSubscriptionStatus: SUBMISSION_STATUS_INITIAL_STATUS,
  fetchSubscriptionCategoriesStatus: SUBMISSION_STATUS_INITIAL_STATUS,
  responseText: '',
  currentSubscriptions: [],
  subscriptionCategories: [],
  selectedSubscriptionOption: SUBSCRIPTION_OPTIONS[0],
  selectedEmail: '',
  allowEmptyEmail: true,
  selectedCategories: BANNER_CONTENT_SWITCHER_VALUE_ITEMS,
  selectedRegion: '',
  selectedSubscriptionCategories: [],
  isSubscriptionModalOpen: false,
  availableRegionsForSubscriptions: [],
  userSubscriptionFlag: null,
  regionAvailabilityStatus: false
}

const SUBSCRIPTION_UPDATE_SUBSCRIPTION_STATUS = 'SUBSCRIPTION_UPDATE_SUBSCRIPTION_STATUS';
export const updateSubscriptionStatusAction = (newStatus) => ({ type: SUBSCRIPTION_UPDATE_SUBSCRIPTION_STATUS, payload: newStatus });

const SUBSCRIPTION_UPDATE_PUSH_SUBSCRIPTION_STATUS = 'SUBSCRIPTION_UPDATE_PUSH_SUBSCRIPTION_STATUS';
export const updatePushSubscriptionStatusAction = (newStatus) => ({ type: SUBSCRIPTION_UPDATE_PUSH_SUBSCRIPTION_STATUS, payload: newStatus });

const SUBSCRIPTION_UPDATE_CHECK_SUBSCRIPTION_STATUS = 'SUBSCRIPTION_UPDATE_CHECK_SUBSCRIPTION_STATUS';
export const updateCheckSubscriptionStatusAction = (newStatus) => ({ type: SUBSCRIPTION_UPDATE_CHECK_SUBSCRIPTION_STATUS, payload: newStatus });

const SUBSCRIPTION_UPDATE_FETCH_SUBSCRIPTION_CATEGORIES_STATUS = 'SUBSCRIPTION_UPDATE_FETCH_SUBSCRIPTION_CATEGORIES_STATUS';
export const updateFetchSubscriptionCategoriesStatusAction = (newStatus) => ({ type: SUBSCRIPTION_UPDATE_FETCH_SUBSCRIPTION_CATEGORIES_STATUS, payload: newStatus });

const SUBSCRIPTION_UPDATE_RESPONSE_TEXT = 'SUBSCRIPTION_UPDATE_RESPONSE_TEXT';
export const updateResponseTextAction = (newText) => ({ type: SUBSCRIPTION_UPDATE_RESPONSE_TEXT, payload: newText });

const SUBSCRIPTION_UPDATE_CURRENT_SUBSCRIPTIONS = 'SUBSCRIPTION_UPDATE_CURRENT_SUBSCRIPTIONS';
export const updateCurrentSubscriptionsAction = (fetchedSubscriptions) => ({ type: SUBSCRIPTION_UPDATE_CURRENT_SUBSCRIPTIONS, payload: fetchedSubscriptions });

const SUBSCRIPTION_UPDATE_SELECTED_SUBSCRIPTION_OPTION = 'SUBSCRIPTION_UPDATE_SELECTED_SUBSCRIPTION_OPTION';
export const updateSelectedSubscriptionOptionAction = (newValue) => ({ type: SUBSCRIPTION_UPDATE_SELECTED_SUBSCRIPTION_OPTION, payload: newValue });

const SUBSCRIPTION_UPDATE_SELECTED_EMAIL = 'SUBSCRIPTION_UPDATE_SELECTED_EMAIL';
export const updateSelectedEmailAction = (newValue) => ({ type: SUBSCRIPTION_UPDATE_SELECTED_EMAIL, payload: newValue });

const SUBSCRIPTION_UPDATE_ALLOW_EMPTY_EMAIL = 'SUBSCRIPTION_UPDATE_ALLOW_EMPTY_EMAIL';
export const updateAllowEmptyEmailAction = (newValue) => ({ type: SUBSCRIPTION_UPDATE_ALLOW_EMPTY_EMAIL, payload: newValue });

const SUBSCRIPTION_UPDATE_SELECTED_CATEGORIES = 'SUBSCRIPTION_UPDATE_SELECTED_CATEGORIES';
export const updateSelectedCategoriesAction = (newValue) => ({ type: SUBSCRIPTION_UPDATE_SELECTED_CATEGORIES, payload: newValue });

const SUBSCRIPTION_UPDATE_SELECTED_REGION = 'SUBSCRIPTION_UPDATE_SELECTED_REGION';
export const updateSelectedRegionAction = (newValue) => ({ type: SUBSCRIPTION_UPDATE_SELECTED_REGION, payload: newValue });

const SUBSCRIPTION_UPDATE_SELECTED_SUBSCRIPTION_CATEGORIES = 'SUBSCRIPTION_UPDATE_SELECTED_SUBSCRIPTION_CATEGORIES';
export const updateSelectedSubscriptionCategoriesAction = (newValue) => ({ type: SUBSCRIPTION_UPDATE_SELECTED_SUBSCRIPTION_CATEGORIES, payload: newValue });

const SUBSCRIPTION_UPDATE_SUBSCRIPTION_CATEGORIES = 'SUBSCRIPTION_UPDATE_SUBSCRIPTION_CATEGORIES';
export const updateSubscriptionCategoriesAction = (newValue) => ({ type: SUBSCRIPTION_UPDATE_SUBSCRIPTION_CATEGORIES, payload: newValue });

const SUBSCRIPTION_UPDATE_IS_OPEN_SUBSCRIPTION_MODAL_FLAG = 'SUBSCRIPTION_UPDATE_IS_OPEN_SUBSCRIPTION_MODAL_FLAG';
export const updateIsSubscriptionModalOpenFlagAction = (newValue) => ({ type: SUBSCRIPTION_UPDATE_IS_OPEN_SUBSCRIPTION_MODAL_FLAG, payload: newValue });

const SUBSCRIPTION_RESET_STATUS = 'SUBSCRIPTION_RESET_STATUS';
export const resetStatusAction = () => ({ type: SUBSCRIPTION_RESET_STATUS, payload: null });

const SUBSCRIPTION_SET_NEW_AVAILABLE_REGIONS = 'SUBSCRIPTION_SET_NEW_AVAILABLE_REGIONS';
export const setNewNotificationAvailableRegionsAction = (availableRegions) => ({
  type: SUBSCRIPTION_SET_NEW_AVAILABLE_REGIONS,
  payload: availableRegions
});

const SUBSCRIPTION_UPDATE_USER_SUBSCRIPTION_FLAG = 'SUBSCRIPTION_UPDATE_USER_SUBSCRIPTION_FLAG';
export const updateUserSubscriptionFlagAction = (newValue) => ({ type: SUBSCRIPTION_UPDATE_USER_SUBSCRIPTION_FLAG, payload: newValue });

const SUBSCRIPTION_SET_REGION_AVAILABILITY_STATUS = 'SUBSCRIPTION_SET_REGION_AVAILABILITY_STATUS'
export const setRegionAvailabilityStatusAction = (regionAvailabilityStatus) => ({
  type: SUBSCRIPTION_SET_REGION_AVAILABILITY_STATUS,
  payload: regionAvailabilityStatus
});

export const resetStatus = () => {
  return (dispatch, getState) => {
    getUserSubscription(getState, dispatch);
    dispatch(resetStatusAction(SUBMISSION_STATUS_INITIAL_STATUS));
  }
}

export const verifyUserSubscription = (IUI, envId) => {
  return (dispatch, getState) => {
    updateCheckSubscriptionStatusAction(SUBMISSION_STATUS_SUBMITTED);

    requestEmailSubscriptionVerification(IUI, envId)
      .then(data => {
        dispatch(updateUserSubscriptionFlagAction(data));
        if (data) {
          getUserSubscription(getState, dispatch);
        } else {
          dispatch(updateResponseTextAction('No subscription(s) found!'));
          dispatch(updateCurrentSubscriptionsAction([]));
          dispatch(updateCheckSubscriptionStatusAction(SUBMISSION_STATUS_SUCCESS));
        }
      });
  }
}

export const handleSubscription = (email, subscriptionAction, categories, subscriptionCategories, selectedRegion = '') => {
  return (dispatch, getState) => {
    let data;
    let originatorIdText = '';

    if (getState().user.IUI && getState().user.IUI !== '') {
      originatorIdText = "?originatorid=" + getState().user.IUI;
    }

    data = {
      emailAddress: email.replace(/\s/g, ""),
      announcement: categories.includes('announcement'),
      incident: categories.includes('incident'),
      security: categories.includes('security'),
      maintenance: categories.includes('maintenance'),
      alert: categories.includes('alert'),
      subscribedCategoryAttributeIds: formatSubscriptionCategoriesToBackend(subscriptionCategories),
      userId: getState().user.userEmail !== '' ? getState().user.userEmail : email.replace(/\s/g, "")  // Use user authentication e-mail if available, otherwise use subscription e-mail
    };

    if (getState().user.environmentSchema.visibility && getState().user.environmentSchema.visibility.supportsRegionSubscription) {
      data = {
        ...data,
        instance: selectedRegion === '' ? 'all' : selectedRegion
      };
    }

    dispatch(updateSubscriptionStatusAction(SUBMISSION_STATUS_SUBMITTED));

    submitSubscription(getState().user.environmentSchema.id, getState().user.selectedServiceType.name, subscriptionAction, originatorIdText, data)
      .then(data => {
        if (data.length !== 0 && data[0] === 'Email ' + email.toLowerCase().replace(/\s/g, "") + ' is not an IBMID!') {
          dispatch(updateResponseTextAction(data[0] + ' Please try a different e-mail!'));

          dispatch(updateSubscriptionStatusAction(SUBMISSION_STATUS_FAILED));
        } else {
          let responseText = subscriptionAction === 'subscribe' ?
            'Successfully subscribed!\n Email [' + email.replace(/\s/g, "") + '] will receive announcement updates.' :
            'Successfully unsubscribed!\n Email [' + email.replace(/\s/g, "") + '] will not receive announcement updates.'

          if (getState().user.userEmail.toLowerCase() === email.replace(/\s/g, "").toLowerCase()) {
            //User has subscribed themselves
            dispatch(updateUserSubscriptionFlagAction(true));
          }

          dispatch(updateResponseTextAction(responseText));

          dispatch(updateSubscriptionStatusAction(SUBMISSION_STATUS_SUCCESS));
        }
      })
      .catch(error => {
        dispatch(updateSubscriptionStatusAction(SUBMISSION_STATUS_FAILED));
        dispatch(updateResponseTextAction('An error ocurred with your action. Please, try again.'));
      });
  }
}

const formatSubscriptionCategoriesToBackend = (categories) => {
  let backEndSubscriptionCategories = [];

  for (let index = 0; index < Object.keys(categories).length; index++) {
    let category = categories[Object.keys(categories)[index]];

    if (Array.isArray(category)) {
      for (let indexJ = 0; indexJ < category.length; indexJ++) {
        backEndSubscriptionCategories.push(category[indexJ].id);
      }
    }
  }

  return backEndSubscriptionCategories;
}

export const fetchNotificationAvailableRegions = (orgId) => {
  return (dispatch) => {
    getRegionList(orgId)
      .then(data => {
        let availableRegions = [];

        if (data && data.datacenters) {
          availableRegions = data.datacenters;

          dispatch(setRegionAvailabilityStatusAction(true));
        } else {
          dispatch(setRegionAvailabilityStatusAction(false));
        }

        dispatch(setNewNotificationAvailableRegionsAction(availableRegions))
      });
  }
}

export const requestSubscriptionCategories = (solutionId) => {
  return (dispatch) => {
    dispatch(updateFetchSubscriptionCategoriesStatusAction(SUBMISSION_STATUS_SUBMITTED));

    fetchSubscriptionCategories(solutionId)
      .then(data => {
        if (Array.isArray(data) && data[0].categoryId) {
          dispatch(updateSubscriptionCategoriesAction(data));
          dispatch(updateFetchSubscriptionCategoriesStatusAction(SUBMISSION_STATUS_SUCCESS));
        } else {
          dispatch(updateSubscriptionCategoriesAction([]));
          dispatch(updateFetchSubscriptionCategoriesStatusAction(SUBMISSION_STATUS_FAILED));
        }
      })
      .catch(error => {
        dispatch(updateSubscriptionCategoriesAction([]));
        dispatch(updateFetchSubscriptionCategoriesStatusAction(SUBMISSION_STATUS_FAILED));
      })
  }
}

export const fetchCurrentUserSubscription = () => {
  return (dispatch, getState) => {
    getUserSubscription(getState, dispatch);
  }
}

const getUserSubscription = (getState, dispatch) => {
  dispatch(updateCheckSubscriptionStatusAction(SUBMISSION_STATUS_SUBMITTED));

  getSubscriptionByUser(getState().user.environmentSchema.id, getState().user.userEmail)
    .then(data => {
      if (data && data.length > 0) {
        if (data[0] === 'No subscriptions found') {
          dispatch(updateResponseTextAction(data[0] + '!'));
          dispatch(updateCurrentSubscriptionsAction([]));
          dispatch(updateCheckSubscriptionStatusAction(SUBMISSION_STATUS_SUCCESS));
        } else {
          let subscriptions = [];
          data.forEach(subscription => {
            subscriptions.push(formatSubscription(subscription));
          });

          dispatch(updateCurrentSubscriptionsAction(subscriptions));

          dispatch(updateCheckSubscriptionStatusAction(SUBMISSION_STATUS_SUCCESS));
        }
      } else {
        dispatch(updateResponseTextAction("There was an error! Please try again!"));
        dispatch(updateCheckSubscriptionStatusAction(SUBMISSION_STATUS_FAILED));
        dispatch(updateCurrentSubscriptionsAction([]));
      }
    });
}

const formatSubscription = (subscription) => {
  return {
    annoucement: subscription.announcement,
    incident: subscription.incident,
    maintenance: subscription.maintenance,
    security: subscription.security,
    alert: subscription.alert,
    instance: subscription.instance,
    subscribedCategoryAttributes: formatSubscriptionCategoriesToFrontEnd(JSON.parse(subscription.subscribedCategoryAttributes))
  }
}

const formatSubscriptionCategoriesToFrontEnd = (categories) => {
  let frontEndSubscriptionCategories = {};

  for (let index = 0; index < categories.length; index++) {
    let category = categories[index];

    if (category) {
      if (!frontEndSubscriptionCategories[category.parentCategoryId]) {
        frontEndSubscriptionCategories[category.parentCategoryId] = [];
      }

      frontEndSubscriptionCategories[category.parentCategoryId].push({ text: category.attributeName, id: category.attributeId });
    }
  }

  return frontEndSubscriptionCategories;
}

export const updateIsSubscriptionModalOpenFlag = (value) => {
  return (dispatch) => {
    dispatch(updateIsSubscriptionModalOpenFlagAction(value));
  }
}

export const updateSelectedSubscriptionOption = (value) => {
  return (dispatch) => {
    dispatch(updateSelectedSubscriptionOptionAction(value));
  }
}

export const updateSelectedEmail = (value) => {
  return (dispatch) => {
    dispatch(updateSelectedEmailAction(value));
  }
}

export const updateAllowEmptyEmail = (value) => {
  return (dispatch) => {
    dispatch(updateAllowEmptyEmailAction(value));
  }
}

export const updateSelectedCategories = (value) => {
  return (dispatch) => {
    dispatch(updateSelectedCategoriesAction(value));
  }
}

export const updateSelectedRegion = (value) => {
  return (dispatch) => {
    dispatch(updateSelectedRegionAction(value));
  }
}

export const updateSelectedSubscriptionCategories = (value) => {
  return (dispatch) => {
    dispatch(updateSelectedSubscriptionCategoriesAction(value));
  }
}

export const resetEmailData = () => {
  return (dispatch) => {
    dispatch(updateSelectedEmailAction(''));
    dispatch(updateAllowEmptyEmailAction(false));
    dispatch(updateSelectedCategoriesAction(BANNER_CONTENT_SWITCHER_VALUE_ITEMS));
    dispatch(updateSelectedSubscriptionCategoriesAction([]));
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_UPDATE_SUBSCRIPTION_STATUS:
      return { ...state, subscriptionStatus: action.payload }

    case SUBSCRIPTION_UPDATE_PUSH_SUBSCRIPTION_STATUS:
      return { ...state, pushSubscriptionStatus: action.payload }

    case SUBSCRIPTION_UPDATE_CHECK_SUBSCRIPTION_STATUS:
      return { ...state, checkSubscriptionStatus: action.payload }

    case SUBSCRIPTION_UPDATE_FETCH_SUBSCRIPTION_CATEGORIES_STATUS:
      return { ...state, fetchSubscriptionCategories: action.payload }

    case SUBSCRIPTION_UPDATE_RESPONSE_TEXT:
      return { ...state, responseText: action.payload }

    case SUBSCRIPTION_UPDATE_CURRENT_SUBSCRIPTIONS:
      return { ...state, currentSubscriptions: action.payload }

    case SUBSCRIPTION_UPDATE_SUBSCRIPTION_CATEGORIES:
      return { ...state, subscriptionCategories: action.payload }

    case SUBSCRIPTION_UPDATE_SELECTED_SUBSCRIPTION_OPTION:
      return { ...state, selectedSubscriptionOption: action.payload }

    case SUBSCRIPTION_UPDATE_SELECTED_EMAIL:
      return { ...state, selectedEmail: action.payload }

    case SUBSCRIPTION_UPDATE_ALLOW_EMPTY_EMAIL:
      return { ...state, allowEmptyEmail: action.payload }

    case SUBSCRIPTION_UPDATE_SELECTED_CATEGORIES:
      return { ...state, selectedCategories: action.payload }

    case SUBSCRIPTION_UPDATE_SELECTED_REGION:
      return { ...state, selectedRegion: action.payload }

    case SUBSCRIPTION_UPDATE_IS_OPEN_SUBSCRIPTION_MODAL_FLAG:
      return { ...state, isSubscriptionModalOpen: action.payload }

    case SUBSCRIPTION_RESET_STATUS:
      return { ...state, subscriptionStatus: SUBMISSION_STATUS_INITIAL_STATUS, pushSubscriptionStatus: SUBMISSION_STATUS_INITIAL_STATUS }

    case SUBSCRIPTION_SET_NEW_AVAILABLE_REGIONS:
      return { ...state, availableRegionsForSubscriptions: action.payload }

    case SUBSCRIPTION_SET_REGION_AVAILABILITY_STATUS:
      return { ...state, regionAvailabilityStatus: action.payload }

    case SUBSCRIPTION_UPDATE_USER_SUBSCRIPTION_FLAG:
      return { ...state, userSubscriptionFlag: action.payload }

    default:
      return state;
  }
}
