import { getUserGroup, getOrgFromServer, getLoginUserId, changeCurrentOrg, getUserEmail } from '../lib/UserServices.js';
import {
  SUBMISSION_STATUS_INITIAL_STATUS,
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_SUCCESS,
  SUBMISSION_STATUS_SUBMITTED
} from '../reducers/BaseReducer';
import { createSchema } from './helper/EnvironmentSchemaHelper';

import * as moment from 'moment';

const initState = {
  userGroupSubOrgs: [],
  IUI: '',
  environmentSchema: {},
  loginUserId: '',
  userEmail: '',
  selectedServiceType: {},
  date: moment.utc(),
  mustUpdateHealthStatus: false,

  loadUserGroupStatus: SUBMISSION_STATUS_INITIAL_STATUS
}

const USER_LOAD_USER_GROUP = 'USER_LOAD_USER_GROUP';
export const loadUserGroupAction = (userGroup) => ({ type: USER_LOAD_USER_GROUP, payload: userGroup });

const USER_LOAD_USER_IUI = 'USER_LOAD_USER_IUI';
export const loadUserIUIAction = (newIUI) => ({ type: USER_LOAD_USER_IUI, payload: newIUI });

const USER_LOAD_ENVIRONMENT_SCHEMA = 'USER_LOAD_ENVIRONMENT_SCHEMA';
export const loadEnvironmentSchemaAction = (userGroup) => ({ type: USER_LOAD_ENVIRONMENT_SCHEMA, payload: userGroup });

const USER_LOAD_LOGIN_USER_ID = 'USER_LOAD_LOGIN_USER_ID';
export const loadLoginUserIdAction = (userGroup) => ({ type: USER_LOAD_LOGIN_USER_ID, payload: userGroup });

const USER_LOAD_LOGIN_USER_EMAIL = 'USER_LOAD_LOGIN_USER_EMAIL';
export const loadLoginUserEmailAction = (userEmail) => ({ type: USER_LOAD_LOGIN_USER_EMAIL, payload: userEmail })

const USER_UPDATE_SELECTED_SERVICE_TYPE = 'USER_UPDATE_SELECTED_SERVICE_TYPE';
export const updateSelectedServiceTypeAction = (userGroup) => ({ type: USER_UPDATE_SELECTED_SERVICE_TYPE, payload: userGroup });

const USER_UPDATE_DATE = 'USER_UPDATE_DATE';
export const updateDateAction = (newDate) => ({ type: USER_UPDATE_DATE, payload: newDate });

const USER_UPDATE_MUST_UPDATE_HEALTH_STATUS_FLAG = 'USER_UPDATE_MUST_UPDATE_HEALTH_STATUS_FLAG';
export const updateMustUpdateHealthStatusFlagAction = (newFlag) => ({ type: USER_UPDATE_MUST_UPDATE_HEALTH_STATUS_FLAG, payload: newFlag });

const USER_LOAD_USER_GROUP_STATUS = 'USER_LOAD_USER_GROUP_STATUS';
export const updateSubmissionStatusAction = (newLogoutStatus) => ({ type: USER_LOAD_USER_GROUP_STATUS, payload: newLogoutStatus });

export const loadUserGroup = () => {
  return (dispatch) => {
    tryToRequestUserGroup(dispatch, 0);
  }
}

const tryToRequestUserGroup = (dispatch, numberOfLoadGroupRequests) => {
  dispatch(updateSubmissionStatusAction(USER_LOAD_USER_GROUP_STATUS, SUBMISSION_STATUS_SUBMITTED));

  getUserGroup()
    .then(data => {
      if (Array.isArray(data) && data.length > 0) {
        dispatch(updateSubmissionStatusAction(USER_LOAD_USER_GROUP_STATUS, SUBMISSION_STATUS_SUCCESS));

        dispatch(loadUserGroupAction(data));
      } else {
        setTimeout(function () {
          if (numberOfLoadGroupRequests < 10) {
            numberOfLoadGroupRequests++;
            tryToRequestUserGroup(dispatch, numberOfLoadGroupRequests);
          }
        }, 1000);
      }
    })
    .catch(error => {
      dispatch(updateSubmissionStatusAction(USER_LOAD_USER_GROUP_STATUS, SUBMISSION_STATUS_FAILED));
      dispatch(loadUserGroupAction(null));
    });
}

export const loadOrg = () => {
  return (dispatch) => {
    dispatch(updateSubmissionStatusAction(USER_LOAD_USER_GROUP_STATUS, SUBMISSION_STATUS_SUBMITTED));

    getOrgFromServer()
      .then(data => {
        let schema = createSchema(data);

        dispatch(loadEnvironmentSchemaAction(schema));

        if (schema.serviceTypes.length >= 0) {
          dispatch(updateSelectedServiceTypeAction(schema.serviceTypes[0]));
        } else {
          dispatch(updateSelectedServiceTypeAction({}));
        }

        dispatch(updateMustUpdateHealthStatusFlagAction(true));
        dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_SUCCESS));
      })
      .catch(error => {
        dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_FAILED));
        dispatch(loadEnvironmentSchemaAction({}));
        dispatch(updateSelectedServiceTypeAction({}));
      });
  }
}

export const loadLoginUserId = () => {
  return (dispatch) => {
    dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_SUBMITTED));

    getLoginUserId()
      .then(data => {
        if (data.loginUser && data.IUI) {
          dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_SUCCESS));
          dispatch(loadLoginUserIdAction(data.loginUser));
          dispatch(loadUserIUIAction(data.IUI));
        } else {
          dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_FAILED));
          dispatch(loadLoginUserIdAction(''));
          dispatch(loadUserIUIAction(''));
        }
      })
      .catch(error => {
        dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_FAILED));
        dispatch(loadLoginUserIdAction(''));
      });
  }
}

export const loadUserEmail = () => {
  return (dispatch) => {
    dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_SUBMITTED));

    getUserEmail()
      .then(data => {
        if (data.userEmail) {
          dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_SUCCESS));
          dispatch(loadLoginUserEmailAction(data.userEmail));
        } else {
          dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_FAILED));
          dispatch(loadLoginUserEmailAction(''));
        }
      })
      .catch(error => {
        dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_FAILED));
        dispatch(loadLoginUserEmailAction(''));
      });
  }
}

export const requestToChangeOrg = (newOrgId) => {
  return (dispatch) => {
    dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_SUBMITTED));

    changeCurrentOrg(newOrgId)
      .then(data => {
        let schema = createSchema(data);

        dispatch(loadEnvironmentSchemaAction(schema));

        if (schema.serviceTypes.length >= 0) {
          dispatch(updateSelectedServiceTypeAction(schema.serviceTypes[0]));
        } else {
          dispatch(updateSelectedServiceTypeAction({}));
        }

        dispatch(updateMustUpdateHealthStatusFlagAction(true));
        dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_SUCCESS));
      })
      .catch(error => {
        dispatch(updateSubmissionStatusAction(SUBMISSION_STATUS_FAILED));
      });
  }
}

export const updateSelectedServiceType = (index) => {
  return (dispatch, getState) => {
    let environmentSchema = getState().user.environmentSchema;

    let newSelectedServiceType = environmentSchema.serviceTypes[index];

    dispatch(updateMustUpdateHealthStatusFlagAction(true));
    dispatch(updateSelectedServiceTypeAction(newSelectedServiceType));
  }
}

export const updateDate = () => {
  return (dispatch) => {
    dispatch(updateDateAction(moment.utc()));
  }
}

export const updateMustUpdateFlag = (value) => {
  return (dispatch) => {
    dispatch(updateMustUpdateHealthStatusFlagAction(value));
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case USER_LOAD_USER_GROUP:
      return { ...state, userGroupSubOrgs: action.payload }

    case USER_LOAD_USER_IUI:
      return { ...state, IUI: action.payload }

    case USER_LOAD_ENVIRONMENT_SCHEMA:
      return { ...state, environmentSchema: action.payload }

    case USER_LOAD_LOGIN_USER_ID:
      return { ...state, loginUserId: action.payload }

    case USER_LOAD_LOGIN_USER_EMAIL:
      return { ...state, userEmail: action.payload }

    case USER_UPDATE_SELECTED_SERVICE_TYPE:
      return { ...state, selectedServiceType: action.payload }

    case USER_UPDATE_DATE:
      return { ...state, date: action.payload }

    case USER_LOAD_USER_GROUP_STATUS:
      return { ...state, loadUserGroupStatus: action.payload }

    case USER_UPDATE_MUST_UPDATE_HEALTH_STATUS_FLAG:
      return { ...state, mustUpdateHealthStatus: action.payload }

    default:
      return state;
  }
}
