import React from 'react';
import { connect } from 'react-redux';

import { Tooltip } from 'carbon-components-react';

export const HealthStatusMonitorTooltip = (props) => {
  const createMonitorTooltipText = (monitorMap) => {
    let text = [];

    for (let index = 0; index < monitorMap.length; index++) {
      text.push(
        <div key={index}>
          <p className="tooltipTextDescription">
            {monitorMap[index].name}:
          </p>
          <p className="tooltipTextURL">
            {monitorMap[index].description}
          </p>
        </div>
      );
      if (index !== monitorMap.length - 1) {
        text.push(<div key={index + '_blankspace'}>
          <br />
        </div>);
      }
    }

    return text;
  };

  return (
    <div className="monitorGrouping" key={props.label}>
      <Tooltip
        direction="bottom"
        tabIndex={0}
        triggerText={<div className="tooltipText">
          {props.label}
        </div>}>
        <div>
          {createMonitorTooltipText(props.monitorMap)}
        </div>
      </Tooltip>
    </div>
  );
}

export default connect(
  (state) => ({
  }), {
}
)(HealthStatusMonitorTooltip);