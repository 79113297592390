export const MILLISECONDS_IN_ONE_HOUR = 3600000;

export const MILLISECONDS_IN_ONE_DAY = MILLISECONDS_IN_ONE_HOUR * 24;

export const DEFAULT_TIME_CONTROL_OPTION = 2;

export const TIME_CONTROL_OPTIONS = [{
  text: 'Last 15 Minutes',
  value: 1 / 4
}, {
  text: 'Last 30 Minutes',
  value: 1 / 2
}, {
  text: 'Last Hour',
  value: 1
}, {
  text: 'Last 6 Hours',
  value: 6
}, {
  text: 'Last 12 Hours',
  value: 12
}, {
  text: 'Last 24 Hours',
  value: 24
}, {
  text: 'Last 7 Days',
  value: 168
}, {
  text: 'Last 30 Days',
  value: 720
}, {
  text: 'Today',
  value: 'today'
}, {
  text: 'Yesterday',
  value: 'yesterday'
}, {
  text: 'This Week',
  value: 'this-week'
}, {
  text: 'This Month',
  value: 'this-month'
}, {
  text: 'Last 60 Days',
  value: 1440
}, {
  text: 'Last 3 Months',
  value: 'last-3-months'
}, {
  text: 'Last 90 Days',
  value: 2160
}];

export const calculateInitialAndFinalDates = (selectedTimeControlOption, selectedTimeOptionTab,
  initialFilterDate, initialFilterTime, finalFilterDate, finalFilterTime) => {
  let initialTimeInMilliseconds, finalTimeInMilliseconds, isoTimeStart, isoTimeEnd;
  let timeValue = TIME_CONTROL_OPTIONS[selectedTimeControlOption].value;

  if (selectedTimeOptionTab === 1 && initialFilterDate !== '' && initialFilterTime !== '' && finalFilterDate !== '' && finalFilterTime !== '') {
    let initialDate = new Date(initialFilterDate);
    let finalDate = new Date(finalFilterDate);

    initialDate.setHours(initialFilterTime.split(':')[0]);
    initialDate.setMinutes(initialFilterTime.split(':')[1]);
    finalDate.setHours(finalFilterTime.split(':')[0]);
    finalDate.setMinutes(finalFilterTime.split(':')[1]);

    isoTimeStart = initialDate.toISOString();
    isoTimeEnd = finalDate.toISOString();

    initialTimeInMilliseconds = initialDate.getTime();
    finalTimeInMilliseconds = finalDate.getTime();
  } else {
    finalTimeInMilliseconds = new Date().getTime();

    if (parseFloat(timeValue)) {
      initialTimeInMilliseconds = finalTimeInMilliseconds - (timeValue * MILLISECONDS_IN_ONE_HOUR);
    } else {
      initialTimeInMilliseconds =  getSpecialDate(timeValue).getTime();

      if (timeValue === 'yesterday') {
        finalTimeInMilliseconds = initialTimeInMilliseconds + (24 * MILLISECONDS_IN_ONE_HOUR) - 1;
      }
    }

    isoTimeStart = new Date(initialTimeInMilliseconds).toISOString();
    isoTimeEnd = new Date(finalTimeInMilliseconds).toISOString();
  }

  return { initialTimeInMilliseconds, finalTimeInMilliseconds, isoTimeStart, isoTimeEnd };
}

export const getSpecialDate = (timeValue) => {
  let initialDate = new Date();

  if (timeValue === 'yesterday') {
    initialDate.setDate(initialDate.getDate() - 1);
  } else if (timeValue === 'this-week') {
    let day = initialDate.getDay();
    let diff = initialDate.getDate() - day + (day === 0 ? -6 : 1);

    initialDate.setDate(diff);
  } else if (timeValue === 'this-month') {
    initialDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1);
  } else if (timeValue === 'last-3-months') {
    initialDate = new Date(initialDate.getFullYear(), initialDate.getMonth() - 2);
  }

  initialDate.setHours(0, 0, 0, 0);

  return initialDate;
}