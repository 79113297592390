import { getDashboardsInfoByUserId, getSolutionsByGroup, getInstancesBySolution, getTenantsByInstance } from "../lib/DashboardsInfoServices";

const initState = {
    dashboardsInfo: [],
    dashboardGroups: [],
    commonCoreSolutions:[],
    commonCoreInstances:[],
    commonCoreTenants:[],
    filteredDashboardsInfoByGroup : [],
    filteredDashboardsInfoList : null,    
    subscriptionFetchStatus: 'DASHBOARDS_INFO_FETCH_INITIAL_STATUS'
}

const SOLUTIONS_LOAD_LIST = 'SOLUTIONS_LOAD_LIST' ;
const INSTANCES_LOAD_LIST = 'INSTANCES_LOAD_LIST' ;
const TENANTS_LOAD_LIST = 'TENANTS_LOAD_LIST' ;
export const loadSolutionsAction = (solutions) =>  ({type:SOLUTIONS_LOAD_LIST, payload: solutions});
export const loadInstancesAction = (instances) =>  ({type:INSTANCES_LOAD_LIST, payload: instances});
export const loadTenantsAction = (tenants) =>  ({type:TENANTS_LOAD_LIST, payload: tenants});

export const DASHBOARDS_INFO_GROUP_OPTIONS_ALL = {name:'ALL', id: 'all'}; 
export const DASHBOARDS_INFO_GROUP_OPTIONS_NO_GROUP = {name:'NO GROUP', id: 'nogroup'}; 
const DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_INFO_FILTERED_BY_GROUP = 'DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_INFO_FILTERED_BY_GROUP';
export const loadDashboardsInfoFilteredByGroupAction = (filteredDashboardsInfo) => ({
    type: DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_INFO_FILTERED_BY_GROUP,
    payload: filteredDashboardsInfo
});

const DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_INFO = 'DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_INFO' ;
export const loadDashboardsInfoAction = (newDashboardsInfo) => ({
    type: DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_INFO,
    payload: newDashboardsInfo
});

const DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_GROUPS = 'DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_GROUPS' ;
export const loadDashboardsGroupsAction = (dashboardGroups) => ({
    type: DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_GROUPS,
    payload: dashboardGroups
});

const DASHBOARDS_INFO_REDUCER_UPDATE_FETCH_STATUS = 'DASHBOARDS_INFO_REDUCER_UPDATE_FETCH_STATUS' ;
export const updateFetchStatusAction = (newStatus) => ({
    type: DASHBOARDS_INFO_REDUCER_UPDATE_FETCH_STATUS,
    payload: newStatus
})


export const DASHBOARDS_INFO_REDUCER_FETCH_STATUS_UPDATING = 'DASHBOARDS_INFO_REDUCER_FETCH_STATUS_UPDATING';
export const DASHBOARDS_INFO_REDUCER_FETCH_STATUS_SUCCESS = 'DASHBOARDS_INFO_REDUCER_FETCH_STATUS_SUCCESS';
export const DASHBOARDS_INFO_REDUCER_FETCH_STATUS_FAILED = 'DASHBOARDS_INFO_REDUCER_FETCH_STATUS_FAILED';

export const DASHBOARDS_INFO_REDUCER_FILTER_LIST = 'DASHBOARDS_INFO_REDUCER_FILTER_LIST';

//binding action creators to with dispatch
export const fetchSolutionsType = (group) => {
    return (dispatch) => {
      getSolutionsByGroup(group)
      .then(solutions => {
        let items = [];
  
        if (Array.isArray(solutions)) {
          items.push({id: "all", text:"all"});
          
          solutions.forEach((solution, index) => {
            let item = {
              id: solution,
              text: solution
            }
            items.push(item);
          })
        }
        dispatch(loadSolutionsAction(items))
      }).catch(error => {
        console.log("fetchSolutionsType.error", error);
      });
    }
  }
  
  //binding action creators to with dispatch
export const fetchInstancesType = (solution) => {
    return (dispatch) => {
      getInstancesBySolution(solution)
      .then(instances => {
        var items = [];
        items.push({id: "all", text:"all"})
        instances.forEach((instance, index) => {
          let item = {
            id: instance,
            text: instance
          }
          items.push(item);
        })
        dispatch(loadInstancesAction(items))
      }).catch(error => {
        console.log("fetchInstancesType.error", error);
        var items = [];
        items.push({id: "all", text:"all"})
        dispatch(loadInstancesAction(items))
      });
    }
  }
  
  //binding action creators to with dispatch
export const fetchTenantsType = (solution, instance) => {
    return (dispatch) => {
      getTenantsByInstance(solution, instance)
      .then(tenants => {
        var items = [];
        if(tenants.hasOwnProperty("CommoncoreAnnouncementFilterTenants")){
          dispatch(loadTenantsAction(items))
        } else{
          items.push({id: "all", text:"all"})
          tenants.forEach((tenant, index) => {
            let item = {
              id: tenant,
              text: tenant
            }
            items.push(item);
          })
          dispatch(loadTenantsAction(items))
        }
      }).catch(error => {
        console.log("fetchTenantsType.error", error);
        var items = [];
        items.push({id: "all", text:"all"})
        dispatch(loadTenantsAction(items))
      });
    }
  }

export const fetchDashboardsInfo = (userId) => {
    return (dispatch) => {
        dispatch(updateFetchStatusAction(DASHBOARDS_INFO_REDUCER_FETCH_STATUS_UPDATING));
        getDashboardsInfoByUserId(userId, false)
        .then(dashboardsInfo => {
                dispatch(updateFetchStatusAction(DASHBOARDS_INFO_REDUCER_FETCH_STATUS_SUCCESS));
                dispatch(loadDashboardsInfoAction(dashboardsInfo));
                dispatch(loadDashboardsInfoFilteredByGroupAction(dashboardsInfo));
        })
        .catch(error => {
            dispatch(updateFetchStatusAction(DASHBOARDS_INFO_REDUCER_FETCH_STATUS_FAILED));
            dispatch(loadDashboardsInfoAction([]));
        });
    }
}

export const fetchDashboardsGroups = (userId) => {
    return (dispatch) => {
        dispatch(updateFetchStatusAction(DASHBOARDS_INFO_REDUCER_FETCH_STATUS_UPDATING));
        getDashboardsInfoByUserId(userId, true)
        .then(dashboardsInfo => {
                let dashboardsGroups = dashboardsInfo.filter(org => org.type === 'group'); 
                dashboardsGroups.push(DASHBOARDS_INFO_GROUP_OPTIONS_ALL);
                dashboardsGroups.push(DASHBOARDS_INFO_GROUP_OPTIONS_NO_GROUP);
                dispatch(loadDashboardsGroupsAction(dashboardsGroups));
        })
        .catch(error => {
            dispatch(loadDashboardsGroupsAction([]));
        });
    }
}


export const filterDashboardsInfoByGroup = (groupId) => {
    return (dispatch,getState ) => {
        const dashboardsInfo = getState().dashboardsInfoPage.dashboardsInfo; 

        let filteredDashboardsInfoByGroup = dashboardsInfo; 
        if(groupId === DASHBOARDS_INFO_GROUP_OPTIONS_ALL.id){
             filteredDashboardsInfoByGroup = dashboardsInfo; 
        }else if(groupId === DASHBOARDS_INFO_GROUP_OPTIONS_NO_GROUP.id){
            filteredDashboardsInfoByGroup = dashboardsInfo.filter(org => org.parentId === undefined && org.type !== 'group' ); 
        }else if(groupId !== '' ){
            filteredDashboardsInfoByGroup = dashboardsInfo.filter(org => org.parentId!== undefined && groupId.includes(org.parentId.toLowerCase())); 
        }
          
        dispatch(loadDashboardsInfoFilteredByGroupAction(filteredDashboardsInfoByGroup));

    }
}

export default (state = initState, action) => {
    switch (action.type) {
        case DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_INFO:
            return {
                ...state,
                dashboardsInfo: action.payload,
                filteredDashboardsInfoList: null
            }
        case DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_INFO_FILTERED_BY_GROUP:
        return {
            ...state,
            filteredDashboardsInfoByGroup: action.payload,
            filteredDashboardsInfoList: null
        }            
                    
        case DASHBOARDS_INFO_REDUCER_LOAD_DASHBOARDS_GROUPS:
        return {
            ...state,
            dashboardsGroups: action.payload,
            filteredDashboardsInfoList: null
        }            
        case DASHBOARDS_INFO_REDUCER_UPDATE_FETCH_STATUS:
            return {
                ...state,
                subscriptionFetchStatus: action.payload,
                filteredDashboardsInfoList: null
            }
        case DASHBOARDS_INFO_REDUCER_FILTER_LIST:
            const {payload, text} = action;
            
            if (payload && text) {
                  let filteredDashboardsInfoList = state.dashboardsInfo.filter((dashboardInfo) => payload.has(dashboardInfo.id))
                  return {...state, filteredDashboardsInfoList}
                }
            return {...state, filteredDashboardsInfoList: null}
            
        case SOLUTIONS_LOAD_LIST:
            return {...state, commonCoreSolutions: action.payload, filteredDashboardsInfoList: null};
            
        case INSTANCES_LOAD_LIST:
            return {...state, commonCoreInstances: action.payload, filteredDashboardsInfoList: null};
            
        case TENANTS_LOAD_LIST:
            return {...state, commonCoreTenants: action.payload, filteredDashboardsInfoList: null};
        default:
            return state;
    }
}