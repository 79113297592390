import React from 'react';
import { connect } from 'react-redux';

import CommunicationsToolbarDatePicker from './CommunicationsToolbarDatePicker';

const CommunicationsToolbarDatePickerArea = (props) => {
  return (
    <div className="datePickerContainer">
      <div className="dateArea">
        <div>
          <div>
            From Date:
          </div>
          &nbsp;
          <CommunicationsToolbarDatePicker
            dateFilter={props.initialDateFilter}
            timeFilter={props.initialTimeFilter}
            updateDateFilter={props.updateInitialDateFilter}
            updateTimeFilter={props.updateInitialTimeFilter} />
        </div>
      </div>
      <div className="dateArea">
        <div>
          <div>
            To Date:
          </div>
          &nbsp;
          <CommunicationsToolbarDatePicker
            dateFilter={props.finalDateFilter}
            timeFilter={props.finalTimeFilter}
            updateDateFilter={props.updateFinalDateFilter}
            updateTimeFilter={props.updateFinalTimeFilter} />
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
  }), {
}
)(CommunicationsToolbarDatePickerArea);