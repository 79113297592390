import React from 'react';
import { connect } from 'react-redux';

import { Button, DatePicker, DatePickerInput } from 'carbon-components-react';
import InputMask from 'react-input-mask';
import { clearBigTableFilters, fetchBigTable, requestBigTableValues, toggleBigTableFilterAreaOpened, updateBigTableCaption, updateBigTableFinalDateFilter, updateBigTableFinalTimeFilter, updateBigTableInitialDateFilter, updateBigTableInitialTimeFilter } from '../../reducers/PerformanceTablesReducer';

import * as moment from 'moment';

export const BigTableDatePickerArea = (props) => {
  const verifyEmptyFilters = () => {
    let isInitialDateFilterEmpty = !props.bigTableInitialDateFilter && !props.bigTableInitialTimeFilter;
    let isFinalDateFilterEmpty = !props.bigTableFinalDateFilter && !props.bigTableFinalTimeFilter;

    return (isInitialDateFilterEmpty && isFinalDateFilterEmpty)
  }

  const clearFilters = () => {
    if (props.bigTableFilterAreaOpened) {
      props.toggleBigTableFilterAreaOpened();
    }

    props.clearBigTableFilters();
    props.requestBigTableValues(props.selectedMonitorId);
  }

  const checkDateFilters = () => {
    if (props.bigTableInitialDateFilter && props.bigTableFinalDateFilter) {
      callForFetchBigTable();
    }
  }

  const callForFetchBigTable = () => {
    let initialFilterData, finalFilterData, hours, minutes;

    if (props.bigTableInitialDateFilter) {
      initialFilterData = new Date(props.bigTableInitialDateFilter);

      if (props.bigTableInitialTimeFilter) {
        hours = props.bigTableInitialTimeFilter.split(':')[0];
        minutes = props.bigTableInitialTimeFilter.split(':')[1];

        initialFilterData.setHours(hours, minutes);
      }
    }

    if (props.bigTableFinalDateFilter) {
      finalFilterData = new Date(props.bigTableFinalDateFilter);

      if (props.bigTableFinalTimeFilter) {
        hours = props.bigTableFinalTimeFilter.split(':')[0];
        minutes = props.bigTableFinalTimeFilter.split(':')[1];

        finalFilterData.setHours(hours, minutes);
      }
    }

    props.fetchBigTable({
      initialFilterData: initialFilterData,
      finalFilterData: finalFilterData
    });

    if (props.bigTableInitialDateFilter) {
      props.updateBigTableCaption(`Displaying latest values from ${moment(props.bigTableInitialDateFilter).format('DD MMM YYYY')} to ${moment(props.bigTableFinalDateFilter).format('DD MMM YYYY')}`);
      props.requestBigTableValues(props.selectedMonitorId, initialFilterData.toISOString(), finalFilterData.toISOString());
    }
  }

  return (
    <React.Fragment>
      <div className="datePickerContainer">
        <div className="dateArea">
          <div>
            <div>
              From Date:
            </div>
            &nbsp;
            <div className="dateItemsArea">
              <div className="datePickerArea">
                <DatePicker
                  id="date-picker-time-range-start"
                  datePickerType="single"
                  value={props.bigTableInitialDateFilter}
                  onChange={(value) => {
                    props.updateBigTableInitialDateFilter(value[0]);
                  }}>
                  <DatePickerInput
                    id="date-picker-time-range-input-id-start"
                    labelText=""
                    pattern="d{1,2}/d{4}"
                    placeholder="mm/dd/yyyy"
                    invalidText="A valid value is required"
                  />
                </DatePicker>
              </div>
              <div className="timePickerChartArea">
                <InputMask
                  id="time-picker-time-range-start"
                  className="timePicker"
                  placeholder="hh:mm"
                  mask="29:59"
                  formatChars={{
                    '9': '[0-9]',
                    '2': '[0-2]',
                    '5': '[0-5]'
                  }}
                  value={props.bigTableInitialTimeFilter}
                  onChange={(event) => {
                    props.updateBigTableInitialTimeFilter(event.target.value);
                  }}>
                </InputMask>
              </div>
            </div>
          </div>
        </div>
        <div className="dateArea">
          <div>
            <div>
              To Date:
            </div>
            &nbsp;
            <div className="dateItemsArea">
              <div className="datePickerArea">
                <DatePicker
                  id="date-picker-time-range-end"
                  datePickerType="single"
                  value={props.bigTableFinalDateFilter}
                  onChange={(value) => {
                    props.updateBigTableFinalDateFilter(value[0]);
                  }}>
                  <DatePickerInput
                    id="date-picker-time-range-input-id-end"
                    labelText=""
                    pattern="d{1,2}/d{4}"
                    placeholder="mm/dd/yyyy"
                    invalidText="A valid value is required"
                  />
                </DatePicker>
              </div>
              <div className="timePickerChartArea">
                <InputMask
                  id="time-picker-time-range-end"
                  className="timePicker"
                  placeholder="hh:mm"
                  mask="29:59"
                  formatChars={{
                    '9': '[0-9]',
                    '2': '[0-2]',
                    '5': '[0-5]'
                  }}
                  value={props.bigTableFinalTimeFilter}
                  onChange={(event) => {
                    props.updateBigTableFinalTimeFilter(event.target.value);
                  }}>
                </InputMask>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="datePickerContainer">
        <Button
          disabled={verifyEmptyFilters()}
          className="statisticConfirmationButton"
          kind="secondary"
          iconDescription="Clear Filter Button"
          onClick={() => clearFilters()}>
          Clear
        </Button>
        <Button
          className="statisticConfirmationButton"
          iconDescription="Submit Date Range Button"
          onClick={() => checkDateFilters()}>
          Submit
        </Button>
      </div>
    </React.Fragment>
  );
}

export default connect(
  (state) => ({
    bigTableFilterAreaOpened: state.performanceTables.bigTableFilterAreaOpened,
    bigTableInitialDateFilter: state.performanceTables.bigTableInitialDateFilter,
    bigTableInitialTimeFilter: state.performanceTables.bigTableInitialTimeFilter,
    bigTableFinalDateFilter: state.performanceTables.bigTableFinalDateFilter,
    bigTableFinalTimeFilter: state.performanceTables.bigTableFinalTimeFilter
  }), {
  fetchBigTable,
  requestBigTableValues,
  clearBigTableFilters,
  toggleBigTableFilterAreaOpened,
  updateBigTableInitialDateFilter,
  updateBigTableInitialTimeFilter,
  updateBigTableFinalDateFilter,
  updateBigTableFinalTimeFilter,
  updateBigTableCaption
}
)(BigTableDatePickerArea);