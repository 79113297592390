import { headers } from "./BaseServices";

export const fetchIncidents = (solutionId) => {
  return fetch(`configfrontend/incident/orgid/${solutionId}/external`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json());
}