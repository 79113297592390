import React from 'react';
import { connect } from 'react-redux';

import { Accordion, AccordionItem } from 'carbon-components-react';
import { SUBMISSION_STATUS_SUCCESS } from '../../reducers/BaseReducer';
import { resetChartNamesData, updateChartsToUpdateParameter, updateMonitorNamesDataVisibility, updateSelectedMonitorAssetNumber } from '../../reducers/StatisticsChartsReducer';
import StatisticsChartsMonitorAccordionItem from './StatisticsChartsMonitorAccordionItem';

const StatisticsChartsMonitorAccordion = (props) => {
  const onHeadingClick = (index, isOpen) => {
    props.updateSelectedMonitorAssetNumber(isOpen ? props.monitorNames[index].assetNum : null);
    props.updateChartsToUpdateParameter('');
    props.updateMonitorNamesDataVisibility(index, isOpen);
    props.resetChartNamesData();
  }

  const createChartAccordions = () => {
    return props.monitorNames.map((chartDataName, index) =>
      <AccordionItem title={getAccordionText(index, chartDataName.monitorName)} key={index} open={verifyModal() ? chartDataName.visibility : false}
        onHeadingClick={(event) => onHeadingClick(index, event.isOpen)}>
        <StatisticsChartsMonitorAccordionItem
          isModal={props.isModal}
          isStatisticsChartsModalOpen={props.isStatisticsChartsModalOpen}
          isOpen={chartDataName.visibility}
          assetNumber={chartDataName.assetNum} />
      </AccordionItem>
    );
  }

  const verifyModal = () => {
    if (props.isModal) {
      return props.isStatisticsChartsModalOpen;
    } else {
      return !props.isStatisticsChartsModalOpen;
    }
  }

  const getAccordionText = (index, name) => {
    if (props.monitorNames[index].visibility && props.loadChartDataStatus !== SUBMISSION_STATUS_SUCCESS) {
      return 'Loading...'
    } else {
      return name;
    }
  }

  return (
    <Accordion className="accordionContainer">
      {Array.isArray(props.monitorNames) && createChartAccordions()}
    </Accordion>
  );
}

export default connect(
  (state) => ({
    monitorNames: state.statisticCharts.monitorNamesData,
    loadChartDataStatus: state.statisticCharts.loadChartDataStatus
  }), {
  updateSelectedMonitorAssetNumber,
  updateChartsToUpdateParameter,
  updateMonitorNamesDataVisibility,
  resetChartNamesData
}
)(StatisticsChartsMonitorAccordion);