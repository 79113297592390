import React from 'react';
import { connect } from 'react-redux';

import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  Link
} from 'carbon-components-react';

import { closeModal } from '../../reducers/RssFeedReducer';

const RssFeedModal = (props) => {
  const createBody = () => {
    return (
      <div className="modalTextContainer">
        <div>
          The RSS Feed will provide information of the notifications for any feed reader of your choice.
        </div>
        &nbsp;
        <div>
          The link for the feed is:
        </div>
        &nbsp;
        <div>
          {createDisplayUrl()}
        </div>
      </div>
    );
  }

  const createDisplayUrl = () => {
    let url = window.location.href;
    let displayUrl = url.split('.com')[0] + '.com' + props.url;

    return (
      <Link
        className="formItems"
        href={displayUrl}>
        {displayUrl}
      </Link>
    );
  }

  return (
    <ComposedModal
      open={props.openModal}
      onClose={() => props.closeModal()}>
      <ModalHeader
        title='RSS Feed Options'
        iconDescription='Close Modal'
        buttonOnClick={() => props.closeModal()} />
      <ModalBody>
        {createBody()}
      </ModalBody>
    </ComposedModal>
  );
}

export default connect(
  (state) => ({
    openModal: state.rssFeed.openModal,
    url: state.rssFeed.url
  }), {
  closeModal
}
)(RssFeedModal);