import {createStore, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';

import UserReducer from './reducers/UserReducer';
import SubscriptionReducer from './reducers/SubscriptionReducer';
import HealthStatusReducer from './reducers/HealthStatusReducer';
import CommunicationsReducer from './reducers/CommunicationsReducer';
import StatisticsChartsContainerReducer from './reducers/StatisticsChartsReducer';
import RssFeedReducer from './reducers/RssFeedReducer';
import NoticeAPIReducer from './reducers/NoticeAPIReducer';
import DashboardsInfoReducer from './reducers/DashboardsInfoReducer';
import PerformanceTablesReducer from './reducers/PerformanceTablesReducer';
import IncidentManagerReducer from './reducers/IncidentManagerReducer';

import {composeWithDevTools} from 'redux-devtools-extension'


const reducer = combineReducers({
    user : UserReducer,
    subscription: SubscriptionReducer,
    healthStatus: HealthStatusReducer,
    communications: CommunicationsReducer,
    statisticCharts: StatisticsChartsContainerReducer,
    rssFeed: RssFeedReducer,
    noticeAPI: NoticeAPIReducer,
    dashboardInfo: DashboardsInfoReducer,
    performanceTables: PerformanceTablesReducer,
    incident: IncidentManagerReducer
  })

export default createStore(reducer,
  composeWithDevTools(applyMiddleware(thunk))
);
