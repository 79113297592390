import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader, RadioButton } from 'carbon-components-react';
import { updateChartsToUpdateParameter, updateIsChartUpdateConfirmationModalOpen, updateTriggerToUpdateCharts } from '../../reducers/StatisticsChartsReducer';

const ChartUpdateConfirmationModal = (props) => {
  const [chartsToUpdate, setChartsToUpdate] = useState([]);

  useEffect(() => {
    //Checking if the chart data was loaded for the first time
    setChartsToUpdate(new Array(props.chartsNamesData.length).fill(true));
  }, [props.chartsNamesData]);

  const getChartsToUpdateParameter = () => {
    let chartsToUpdateParameter = '&chart=' + props.chartsNamesData.filter((value, index) => chartsToUpdate[index])
      .join('&chart=');

    return chartsToUpdateParameter;
  }

  const renderModalBody = () => {
    return (
      <div className="flex flexWrap">
        {props.chartsNamesData.map((chartName, index) =>
          <div className="width50 textAlignCenter">
            <RadioButton
              key={index}
              className="radioButton"
              labelText={chartName}
              checked={chartsToUpdate[index]}
              onClick={() => updateChartsToUpdate(index)} />
          </div>
        )}
      </div>
    );
  }

  const updateChartsToUpdate = (index) => {
    let newChartsToUpdate = [...chartsToUpdate]; // creates a copy of chartsToUpdate on a new reference
    newChartsToUpdate.splice(index, 1, !chartsToUpdate[index]); // updates the content of the newly created array 
    setChartsToUpdate(newChartsToUpdate);        // new reference is given to chartsToUpdate
  }

  const updateCharts = () => {
    props.updateChartsToUpdateParameter(getChartsToUpdateParameter());

    props.updateTriggerToUpdateCharts(true);

    closeModal();
  }

  const selectAll = () => {
    setChartsToUpdate(new Array(props.chartsNamesData.length).fill(true));
  }

  const deselectAll = () => {
    setChartsToUpdate(new Array(props.chartsNamesData.length).fill(false));
  }

  const closeModal = () => {
    props.updateIsChartUpdateConfirmationModalOpen(false);

    selectAll();
  }

  const isUpdateChartsDisabled = () => {
    return chartsToUpdate.every(value => value === false);
  }

  return (
    <div>
      <ComposedModal
        open={props.isChartUpdateConfirmationModalOpen}
        onClose={() => closeModal()}>
        <ModalHeader
          title='Select which charts to update'
          iconDescription='Close Modal'
          buttonOnClick={() => closeModal()} />
        <ModalBody>
          {props.chartsNamesData && renderModalBody()}
        </ModalBody>
        <ModalFooter>
          <div className="width100 reverseRow">
            <Button
              kind="primary"
              disabled={isUpdateChartsDisabled()}
              onClick={() => updateCharts()}>
              Update Charts
            </Button>
            <Button
              kind="tertiary"
              onClick={() => selectAll()}>
              Select All
            </Button>
            <Button kind="tertiary"
              onClick={() => deselectAll()}>
              Deselect All
            </Button>
            <Button
              kind="secondary"
              onClick={() => closeModal()}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </ComposedModal>
    </div>
  );
}

export default connect(
  (state) => ({
    isChartUpdateConfirmationModalOpen: state.statisticCharts.isChartUpdateConfirmationModalOpen,
    chartsNamesData: state.statisticCharts.chartsNamesData,
    initialFilterDate: state.statisticCharts.initialFilterDate,
    initialFilterTime: state.statisticCharts.initialFilterTime,
    finalFilterDate: state.statisticCharts.finalFilterDate,
    finalFilterTime: state.statisticCharts.finalFilterTime
  }), {
  updateChartsToUpdateParameter,
  updateTriggerToUpdateCharts,
  updateIsChartUpdateConfirmationModalOpen
}
)(ChartUpdateConfirmationModal);