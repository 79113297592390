import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CommunicationsDataTable from '../CommunicationsDataTable';
import { BANNER_CONTENT_SWITCHER_VALUES, STATUS_CONTENT_SWITCHER_VALUES } from '../CommunicationsHelper';
import { filterNotifications, updateFinalDateFilter, updateFinalTimeFilter, updateInitialDateFilter, updateInitialTimeFilter, updateSearchValue, updateStatusFiltersControl } from '../../../reducers/CommunicationsReducer';
import CommunicationsCalendarModal from '../CommunicationsCalendarModal';
import CommunicationsDownloadModal from '../CommunicationsDownloadModal';
import NotificationsDataTableModal from './NotificationsDataTableModal';
import { NOTIFICATION_HEADERS } from '../HeaderHelper';

const NotificationsDataTable = (props) => {
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  useEffect(() => {
    let initialFilterData, finalFilterData, hours, minutes;
    let subCategories = props.notificationSubCategories;
    let categoriesAllowed = [];
    let subCategoriesAllowed = [];
    let isSubCategorySelected = false;
    let statusAllowed = [];
    let subCategoriesFiltered = subCategories.map(obj => obj.subCategory);

    if (props.initialDateFilter) {
      initialFilterData = new Date(props.initialDateFilter);

      if (props.initialTimeFilter) {
        hours = props.initialTimeFilter.split(':')[0];
        minutes = props.initialTimeFilter.split(':')[1];

        initialFilterData.setHours(hours, minutes);
      }
    }

    if (props.finalDateFilter) {
      finalFilterData = new Date(props.finalDateFilter);

      if (props.finalTimeFilter) {
        hours = props.finalTimeFilter.split(':')[0];
        minutes = props.finalTimeFilter.split(':')[1];

        finalFilterData.setHours(hours, minutes);
      }
    }

    if (props.categoryFilters.every(value => value === false)) {
      categoriesAllowed = BANNER_CONTENT_SWITCHER_VALUES;
    } else {
      props.categoryFilters.forEach((value, key) => {
        if (value) {
          categoriesAllowed.push(BANNER_CONTENT_SWITCHER_VALUES[key]);
        }
      });
    }

    if (props.subCategoryFilters.every(item => !item.index)) {
      subCategoriesAllowed = subCategoriesFiltered;
    } else {
      subCategoriesAllowed = props.subCategoryFilters
        .filter(item => item.index === true)
        .map(item => item.subCategory);

      isSubCategorySelected = true;
    }

    if (props.statusFilters.every(value => value === false)) {
      statusAllowed = STATUS_CONTENT_SWITCHER_VALUES.map(v => v.toLowerCase());
    } else {
      props.statusFilters.forEach((value, key) => {
        if (value) {
          statusAllowed.push(STATUS_CONTENT_SWITCHER_VALUES[key].toLowerCase());
        }
      });
    }

    props.filterNotifications({
      initialFilterData: initialFilterData,
      finalFilterData: finalFilterData,
      categoriesAllowed: categoriesAllowed,
      statusAllowed: statusAllowed,
      subCategoriesAllowed: subCategoriesAllowed,
      isSubCategorySelected: isSubCategorySelected
    });
  }, [props.initialDateFilter, props.initialTimeFilter, props.finalDateFilter, props.finalTimeFilter,
  props.categoryFilters, props.subCategoryFilters, props.statusFilters]);

  return (
    <React.Fragment>
      <CommunicationsDataTable
        isNotificationData={true}
        displayCommunications={props.displayNotifications}
        filteredCommunications={props.filteredNotifications}
        headers={NOTIFICATION_HEADERS}
        isMobile={props.isMobile}
        isDownloadModalOpen={isDownloadModalOpen}
        setIsDownloadModalOpen={setIsDownloadModalOpen}
        initialDateFilter={props.initialDateFilter}
        initialTimeFilter={props.initialTimeFilter}
        finalDateFilter={props.finalDateFilter}
        finalTimeFilter={props.finalTimeFilter}
        updateInitialDateFilter={props.updateInitialDateFilter}
        updateInitialTimeFilter={props.updateInitialTimeFilter}
        updateFinalDateFilter={props.updateFinalDateFilter}
        updateFinalTimeFilter={props.updateFinalTimeFilter}
        statusFilters={props.statusFilters}
        updateStatusFiltersControl={props.updateStatusFiltersControl}
        categoryFilters={props.categoryFilters}
        subCategoryFilters={props.subCategoryFilters}
        searchValue={props.searchValue}
        updateCommunicationSearchValue={props.updateSearchValue}
        loadCommunicationStatus={props.loadNotificationStatus} />
      <CommunicationsCalendarModal
        displayCommunications={props.displayNotifications}
        filterCommunications={props.filterNotifications}
        setNotificationModalOpen={props.setNotificationModalOpen} />
      <CommunicationsDownloadModal
        isNotificationData={true}
        data={props.displayNotifications} />
    </React.Fragment>
  )
}

export default connect(
  (state) => ({
    displayNotifications: state.communications.displayNotifications,
    filteredNotifications: state.communications.filteredNotifications,
    initialDateFilter: state.communications.initialDateFilter,
    initialTimeFilter: state.communications.initialTimeFilter,
    finalDateFilter: state.communications.finalDateFilter,
    finalTimeFilter: state.communications.finalTimeFilter,
    categoryFilters: state.communications.categoryFiltersControl,
    subCategoryFilters: state.communications.subCategoryFiltersControl,
    statusFilters: state.communications.statusFiltersControl,
    searchValue: state.communications.searchValue,
    notificationSubCategories: state.communications.notificationSubCategories,
    loadNotificationStatus: state.communications.loadNotificationStatus
  }), {
  filterNotifications,
  updateInitialDateFilter,
  updateInitialTimeFilter,
  updateFinalDateFilter,
  updateFinalTimeFilter,
  updateStatusFiltersControl,
  updateSearchValue
}
)(NotificationsDataTable);