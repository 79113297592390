import React from 'react';
import { connect } from 'react-redux';

import { Loading, Tile } from 'carbon-components-react';
import { SUBMISSION_STATUS_SUBMITTED } from '../../reducers/BaseReducer';

const WARNING_DETAILS_TITLES = [
  "Low Free Disk (<10%)",
  "High CPU Usage ( avg in 30 mins > 80%)",
  "Low Available Memory (<10%)",
  "JVM: High Response Time (> 2 Seconds or Error)",
  "JVM: Low Free Heap (<200Mb)",
  "JVM: Hung Thread (>1)"
];

const WarningDetailsDataDisplay = (props) => {
  const createWarningVisualization = () => {
    let visualizationRows = [];
    const labels = [];
    const dataArrays = [];

    props.data.forEach(item => {
      labels.push(item.label);
      dataArrays.push(item.data);
    });

    visualizationRows.push(labels);

    for (let index = 0; index < dataArrays[0].length; index++) {
      const array = [];
      for (let indexJ = 0; indexJ < dataArrays.length; indexJ++) {
        array.push(dataArrays[indexJ][index]);
      }
      visualizationRows.push(array);
    }

    return visualizationRows.map(row =>
      <div className='warningDetailColumn'>
        {row.map(value =>
          <div className='warningDetailCell'>
            {displayValue(value)}
          </div>
        )}
      </div>
    );
  }

  const displayValue = (value) => {
    if (value === 'totalcpuusage') {
      return 'Total CPU Usage %';
    } else if (value === 'lowavailablememory') {
      return 'Available %';
    } else {
      return value;
    }
  }

  return (
    <Tile className='dataWarningDetailsTile'>
      <div className='dataWarningDetailsTitle'>
        {WARNING_DETAILS_TITLES[props.index]}
      </div>
      <div className='dataWarningDetailsValue'>
        {props.loadWarningDetailsStatus === SUBMISSION_STATUS_SUBMITTED ?
          <div className='flex justifyCenter'>
            <Loading small withOverlay={false} />
          </div>
          :
          (props.data && props.data.length > 0) ? createWarningVisualization() : "No data to show"}
      </div>
    </Tile>
  );
}

export default connect(
  (state) => ({
    loadWarningDetailsStatus: state.performanceTables.loadWarningDetailsStatus
  }), {
}
)(WarningDetailsDataDisplay);