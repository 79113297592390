import React from 'react';
import { connect } from 'react-redux';

import { MultiSelect } from 'carbon-components-react';
import { BANNER_CONTENT_SWITCHER_VALUE_ITEMS } from './SubscriptionHelper';
import { updateSelectedCategories } from '../../../reducers/SubscriptionReducer';

const EmailSubscriptionCategoryOptionsArea = (props) => {
  return (
    <MultiSelect
      className='cccMultiSelect'
      label="Notification Categories"
      initialSelectedItems={BANNER_CONTENT_SWITCHER_VALUE_ITEMS}
      invalid={props.selectedCategories.length === 0}
      invalidText="You must select at least one category for subscription"
      items={BANNER_CONTENT_SWITCHER_VALUE_ITEMS}
      itemToString={item => (item ? item.text : '')}
      onChange={(event) => props.updateSelectedCategories(event.selectedItems)}
    />
  );
}

export default connect(
  (state) => ({
    selectedCategories: state.subscription.selectedCategories
  }), {
  updateSelectedCategories
}
)(EmailSubscriptionCategoryOptionsArea);