import React, { Component } from 'react';
import { connect } from 'react-redux';

import Page from '../App/Page';
import Banner from '../Banner/Banner';
import Title from './Title';
import ComponentBar from './ComponentBar';
import HealthStatusComponent from '../HealthStatus/HealthStatusComponent';
import StatisticsChartsComponent from '../StatisticsCharts/StatisticsChartsComponent';

import { verifyUserSubscription } from '../../reducers/SubscriptionReducer';

import './Dashboard.css';
import CommunicationTabs from '../Communications/CommunicationTabs';
import SubscriptionAlertComponent from '../Banner/SubscriptionAlertComponent';
import PerformanceTablesComponent from '../PerformanceTables/PerformanceTablesComponent';
import { shouldShowPerformanceTablesAboveCommunications } from '../Helper/ComponentHelper';
import { getIncidentList } from '../../reducers/IncidentManagerReducer';
import IncidentComponent from '../Incidents/IncidentComponent';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = { width: 0, height: 0 };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate = ({ ...prevProps }) => {
    if ((this.props.envSchema.id !== prevProps.envSchema.id && this.props.IUI !== '') ||
      (this.props.IUI !== prevProps.IUI && this.props.envSchema.id !== '')) {
      this.props.getIncidentList(this.props.envSchema.id);
      this.props.verifyUserSubscription(this.props.IUI, this.props.envSchema.id);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  shouldShowHealthTable = () => {
    return !(this.props.envSchema.visibility && this.props.envSchema.visibility.shouldHideHealthTable);
  }

  shouldShowPerformanceTablesComponent() {
    return this.props.envSchema.performanceTables &&
      !Object.keys(this.props.envSchema.performanceTables).every(flag => this.props.envSchema.performanceTables[flag] === false);
  }

  render() {
    return (
      <Page>

        <Banner />
        <Title />

        <div className="spaceBetweenComponents" />

        <ComponentBar />

        <div className="spaceBetweenComponents" />

        <SubscriptionAlertComponent />

        <div className='componentContainer'>
          <IncidentComponent />
        </div>

        {this.shouldShowHealthTable() ?
          <div className="componentContainer">
            <div className="columnComponentContainer">
              <HealthStatusComponent />
              <div className="desktopOnly spaceBetweenContainers" />
              <StatisticsChartsComponent />
            </div>
            {shouldShowPerformanceTablesAboveCommunications(this.props.envSchema.id) ?
              <React.Fragment>
                <div className="columnComponentContainer">
                  {this.shouldShowPerformanceTablesComponent() &&
                    <React.Fragment>
                      <PerformanceTablesComponent width={this.state.width} />
                      <div className="desktopOnly spaceBetweenContainers" />
                    </React.Fragment>
                  }
                  <CommunicationTabs envSchemaDataAlreadyLoaded={true} width={this.state.width} />
                </div>
              </React.Fragment>
              :
              <div className="columnComponentContainer">
                <CommunicationTabs width={this.state.width} />
                {this.shouldShowPerformanceTablesComponent() &&
                  <React.Fragment>
                    <div className="desktopOnly spaceBetweenContainers" />
                    <PerformanceTablesComponent width={this.state.width} />
                  </React.Fragment>
                }
              </div>}
          </div>
          :
          <div className="componentContainer">
            <div className="commoncoreColumnComponentContainer">
              <CommunicationTabs envSchemaDataAlreadyLoaded={true} width={this.state.width} />
            </div>
          </div>}
      </Page>
    );
  }
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    IUI: state.user.IUI
  }), {
  getIncidentList,
  verifyUserSubscription
}
)(Dashboard);