import React from 'react';

import { generateArrow } from './graphIconGenerator';
import { Icon } from 'carbon-components-react';

const TitleComponent = (props) => {
  return (
    <div className="pageComponentTitle">
      <div>
        {generateArrow(props.open, props.setOpen)}
        {props.message}
      </div>
      <Icon
        name='launch--glyph'
        className="modalIcon"
        description="Click to open the Health Status in an external modal"
        title="Click to open the Health Status in an external modal"
        onClick={() => props.setModalOpen(true)} />
    </div>
  );
}

export default TitleComponent;