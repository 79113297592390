import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Tab, Tabs } from 'carbon-components-react';
import { requestBigTableValues, requestCurrentStatusAllServersData, requestCurrentStatusData, requestWarningStatusData, resetPerformanceTablesData, updateSelectedMonitorId } from '../../reducers/PerformanceTablesReducer';
import { compareAlphabetically } from '../Helper/ComponentHelper';
import BigTableDataTable from './BigTableDataTable';
import CurrentStatusComponent from './CurrentStatusComponent';
import PerformanceTablesModal from './PerformanceTablesModal';
import WarningDetailsComponent from './WarningDetailsComponent';
import TitleComponent from '../Helper/TitleComponent';
import PerformanceTablesMonitorCombobox from './PerformanceTablesMonitorCombobox';
import { createComboboxValues, shouldShowPerformanceTablesComponent } from './PerformanceTablesHelper';

import './PerformanceTables.css';

class PerformanceTablesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      isPerformanceTablesModalOpen: window.localStorage.getItem('isPerformanceTablesModalOpen') === "true" || false,
      tabIndex: 0
    }

    this.setOpen = this.setOpen.bind(this);
    this.setTabIndex = this.setTabIndex.bind(this);
    this.setComponentModalOpen = this.setComponentModalOpen.bind(this);
    this.requestPerformanceTablesData = this.requestPerformanceTablesData.bind(this);
  }

  checkStyleForBigTable = () => {
    if (this.props.monitorNames.length > 0 && this.props.envSchema.performanceTables &&
      !Object.keys(this.props.envSchema.performanceTables).every(flag => this.props.envSchema.performanceTables[flag] === false)) {
      return {};
    } else {
      return { display: 'none' }
    }
  }

  requestPerformanceTablesData = (selectedMonitorId) => {
    this.props.resetPerformanceTablesData();

    this.props.updateSelectedMonitorId(selectedMonitorId);

    if (shouldShowPerformanceTablesComponent(this.props.envSchema.performanceTables, this.props.envSchema.performanceTables.shouldShowCurrentStatus)) {
      this.requestAllCurrentStatusValues(selectedMonitorId);

      this.props.requestCurrentStatusAllServersData(selectedMonitorId);
    }

    if (shouldShowPerformanceTablesComponent(this.props.envSchema.performanceTables, this.props.envSchema.performanceTables.shouldShowWarningDetails)) {
      this.requestAllWarningDetailsData(selectedMonitorId);
    }

    if (shouldShowPerformanceTablesComponent(this.props.envSchema.performanceTables, this.props.envSchema.performanceTables.shouldShowBigTables)) {
      this.props.requestBigTableValues(selectedMonitorId);
    }
  }

  requestAllCurrentStatusValues = (monitorId) => {
      this.props.requestCurrentStatusData(monitorId);
  }

  requestAllWarningDetailsData = (monitorId) => {
      this.props.requestWarningStatusData(monitorId);
  }

  setOpen = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  }

  setComponentModalOpen = (value) => {
    window.localStorage.setItem('isPerformanceTablesModalOpen', value);

    this.setState({ isPerformanceTablesModalOpen: value });
  }

  setTabIndex = (index) => {
    this.setState({ tabIndex: index });
  }

  render() {
    return (
      <div className="pageComponentArea" style={this.checkStyleForBigTable()}>
        <TitleComponent
          open={this.state.open}
          setOpen={this.setOpen}
          message='Performance Tables'
          setModalOpen={this.setComponentModalOpen} />
        {this.state.open &&
          <React.Fragment>
            <div className='flex'>
              <PerformanceTablesMonitorCombobox
                requestPerformanceTablesData={this.requestPerformanceTablesData} />
              &nbsp;
              {this.props.selectedMonitorId &&
                <Button onClick={() => this.requestPerformanceTablesData(this.props.selectedMonitorId)}>
                  Reload
                </Button>
              }
            </div>
            <div className={this.props.selectedMonitorId ? 'height16' : 'height400'} />
            {this.props.selectedMonitorId &&
              <Tabs className='horizontalScroll' selected={this.state.tabIndex} fullWidth onSelectionChange={(index) => this.setTabIndex(index)}>
                {shouldShowPerformanceTablesComponent(this.props.envSchema.performanceTables, this.props.envSchema.performanceTables.shouldShowCurrentStatus) &&
                  <Tab key="0" label="Current Status">
                    <CurrentStatusComponent />
                  </Tab>}
                {shouldShowPerformanceTablesComponent(this.props.envSchema.performanceTables, this.props.envSchema.performanceTables.shouldShowWarningDetails) &&
                  <Tab key="1" label="Warning Details">
                    <WarningDetailsComponent />
                  </Tab>}
                {shouldShowPerformanceTablesComponent(this.props.envSchema.performanceTables, this.props.envSchema.performanceTables.shouldShowBigTables) &&
                  <Tab key="2" label="Big Tables">
                    <BigTableDataTable />
                  </Tab>}
              </Tabs>
            }
          </React.Fragment>
        }

        <PerformanceTablesModal
          tabIndex={this.state.tabIndex}
          setTabIndex={this.setTabIndex}
          comboboxValues={createComboboxValues(this.props.monitorNames).sort(compareAlphabetically)}
          isModalOpen={this.state.isPerformanceTablesModalOpen}
          requestPerformanceTablesData={this.requestPerformanceTablesData}
          closeModal={this.setComponentModalOpen} />
      </div >
    );
  }
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    monitorNames: state.statisticCharts.monitorNamesData,
    bigTableValues: state.performanceTables.bigTableValues,
    selectedMonitorId: state.performanceTables.selectedMonitorId,
    loadChartDataStatus: state.performanceTables.loadChartDataStatus
  }), {
  requestBigTableValues,
  requestCurrentStatusData,
  requestCurrentStatusAllServersData,
  requestWarningStatusData,
  updateSelectedMonitorId,
  resetPerformanceTablesData
}
)(PerformanceTablesComponent);