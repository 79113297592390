import React from 'react';
import { connect } from 'react-redux';

import { BadIcon, GoodIcon } from '../../HealthStatus/listIcons';
import { TextInputSkeleton } from 'carbon-components-react';
import { SUBMISSION_STATUS_FAILED, SUBMISSION_STATUS_SUBMITTED, SUBMISSION_STATUS_SUCCESS } from '../../../reducers/BaseReducer';

const EmailSubscriptionStatusText = (props) => {
  const createSubscriptionStatusText = () => {
    if (props.checkSubscriptionStatus === SUBMISSION_STATUS_SUBMITTED) {
      return <TextInputSkeleton />;
    } else if (props.currentSubscriptions && props.currentSubscriptions.length > 0 && props.userEmail) {
      let responseText = [];

      responseText.push(<div className='currentSubscriptionText'>Your current subscription(s) for {props.envSchema.name}:</div>);
      responseText.push(<br></br>);

      props.currentSubscriptions.forEach((subscription, index) => {
        responseText.push(subscription.instance && <div
          key={index + "_title"}
          className="legend regionTitle">
          For {subscription.instance} region(s):
        </div>);

        responseText.push(<div className="legendGrouping desktopOnly">
          <div
            key={index + "_announcement"}
            className="legend">
            Announcement: {subscription.annoucement ? <GoodIcon /> : <BadIcon />}
          </div>
          <div
            key={index + "_incident"}
            className="legend">
            Incident: {subscription.incident ? <GoodIcon /> : <BadIcon />}
          </div>
          <div
            key={index + "_maintenance"}
            className="legend">
            Maintenance: {subscription.maintenance ? <GoodIcon /> : <BadIcon />}
          </div>
          <div
            key={index + "_alert"}
            className="legend">
            Alert: {subscription.alert ? <GoodIcon /> : <BadIcon />}
          </div>
          <div
            key={index + "_security"}
            className="legend">
            Security: {subscription.security ? <GoodIcon /> : <BadIcon />}
          </div>
        </div>);
      });

      return responseText;
    }
  }

  const createResponseText = () => {
    let subscriptionFailedFlag = props.checkSubscriptionStatus === SUBMISSION_STATUS_FAILED || props.checkSubscriptionStatus === SUBMISSION_STATUS_SUCCESS;
    let emptySubscriptionFlag = props.currentSubscriptions && props.currentSubscriptions.length <= 0;
    let emailFlag = props.userEmail && props.userEmail !== '';

    if (subscriptionFailedFlag && emptySubscriptionFlag && emailFlag) {
      return (
        <div className='errorMessage' key='errorMessage'>
          {props.responseText}
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <br></br>
      {createSubscriptionStatusText()}
      {createResponseText()}
    </React.Fragment>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    userEmail: state.user.userEmail,
    currentSubscriptions: state.subscription.currentSubscriptions,
    checkSubscriptionStatus: state.subscription.checkSubscriptionStatus
  }), {
}
)(EmailSubscriptionStatusText);