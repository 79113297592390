import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SubscriptionAlertInlineNotification from './SubscriptionAlertInlineNotification';
import SubscriptionAlertModal from './SubscriptionAlertModal';

const SubscriptionAlertComponent = (props) => {
  const [shouldAlertUserOfSubscription, setShouldAlertUserOfSubscription] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    let shouldAlertUser = window.localStorage.getItem('mustSubscriptionOptionAppear') !== "false" &&
      !props.userSubscriptionFlag && props.envSchema.visibility && !props.envSchema.visibility.shouldHideEmailSubscriptionButton;

    setShouldAlertUserOfSubscription(shouldAlertUser);
  }, []);

  const cancelSubscriptionWarning = () => {
    window.localStorage.setItem('mustSubscriptionOptionAppear', "false");

    setIsModalOpen(false);
  }

  return (
    <div>
      {shouldAlertUserOfSubscription &&
        <React.Fragment>
          <SubscriptionAlertInlineNotification setIsModalOpen={setIsModalOpen} />
          <SubscriptionAlertModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} cancelSubscriptionWarning={cancelSubscriptionWarning} />
        </React.Fragment>
      }
    </div>
  )
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    userSubscriptionFlag: state.subscription.userSubscriptionFlag
  }),
  {
  }
)(SubscriptionAlertComponent);