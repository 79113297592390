import React from 'react';
import { connect } from 'react-redux';

import {
  ComposedModal,
  ModalHeader,
  ModalBody
} from 'carbon-components-react';

import Banner from '../Banner/Banner';
import IncidentDataTable from './IncidentDataTable';

const IncidentModal = (props) => {
  const createModalBody = () => {
    return (
      <React.Fragment>
        <Banner />
        <IncidentDataTable />
      </React.Fragment>
    );
  }

  return (
    <div className="desktopOnly">
      <ComposedModal
        open={props.isModalOpen}
        onClose={() => props.closeModal(false)}>
        <ModalHeader
          title="Incident Board"
          iconDescription='Close Modal'
          buttonOnClick={() => props.closeModal(false)} />
        <ModalBody className="modalBodyWithOverflow">
          {createModalBody()}
        </ModalBody>
      </ComposedModal>
    </div>
  );
}

export default connect(
  (state) => ({
  }), {
}
)(IncidentModal);