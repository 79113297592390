import { requestEncryptedId } from '../lib/EncryptedEnvIdServices';
import {
  SUBMISSION_STATUS_INITIAL_STATUS,
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_SUCCESS,
  SUBMISSION_STATUS_SUBMITTED
} from './BaseReducer';

const initState = {
  openNoticeModal: false,
  openMonitorModal: false,
  encryptedEnvId: '',
  fileType: 'json',
  envIdStatus: SUBMISSION_STATUS_INITIAL_STATUS
}

const NOTICE_API_UPDATE_OPEN_NOTICE_MODAL_FLAG = 'NOTICE_API_UPDATE_OPEN_NOTICE_MODAL_FLAG';
export const updateOpenNoticeModalFlagAction = (newFlag) => ({ type: NOTICE_API_UPDATE_OPEN_NOTICE_MODAL_FLAG, payload: newFlag });

const NOTICE_API_UPDATE_OPEN_MONITOR_MODAL_FLAG = 'NOTICE_API_UPDATE_OPEN_MONITOR_MODAL_FLAG';
export const updateOpenMonitorModalFlagAction = (newFlag) => ({ type: NOTICE_API_UPDATE_OPEN_MONITOR_MODAL_FLAG, payload: newFlag });

const NOTICE_API_UPDATE_FILE_TYPE = 'NOTICE_API_UPDATE_FILE_TYPE';
export const updateFileTypeAction = (fileType) => ({ type: NOTICE_API_UPDATE_FILE_TYPE, payload: fileType });

const NOTICE_API_UPDATE_ENV_ID = 'NOTICE_API_UPDATE_ENV_ID';
export const updateEnvIdAction = (encryptedEnvId) => ({ type: NOTICE_API_UPDATE_ENV_ID, payload: encryptedEnvId });

const NOTICE_API_UPDATE_ENV_ID_STATUS = 'NOTICE_API_UPDATE_ENV_ID_STATUS';
export const updateEnvIdStatusAction = (newStatus) => ({ type: NOTICE_API_UPDATE_ENV_ID_STATUS, payload: newStatus });

export const requestEnvIdForNotice = (envSchemaId) => {
  return (dispatch) => {
    dispatch(updateEnvIdStatusAction(SUBMISSION_STATUS_SUBMITTED));

    requestEncryptedId(envSchemaId)
      .then(data => {
        dispatch(updateEnvIdStatusAction(SUBMISSION_STATUS_SUCCESS));
        dispatch(updateOpenNoticeModalFlagAction(true));
        dispatch(updateEnvIdAction(data.value))
      })
      .catch(error => {
        dispatch(updateEnvIdStatusAction(SUBMISSION_STATUS_FAILED));
        dispatch(updateOpenNoticeModalFlagAction(false));
      });
  }
}

export const requestEnvIdForMonitor = (envSchemaId) => {
  return (dispatch) => {
    dispatch(updateEnvIdStatusAction(SUBMISSION_STATUS_SUBMITTED));

    requestEncryptedId(envSchemaId)
      .then(data => {
        dispatch(updateEnvIdStatusAction(SUBMISSION_STATUS_SUCCESS));
        dispatch(updateOpenMonitorModalFlagAction(true));
        dispatch(updateEnvIdAction(data.value))
      })
      .catch(error => {
        dispatch(updateEnvIdStatusAction(SUBMISSION_STATUS_FAILED));
        dispatch(updateOpenMonitorModalFlagAction(false));
      });
  }
}

export const closeModal = () => {
  return (dispatch) => {
    dispatch(updateOpenNoticeModalFlagAction(false));
    dispatch(updateOpenMonitorModalFlagAction(false));
    dispatch(updateFileTypeAction('json'));
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case NOTICE_API_UPDATE_OPEN_NOTICE_MODAL_FLAG:
      return { ...state, openNoticeModal: action.payload }

    case NOTICE_API_UPDATE_OPEN_MONITOR_MODAL_FLAG:
      return { ...state, openMonitorModal: action.payload }

    case NOTICE_API_UPDATE_ENV_ID:
      return { ...state, encryptedEnvId: action.payload }

    case NOTICE_API_UPDATE_FILE_TYPE:
      return { ...state, fileType: action.payload }

    case NOTICE_API_UPDATE_ENV_ID_STATUS:
      return { ...state, envIdStatus: action.payload }

    default:
      return state;
  }
}