import React from 'react';
import { connect } from 'react-redux';

import { TextInput } from 'carbon-components-react';
import { determineInvalidEmail } from './SubscriptionHelper';
import { updateSelectedEmail } from '../../../reducers/SubscriptionReducer';

const EmailSubscriptionTextField = (props) => {
  return (
    <TextInput
      id="emailTextInput"
      className="formItems"
      labelText="E-mail"
      placeholder="name@example.com"
      type="text"
      value={props.selectedEmail}
      invalid={determineInvalidEmail(props.allowEmptyEmail, props.selectedEmail)}
      invalidText="Please, enter a valid e-mail"
      onChange={(event) => props.updateSelectedEmail(event.target.value)}
    />
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    selectedEmail: state.subscription.selectedEmail,
    allowEmptyEmail: state.subscription.allowEmptyEmail
  }), {
  updateSelectedEmail
}
)(EmailSubscriptionTextField);