import React, { Component } from 'react';
import { connect } from 'react-redux';

import TitleComponent from '../Helper/TitleComponent';
import IncidentDataTable from './IncidentDataTable';
import IncidentModal from './IncidentModal';

class IncidentComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      isIncidentComponentModalOpen: window.localStorage.getItem('isIncidentComponentModalOpen') === "true" || false
    }

    this.setOpen = this.setOpen.bind(this);
    this.setIncidentModalOpen = this.setIncidentModalOpen.bind(this);
  }

  setOpen = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  }

  setIncidentModalOpen = (value) => {
    window.localStorage.setItem('isIncidentComponentModalOpen', value);

    this.setState({ isIncidentComponentModalOpen: value });
  }

  render() {
    return (
      <div className="pageComponentArea width100">
        {this.props.incidentList.length > 0 && <React.Fragment>
          <TitleComponent
            open={this.state.open}
            setOpen={this.setOpen}
            message='Incident Board'
            setModalOpen={this.setIncidentModalOpen} />
          {this.state.open &&
            <React.Fragment>
              <IncidentDataTable />
              <div className="spaceBetweenComponents" />
            </React.Fragment>
          }

          <IncidentModal
            isModalOpen={this.state.isIncidentComponentModalOpen}
            closeModal={this.setIncidentModalOpen} />
        </React.Fragment>}
      </div >
    );
  }
}

export default connect(
  (state) => ({
    incidentList: state.incident.incidentList
  }), {
}
)(IncidentComponent);