import React, { Component } from 'react';
import {connect} from 'react-redux' ;

import './components.css';

class PageFooter extends Component {
  createFooterItems = () => {
    if (this.props.envSchema.footer === undefined || !this.props.envSchema.footer.show) {
      return (<div></div>);
    } else if (this.props.envSchema.footer.hyperlinks) {
      return (this.props.envSchema.footer.hyperlinks.map(hyperlink =>
        <a key={hyperlink.desc} title={hyperlink.title} className="footerItem" target="_blank" href={hyperlink.hyperlink}>{hyperlink.desc ? hyperlink.desc : hyperlink.title}</a>
      ));
    }
  }

  render() {
    return (
      <footer className="app-footer">
        {this.createFooterItems()}
      </footer>
    );
  }
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema
  }),
  {
  }
)(PageFooter);