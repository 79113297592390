import 'carbon-components/scss/globals/scss/styles.scss';

import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import './App.scss';
import InitialPage from './components/App/InitialPage';
import LoginFailurePage from './components/App/LoginFailurePage';
import MaintenancePage from './components/App/MaintenancePage';

const router = createBrowserRouter([{
  path: "/loginFailure",
  element: <LoginFailurePage />
}, {
  path: "/maintenance/:customerId?",
  element: <MaintenancePage />
}, {
  path: "/:customerId?",
  element: <InitialPage />
}]);

class App extends Component {
  render() {
    return (
      <div>
        <main>
          <RouterProvider router={router} />
        </main>
      </div>
    );
  }
}

export default connect(
  (state) => ({
  }),
  {
  }
)(App);
