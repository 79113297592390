import React from 'react';
import { connect } from 'react-redux';
import { createLastUpdateText } from '../Helper/ComponentHelper';

import './Dashboard.css';

const Title = (props) => {
  const getTitleMessage = () => {
    if (props.envSchema.headerMessage) {
      return props.envSchema.headerMessage;
    } else {
      return "Current Availability of IBM Watson IoT Platform";
    }
  }

  return (
    <div className="titleContainer">
      <div>
        <div className="titleMessage">
          {getTitleMessage()}
        </div>
        {!(props.envSchema.visibility && props.envSchema.visibility.shouldHideHealthTable) && createLastUpdateText(props.date)}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    date: state.user.date
  })
)(Title);