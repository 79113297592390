import {headers} from "./BaseServices" ;

export const fetchBigTables = (monitorId,startDate,endDate) => {
  let url = '/spid/iotm/api/v1/bigtable/'+monitorId;
  if(startDate !== undefined && startDate !== null && endDate !== undefined && endDate) {
    url+='?start='+startDate+'&end='+endDate;
  }
  else if(startDate !== undefined && startDate !== null) {
    url+='?start='+startDate;
  }
  else if(endDate !== undefined && endDate !== null) {
    url+='?end='+endDate;
  }
  
  return fetch(url, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}

export const fetchCurrentServices = (monitorId) => {
  return fetch(`/spid/iotm/api/v1/health/currentstatus/${monitorId}/query/all`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}

export const fetchCurrentServicesAllServers = (monitorId) => {
  return fetch(`/spid/iotm/api/v1/health/currentstatusservers/${monitorId}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}

export const fetchWarningDetails = (monitorId) => {
  return fetch(`/spid/iotm/api/v1/health/warningdetails/${monitorId}/query/all`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(res => res.json());
}