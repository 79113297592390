import React from 'react';
import { connect } from 'react-redux';

import { DatePicker, DatePickerInput } from 'carbon-components-react';

import InputMask from 'react-input-mask';

const CommunicationsToolbarDatePicker = (props) => {
  return (
    <div className="dateItemsArea">
      <div className="datePickerArea">
        <DatePicker
          id="date-picker-time-range-start"
          datePickerType="single"
          value={props.dateFilter}
          onChange={(value) => { props.updateDateFilter(value[0]) }}>
          <DatePickerInput
            id="date-picker-time-range-input-id-start"
            labelText=""
            pattern="d{1,2}/d{4}"
            placeholder="mm/dd/yyyy"
            invalidText="A valid value is required"
          />
        </DatePicker>
      </div>
      <br/>
      <div className="timePickerChartArea">
        <InputMask
          id="time-picker-time-range-start"
          className="timePicker"
          placeholder="hh:mm"
          mask="29:59"
          formatChars={{
            '9': '[0-9]',
            '2': '[0-2]',
            '5': '[0-5]'
          }}
          value={props.timeFilter}
          onChange={(event) => { props.updateTimeFilter(event.target.value) }}>
        </InputMask>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
  }), {
}
)(CommunicationsToolbarDatePicker);