import {headers} from "./BaseServices" ; 


export const getDashboardsInfoByUserId = (userId, showGroups) => {
    return fetch(`/authorization/iotm/api/v2/users/${userId}/all?showGroups=${showGroups}`,
    headers)
    .then(res => res.json());
}

export const getSolutionsByGroup = (group) => {
    return fetch(`/authorization/iotm/api/v1/${group}/solution/all`, headers)
      .then(res => res.json());
  }

export const getInstancesBySolution = (solution) => {
    return fetch(`/authorization/iotm/api/v1/commoncore/solution/${solution}/instance/all`, headers)
      .then(res => res.json());
  }
  
export const getTenantsByInstance = (solution, instance) => {
    return fetch(`/authorization/iotm/api/v1/commoncore/solution/${solution}/instance/${instance}/tenant/all`, headers)
        .then(res => res.json());
  }
