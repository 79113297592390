import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import CommunicationsToolbar from './Toolbar/CommunicationsToolbar';
import { DataTable, PaginationV2, SkeletonPlaceholder } from 'carbon-components-react';
import { SUBMISSION_STATUS_SUBMITTED } from '../../reducers/BaseReducer';
import { cleanHTML, customSort, upperCaseFirstLetter } from '../Helper/ComponentHelper';
import { checkCommunicationsCellId, checkCommunicationsHeader } from './HeaderHelper';
import { determineStatusCellColor } from './CommunicationsHelper';
import { displayDate } from '../Helper/TimeHelper';
import parse from 'html-react-parser';
import TagCell from './TagCell';
import { renderExpandedInfo } from '../Helper/DataTableHelper';

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow
} = DataTable;

const CommunicationsDataTable = (props) => {
  let [pageNumber, setPageNumber] = useState(1);
  let [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setPageNumber(1);
    setPageSize(10);
  }, [props.initialDateFilter, props.initialTimeFilter, props.finalDateFilter, props.finalTimeFilter,
  props.statusFilters, props.categoryFilters, props.subCategoryFilters]);

  const renderHeader = (getHeaderProps, header) => {
    let c;

    if (!checkCommunicationsHeader(header.key, props.isMobile)) {
      return c;
    }

    c = <TableHeader
      {...getHeaderProps({ header })}
      className={header.key.includes('title') ? "headerCellTitle" : "headerCell"}
      isSortable={true}>
      {header.header}
    </TableHeader>;

    return c;
  }

  const renderCell = (row, cell) => {
    let c;

    if (!checkCommunicationsCellId(cell.id, props.isMobile)) {
      return c;
    }

    if (cell.id.includes('realDate')) {
      c = renderLastUpdatesCell(row);
    } else if (cell.id.includes('title')) {
      c = (<TableCell key={cell.id}>
        <div className="cellTitle">{cell.value}</div>
      </TableCell>
      );
    } else if (cell.id.includes('category')) {
      let subCategoryCell = row.cells.find(c => c.id === row.id + ':subCategory');

      c = <TagCell cellId={cell.id} cellValue={cell.value} subCategoryCell={subCategoryCell} />;
    }
    else {
      c = <TableCell key={cell.id}>{cell.value}</TableCell>;
    }

    return c;
  }

  const renderLastUpdatesCell = (row) => {
    let dateCell = row.cells.find(c => c.id === row.id + ':realDate');
    let statusCell = row.cells.find(c => c.id === row.id + ':status');
    let c;

    c = <TableCell key={dateCell.id}>
      <div>
        <div className={"cellDateText " + determineStatusCellColor(statusCell.value)}>{statusCell.value}: </div>
        <div className="cellDateText">{displayDate(dateCell.value)}</div>
      </div>
    </TableCell>;
    return c;
  }

  const renderCellExpanded = (row, cell) => {
    let c;

    let enabledEventTimeRange = row.cells.find(c => c.id === row.id + ':enabledEventTimeRange');
    let startCell = row.cells.find(c => c.id === row.id + ':announcementEventStartTime');
    let endCell = row.cells.find(c => c.id === row.id + ':announcementEventEndTime');
    let publishedDateCell = row.cells.find(c => c.id === row.id + ':date');
    let statusCell = row.cells.find(c => c.id === row.id + ':status');
    let instanceCell = row.cells.find(c => c.id === row.id + ':instance');
    let monitorControlArrayCell = row.cells.find(c => c.id === row.id + ':monitorControlArray');
    let categoryCell = row.cells.find(c => c.id === row.id + ':category');
    let subCategoryCell = row.cells.find(c => c.id === row.id + ':subCategory');
    let updatedDateCell = row.cells.find(c => c.id === row.id + ':updateDate');

    let filteredMonitorsAffectedArray = monitorControlArrayCell ? monitorControlArrayCell.value.map(item => {
      if (item.monitorValueDuringEvent === 'not_available') {
        item.monitorValueDuringEvent = 'Not available';
      } else if (item.monitorValueDuringEvent === 'partially_available') {
        item.monitorValueDuringEvent = 'Partially available';
      } else {
        item.monitorValueDuringEvent = item.monitorValueDuringEvent.charAt(0).toUpperCase() + item.monitorValueDuringEvent.slice(1);
      }
      return item;
    }).filter(item => item.monitorValueAfterEvent !== item.monitorValueDuringEvent) : [];

    if (cell.id.includes('body')) {
      c =
        <div className="expandedArea" key={cell.id}>
          <br />
          {shouldRenderMobileInfo() && renderMobileInfo(categoryCell, subCategoryCell, updatedDateCell, statusCell)}
          {shouldRenderPublishedDateInfo(statusCell.value) && renderPublishedDateInfo(publishedDateCell)}
          {shouldRenderScheduledInfo(enabledEventTimeRange, startCell, endCell) && renderScheduledInfo(startCell, endCell)}
          {shouldRenderMonitorsAffectedInfo(filteredMonitorsAffectedArray) && renderMonitorsAffectedInfo(filteredMonitorsAffectedArray)}
          {shouldRenderRegionsInfo() && renderRegionsInfo(instanceCell)}
          {renderMessageInfo(cell)}
        </div>
    }

    return c;
  }

  const shouldRenderMobileInfo = () => {
    return props.isMobile;
  }

  const renderMobileInfo = (categoryCell, subCategoryCell, updatedDateCell, statusCell) => {
    return (
      <React.Fragment>
        {renderExpandedInfo("Category", <TagCell cellId={categoryCell.id} cellValue={categoryCell.value} subCategoryCell={subCategoryCell} />, "noBorder")}
        {renderExpandedInfo("Status", upperCaseFirstLetter(statusCell.value), "cellDateText " + determineStatusCellColor(statusCell.value))}
        {statusCell.value !== 'published' && updatedDateCell.value &&
          renderExpandedInfo(generateUpdateCellText(statusCell.value) + " Date", displayDate(updatedDateCell.value), "expandedCategoryText")
        }
      </React.Fragment>
    )
  }

  const shouldRenderPublishedDateInfo = (cellStatus) => {
    if (props.isMobile) {
      return true;
    } else {
      return cellStatus !== 'published';
    }
  }

  const renderPublishedDateInfo = (publishedDateCell) => {
    return (renderExpandedInfo("Published Date", displayDate(publishedDateCell.value), "expandedCategoryText"));
  }

  const shouldRenderScheduledInfo = (enabledEventTimeRange, startCell, endCell) => {
    return enabledEventTimeRange && (enabledEventTimeRange.value !== false && startCell.value && endCell.value);
  }

  const renderScheduledInfo = (startCell, endCell) => {
    return (renderExpandedInfo("Scheduled For",
      <React.Fragment>
        <strong>{displayDate(startCell.value)}</strong> to <strong>{displayDate(endCell.value)}</strong>
      </React.Fragment>,
      "expandedCategoryText"));
  }

  const shouldRenderMonitorsAffectedInfo = (filteredMonitorsAffectedArray) => {
    return filteredMonitorsAffectedArray[0] && filteredMonitorsAffectedArray[0].monitorName && filteredMonitorsAffectedArray[0].monitorValueDuringEvent;
  }

  const renderMonitorsAffectedInfo = (filteredMonitorsAffectedArray) => {
    return (renderExpandedInfo("Monitors Affected",
      filteredMonitorsAffectedArray.map(item => (
        <div key={item.monitorName}>
          Monitor: <strong>{item.monitorName}</strong>,
          Monitor Status: <strong>{item.monitorValueDuringEvent}</strong>
          <br />
        </div>
      )), "expandedCategoryText"));
  }

  const shouldRenderRegionsInfo = () => {
    return props.isRegionsEmpty;
  }

  const renderRegionsInfo = (instanceCell) => {
    return (renderExpandedInfo("Regions", instanceCell.value, "expandedCategoryText"));
  }

  const renderMessageInfo = (bodyCell) => {
    return (renderExpandedInfo("Message", parse(cleanHTML(bodyCell.value)), "expandedCategoryText"));
  }

  const generateUpdateCellText = (cellStatus) => {
    return cellStatus.charAt(0).toUpperCase() + cellStatus.slice(1);
  }

  if (props.loadCommunicationStatus === SUBMISSION_STATUS_SUBMITTED) {
    return (
      <div className="dataTableSkeletonComponent">
        <SkeletonPlaceholder />
      </div>);
  } else {
    return (
      <React.Fragment>
        <DataTable
          headers={props.headers}
          rows={props.displayCommunications}
          sortRow={customSort}
          render={({ rows, headers, getHeaderProps, getRowProps }) => {
            rows = rows.slice((pageNumber - 1) * pageSize, (pageNumber) * pageSize);

            return (
              <TableContainer>
                <CommunicationsToolbar
                  filteredCommunications={props.filteredCommunications}
                  isNotificationData={props.isNotificationData}
                  pageSize={pageSize}
                  searchValue={props.searchValue}
                  initialDateFilter={props.initialDateFilter}
                  initialTimeFilter={props.initialTimeFilter}
                  finalDateFilter={props.finalDateFilter}
                  finalTimeFilter={props.finalTimeFilter}
                  statusFilters={props.statusFilters}
                  categoryFilters={props.categoryFilters}
                  subCategoryFilters={props.subCategoryFilters}
                  updateInitialDateFilter={props.updateInitialDateFilter}
                  updateInitialTimeFilter={props.updateInitialTimeFilter}
                  updateFinalDateFilter={props.updateFinalDateFilter}
                  updateFinalTimeFilter={props.updateFinalTimeFilter}
                  updateStatusFiltersControl={props.updateStatusFiltersControl}
                  updateCommunicationSearchValue={props.updateCommunicationSearchValue} />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableExpandHeader />
                      {headers.map(header => (renderHeader(getHeaderProps, header)))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <React.Fragment key={row.id}>
                        <TableExpandRow {...getRowProps({ row })} className="notificationsTableExpandRow">
                          {row.cells.map(cell => (
                            renderCell(row, cell)
                          ))}
                        </TableExpandRow>
                        {row.isExpanded && (
                          <TableExpandedRow>
                            <TableCell className="expandedRow" colSpan={headers.length + 1}>
                              <div>
                                {row.cells.map(cell => (renderCellExpanded(row, cell)))}
                              </div>
                            </TableCell>
                          </TableExpandedRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }}>
        </DataTable>
        <PaginationV2
          id='paginationV2'
          className='pagination'
          onChange={({ page, pageSize }) => {
            setPageNumber(page);
            setPageSize(pageSize);
          }}
          pageSize={pageSize}
          pageSizes={[10, 20, 30, 40, 50]}
          page={pageNumber}
          pageInputDisabled={false}
          totalItems={props.displayCommunications.length}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    isRegionsEmpty: state.communications.isRegionsEmpty
  }), {
}
)(CommunicationsDataTable);