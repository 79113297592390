import React from 'react';
import { connect } from 'react-redux';

import { Tabs, Tab } from 'carbon-components-react';
import { updateSelectedServiceType } from "../../reducers/UserReducer";

const TabsArea = (props) => {
  const mapTabs = () => {
    return props.envSchema.serviceTypes.map((serviceType, i) =>
      <Tab
        key={"tab" + serviceType.name + i}
        label={serviceType.name}
        role="presentation"
        style={{ fontSize: '18px' }}
        onClick={() => props.updateSelectedServiceType(i)} />
    );
  }

  return (
    <Tabs
      ariaLabel="listbox"
      iconDescription="show serviceType options"
      onSelectionChange={function noRefCheck() { }}
      role="navigation"
      selected={0}>
      {mapTabs()}
    </Tabs>
  );
}


export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema
  }),
  {
    updateSelectedServiceType
  }
)(TabsArea);