import React from 'react';

export const BUTTON_COLOR = '#3D70B2';

export const DownloadIcon = ({
  className = "calendar-icon",
  height = "16",
  width = "16",
  viewBox = "-1 2 16 16",
  color = "#FFF"
}) => (
  <svg className={className} fillRule="evenodd" fill={color} width={width} height={height} viewBox={viewBox}>
    <path d="M8 9V2H6.1v7L3.5 6.5 2 8l5 5 5-5-1.5-1.5L8 9z"></path>
    <path d="M13 12v3H1v-3h-2v6h16v-6h-2z"></path>
  </svg>
);

//These ones are programatically generated
export const generateDownloadIcon = (color) => {
  return (
    <svg className="graph-ball-icon" fillRule="evenodd" fill={color} width="16" height="16" viewBox="-1 2 16 16">
      <path d="M8 9V2H6.1v7L3.5 6.5 2 8l5 5 5-5-1.5-1.5L8 9z"></path>
      <path d="M13 12v3H1v-3h-2v6h16v-6h-2z"></path>
    </svg>
  );
}

export const generateAutoZoomIcon = (color) => {
  return (
    <svg className="graph-auto-zoom-icon" fillRule="evenodd" fill={color} width="16" height="16" viewBox="0 0 32 32">
      <path d="M21.4479,20A10.856,10.856,0,0,0,24,13,11,11,0,1,0,13,24a10.856,10.856,0,0,0,7-2.5521L27.5859,29,29,27.5859ZM13,22a9,9,0,1,1,9-9A9.01,9.01,0,0,1,13,22Z"></path>
      <path d="M10 12H8V10a2.0023 2.0023 0 012-2h2v2H10zM18 12H16V10H14V8h2a2.0023 2.0023 0 012 2zM12 18H10a2.0023 2.0023 0 01-2-2V14h2v2h2zM16 18H14V16h2V14h2v2A2.0023 2.0023 0 0116 18z"></path>
    </svg>
  )
}

export const generateResetZoomIcon = (color) => {
  return (
    <svg className="graph-reset-zoom-icon" fillRule="evenodd" fill={color} width="16" height="16" viewBox="0 0 32 32">
      <path d="M22.4478,21A10.855,10.855,0,0,0,25,14,10.99,10.99,0,0,0,6,6.4658V2H4v8h8V8H7.332a8.9768,8.9768,0,1,1-2.1,8H3.1912A11.0118,11.0118,0,0,0,14,25a10.855,10.855,0,0,0,7-2.5522L28.5859,30,30,28.5859Z"></path>
    </svg>
  );
}

export const generateChartInfoIcon = (color) => {
  return (
    <svg className="graph-chart-info-icon" fillRule="evenodd" fill={color} width="16" height="16" viewBox="0 0 32 32">
      <path d="M17 22L17 13 13 13 13 15 15 15 15 22 12 22 12 24 20 24 20 22 17 22zM16 7a1.5 1.5 0 101.5 1.5A1.5 1.5 0 0016 7z"></path>
      <path d="M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z"></path>
    </svg>
  );
}