import * as zoom from 'chartjs-plugin-zoom'

import * as annotations from "chartjs-plugin-annotation"

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const generateChartId = (selectedMonitorAssetNumber, chartName) => {
  return selectedMonitorAssetNumber + chartName.replace(/\s/g, '');
}

export const createOptions = (data, overridenThresholdChartIndex, minYAxisValue) => {
  if (data) {
    let shouldChartBeModified = overridenThresholdChartIndex < 0 ? false : data.chartIndex === overridenThresholdChartIndex;
    let maxValue = shouldChartBeModified ? calculateMaxValue(data.datasets) : Math.max(calculateMaxValue(data.datasets), data.threshold);
    
    let result = {
      responsive: true,
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                minute: 'h:mm a',
                hour: 'MM/DD/YYYY HH:mm',
                day: 'MM/DD/YYYY'
              }
            },
            ticks: {
              minRotation: 30,
              min: data.labels[0],
              max: data.labels[data.labels.length - 1]
            }
          }
        ],
        yAxes: [{
          ticks: {
            maxTicksLimit: 8,
            min: minYAxisValue,
            max: formatNumber(confirmMaxValue(maxValue), true),
            callback: function(label) {
              return presentValue(label, data.formatType);
            }
          }
        }]
      },
      legend: {
        display: false
      },
      pan: {
        enabled: true,
        mode: 'xy',
        rangeMin: {
          x: data.labels[0],
          y: 0
        },
        rangeMax: {
          x: data.labels[data.labels.length - 1]
        }
      },
      zoom: {
        enabled: true,
        mode: 'xy',
        rangeMin: {
          x: data.labels[0],
          y: 0
        },
        rangeMax: {
          x: data.labels[data.labels.length - 1]
        }
      },
      title: {
        display: true,
        text: data.name
      }
    };

    if (data.threshold) {
      result.annotation = {
        drawTime: 'afterDatasetsDraw',
        annotations: [{
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: data.threshold,
            borderColor: 'red',
            borderWidth: 1
        }]
      };
    }

    return result;
  } else {
    return {}
  }
}

const calculateMaxValue = (datasets) => {
  let dataValues = [];

  for (let index = 0; index < datasets.length; index ++) {
    dataValues = dataValues.concat(datasets[index].data);
  }

  return Math.max(...dataValues);
}

const confirmMaxValue = ( maxValue) => {
  if ((maxValue * 1.2) < 0.5) {
    return 1;
 }
 else return (maxValue * 1.2)
}

export const presentValue = (value, formatType) => {
  if (value === null) {
    return '';
  }
  if (formatType === "PERCENTAGE"){
    return formatNumber(value) + '%';
  }
  if (formatType === "TIME"){
    return formatNumber(value) + 'ms';
  }
  if (formatType === "BYTES") {
    let l = 0, number = parseInt(value, 10) || 0;
    while (number >= 1024 && ++l) {
      number = number / 1024;
    }
    return (number.toFixed(number < 10 && l > 0 ? 1 : 0)) + units[l];
    } else {
    return formatNumber(value);
  }
}

export const formatNumber = (value, isMaxValue) => {
  let formattedValue;

  if (Number.isInteger(value)) {
    formattedValue = value;
  } else if (isMaxValue) {
    formattedValue = parseInt(value);
  } else {
    formattedValue = parseFloat(value).toFixed(1);
  }

  return formattedValue;
}