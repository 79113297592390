import { headers } from "./BaseServices";

export const submitSubscription = (envSchemaId, serviceType, subscriptionAction, originatorId, data) => {
  return fetch(`/announcement/iotm/api/v1/subscription/environment/${envSchemaId}/servicetype/${serviceType}/${subscriptionAction}${originatorId}`, {
    method: 'POST',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json());
}

export const getRegionList = (group) => {
  return fetch(`/configfrontend/org/orgid/${group}/datacenter/list`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json());
}

export const requestEmailSubscriptionVerification = (iui, envID) => {
  let subscriptionVerificationBody = { iui: iui, envId: envID };

  return fetch(`announcement/iotm/api/v1/subscription/verifysubscription`, {
    method: 'POST',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(subscriptionVerificationBody)
  })
    .then(res => res.json());
}

export const getSubscriptionByUser = (group, emailId) => {
  return fetch(`/announcement/iotm/api/v1/subscription/environment/${group}/subscriber/${emailId}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json());
}

export const fetchSubscriptionCategories = (solutionId) => {
  return fetch(`/announcement/iotm/api/v1/subscriptioncategory/solutionid/${solutionId}`, {
    method: 'GET',
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json());
}