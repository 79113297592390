import React from 'react';
import { connect } from 'react-redux';

import { Tab, Tabs } from 'carbon-components-react';
import { updateSelectedTabIndex } from '../../reducers/HealthStatusReducer';
import HealthStatusLegend from './HealthStatusLegend';
import HealthStatusMonitorList from './HealthStatusMonitorList';

const MAXIMUM_AMOUNT_OF_TABS_WITHOUT_SCROLL = 4;

const HealthStatusTabComponent = (props) => {
  const shouldCreateTabs = () => {
    return props.systemTabs.length <= MAXIMUM_AMOUNT_OF_TABS_WITHOUT_SCROLL;
  }

  const createTabs = () => {
    return props.systemTabs.map((tab, i) =>
      <Tab
        key={"health-status-tab-" + i}
        label={tab}
        onClick={() => props.updateSelectedTabIndex(i)}>
        <div className="healthStatusContainer">
          <HealthStatusMonitorList />
          
          <div className="legendArea">
            <div className="legendContainer">
              <HealthStatusLegend />
            </div>
          </div>
        </div>
      </Tab>
    );
  }

  return (
    <Tabs className={shouldCreateTabs() ? 'healthStatusTab' : 'healthStatusTab healthStatusTabOnOverflow'} selected={props.selectedTabIndex}>
      {createTabs()}
    </Tabs>
  );
}

export default connect(
  (state) => ({
    systemTabs: state.healthStatus.systemTabs,
    selectedTabIndex: state.healthStatus.selectedTabIndex
  }), {
  updateSelectedTabIndex
}
)(HealthStatusTabComponent);