export const UNITS = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const createComboboxValues = (monitorNames) => {
  let result = [];

  for (let index = 0; index < monitorNames.length; index++) {
    result.push({ id: monitorNames[index].assetNum, text: monitorNames[index].monitorName });
  }

  return result;
}

export const displayNumber = (numberString) => {
  return numberString.toLocaleString();
}

export const displayDate = (dateString) => {
  let date = new Date(dateString);

  if (date.toString() === 'Invalid Date') {
    date = new Date(dateString.substring(0, 10));
  }

  let arrayString = date.toDateString().split(' ');

  arrayString.shift();

  return arrayString.join(' ');
}

export const convertBytes = (x) => {
  let l = 0, n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + UNITS[l]);
}

export const shouldShowPerformanceTablesComponent = (performanceTables, componentFlag) => {
  return performanceTables && componentFlag;
}