import tinycolor from "tinycolor2";

const INITIAL_DATASET_COLOR = 'rgb(130, 202, 157)';

export const transformData = (dataModel, initialTimeInMilliseconds, finalTimeInMilliseconds) => {
  let timeTicks;
  let maxDatasetLength = Number.NEGATIVE_INFINITY;

  let data = fetchData(dataModel);

  for (let index = 0; index < data.length; index++) {
    for (let indexJ = 0; indexJ < data[index].datasets.length; indexJ++) {
      data[index].chartIndex = index;

      timeTicks = (finalTimeInMilliseconds - initialTimeInMilliseconds) / (data[index].datasets[indexJ].data.length - 1);

      if (data[index].datasets[indexJ].data.length > maxDatasetLength) {
        maxDatasetLength = data[index].datasets[indexJ].data.length;
        data[index].labels = [];

        for (let indexK = 0; indexK < data[index].datasets[indexJ].data.length; indexK++) {
          data[index].labels.push(initialTimeInMilliseconds + (timeTicks * indexK));
        }
      }
    }

    maxDatasetLength = Number.NEGATIVE_INFINITY;
  }

  return data;
}

const fetchData = (dataModels) => {
  let result = [];

  for (let index = 0; index < dataModels.length; index++) {
    result.push({
      name: dataModels[index].name,
      type: dataModels[index].type,
      notUpdated: dataModels[index].notUpdated,
      labels: [],
      maxOrder: null,
      minOrder: null,
      formatType: dataModels[index].formatType,
      displayMinColumn: dataModels[index].displayMinColumn,
      currentOrder: true,
      averageOrder: null,
      threshold: dataModels[index].threshold,
      description: dataModels[index].description,
      datasets: createDatasets(dataModels[index].datasets, dataModels[index].asc, dataModels[index].type, dataModels[index].formatType, dataModels[index].displayMinColumn)
    });
  }

  return result;
}

const createDatasets = (datasetsModels, ascendingOrder, type, formatType, displayMinColumn) => {
  let result = [];

  let arrayOfColors = createArrayOfColors(datasetsModels.length);

  for (let index = 0; index < datasetsModels.length; index++) {
    result.push({
      label: datasetsModels[index].label,
      fill: true,
      lineTension: 0.1,
      backgroundColor: arrayOfColors[index].background,
      borderColor: arrayOfColors[index].border,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderWidth: 1,
      borderJoinStyle: "miter",
      pointBorderColor: arrayOfColors[index].border,
      pointBackgroundColor: arrayOfColors[index].background,
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: arrayOfColors[index].background,
      pointHoverBorderColor: arrayOfColors[index].border,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: type === 'TABLE' ? [datasetsModels[index].data[0]] : datasetsModels[index].data,
      hidden: false,
      formatType,
      displayMinColumn: displayMinColumn
    });
  }

  if (ascendingOrder) {
    result.sort((a, b) => (a.data[a.data.length - 1] > b.data[b.data.length - 1]) ? 1 : -1);
  } else {
    result.sort((a, b) => (a.data[a.data.length - 1] < b.data[b.data.length - 1]) ? 1 : -1);
  }

  return result;
}

const createArrayOfColors = (numberOfLines) => {
  let color;
  let result = [];
  let angle = 360 / numberOfLines;
  let base = tinycolor(INITIAL_DATASET_COLOR);

  for (let index = 0; index < numberOfLines; index++) {
    color = base.spin(angle);

    result.push({ border: color.toRgbString() });

    color.setAlpha(0.4);

    result[index].background = color.toRgbString();
  }

  return result.reverse();
}