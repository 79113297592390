import React from 'react';
import { connect } from 'react-redux';

import {
  ComposedModal,
  ModalHeader,
  ModalBody
} from 'carbon-components-react';

import Banner from '../Banner/Banner';

import StatisticsChartButtonsRow from './StatisticsChartButtonsRow';
import StatisticsChartsFilterArea from './StatisticsChartsFilterArea';
import StatisticsChartsMonitorAccordion from './StatisticsChartsMonitorAccordion';

const StatisticsChartsModal = (props) => {
  const createModalBody = () => {
    return (
      <React.Fragment>
        <Banner />
        <StatisticsChartButtonsRow />
        {props.timeControlAreaOpened && <StatisticsChartsFilterArea isDesktop={true} />}

        <StatisticsChartsMonitorAccordion
          isModal={true}
          isStatisticsChartsModalOpen={props.isStatisticsChartsModalOpen}
          chartVisibilityControl={props.chartVisibilityControl} />
      </React.Fragment>
    );
  }

  return (
    <div className="desktopOnly">
      <ComposedModal
        open={props.isStatisticsChartsModalOpen}
        onClose={() => props.closeModal(false)}>
        <ModalHeader
          title='Performance Statistics'
          iconDescription='Close Modal'
          buttonOnClick={() => props.closeModal(false)} />
        <ModalBody className="modalBody scrollY">
          {createModalBody()}
        </ModalBody>
      </ComposedModal>
    </div>
  );
}

export default connect(
  (state) => ({
    timeControlAreaOpened: state.statisticCharts.timeControlAreaOpened
  }), {
}
)(StatisticsChartsModal);