import React from 'react';
import { connect } from 'react-redux';

import { RadioButton } from 'carbon-components-react';
import { BANNER_CONTENT_SWITCHER_VALUES } from '../CommunicationsHelper';
import { clearSubcategoriesFilters, updateCategoryFiltersControl } from '../../../reducers/CommunicationsReducer';

const CommunicationsToolbarCategoryButton = (props) => {
  return (
    <RadioButton
      key={props.key}
      className="radioButton"
      labelText={props.item}
      checked={props.categoryFilters[BANNER_CONTENT_SWITCHER_VALUES.indexOf(props.item)]}
      onClick={() => {
        new Promise((resolve) => resolve(props.updateCategoryFiltersControl(BANNER_CONTENT_SWITCHER_VALUES.indexOf(props.item))))
          .then(() => {
            props.clearSubcategoriesFilters();
          })
      }} />
  );
}

export default connect(
  (state) => ({
    categoryFilters: state.communications.categoryFiltersControl
  }), {
  updateCategoryFiltersControl,
  clearSubcategoriesFilters
}
)(CommunicationsToolbarCategoryButton);