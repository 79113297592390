import React from 'react';
import { connect } from 'react-redux';

import { RadioButton } from 'carbon-components-react';
import { STATUS_CONTENT_SWITCHER_VALUES } from '../CommunicationsHelper';

const CommunicationsToolbarStatusButton = (props) => {
  console.log(props.statusFilters);

  return (
    <RadioButton
      key={props.item}
      className="radioButton"
      labelText={props.item}
      checked={props.statusFilters[STATUS_CONTENT_SWITCHER_VALUES.indexOf(props.item)]}
      onClick={() => { props.updateStatusFiltersControl(STATUS_CONTENT_SWITCHER_VALUES.indexOf(props.item)) }} />
  );
}

export default connect(
  (state) => ({
  }), {
}
)(CommunicationsToolbarStatusButton);