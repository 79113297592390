export const sortNotifications = (a, b) => {
  let dateA = a.updateDate ? a.updateDate : a.realDate;
  let dateB = b.updateDate ? b.updateDate : b.realDate;

  return new Date(dateB) - new Date(dateA);
}

export const sortAlerts = (a, b) => {
  let statusOrder = {
    published: 0,
    updated: 1,
    resolved: 2,
    closed: 3
  };

  let statusComparison = statusOrder[a.status] - statusOrder[b.status];
  if (statusComparison !== 0) {
    return statusComparison;
  }

  let dateA = a.updateDate ? a.updateDate : a.realDate;
  let dateB = b.updateDate ? b.updateDate : b.realDate;

  return new Date(dateB) - new Date(dateA);
}

export const determineStatusCellColor = (status) => {
  switch (status) {
    case 'published':
      return 'boldFormat green'
    case 'updated':
      return 'boldFormat blue';
    case 'resolved':
      return 'boldFormat orange';
    case 'closed':
      return 'boldFormat purple';
    default:
      return 'boldFormat';
  }
}

export const STATUS_CONTENT_SWITCHER_VALUES = [
  'Published',
  'Updated',
  'Resolved',
  'Closed'
];

export const BANNER_CONTENT_SWITCHER_VALUES = [
  'Announcement',
  'Maintenance',
  'Incident',
  'Security'
];

export const CALENDAR_CONTENT_SWITCHER_VALUES = [
  'Announcement',
  'Maintenance',
  'Incident',
  'Security',
  'Alert'
];