import React from 'react';

import { InlineNotification } from 'carbon-components-react';

const SubscriptionAlertInlineNotification = (props) => {
  return (
    <InlineNotification
      className='alertWarning'
      kind='warning'
      role='alert'
      iconDescription='You are not subscribed to this environment warning'
      title='You are not currently subscribed to receive e-mail notifications!'
      subtitle='Please select the Subscription button and subscribe to receive e-mails for published and updated notices.'
      onCloseButtonClick={() => props.setIsModalOpen(true)} />
  );
}

export default SubscriptionAlertInlineNotification;