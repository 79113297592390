export const reduxUpdate = ({payload, filtered, type, text}) => ({type, payload, filtered, text});

export const findIndexes = (rows, searchKeys, collectionId, text) => {
    console.log("findIndexes.rows", rows); 
    if (!rows || !rows.length) {
        return undefined
    }
    if (!searchKeys) {
        //let k = rows.pop(); 
        //console.log("findIndexes.rows.pop()", k);
        searchKeys = Object.keys(rows[0])
        console.log("searchKeys", searchKeys);
    }
    if (!collectionId) {
        collectionId = 'id'
    }
    const re = new RegExp(`.*(${text}).*`, 'i')
    const result = rows.filter( (row) => {
        return searchKeys.find((key) => re.exec(row[`${key}`]))
      })
      .map( (row) => row[`${collectionId}`])
    return new Set(result)
}

export const searchOnRows = ({rows, searchKeys, collectionId, text, reduxTargetAction}) => {
    return (dispatch) => {
        let indexes = findIndexes(rows, searchKeys, collectionId, text)
        dispatch(reduxUpdate({collectionId, payload: indexes, type: reduxTargetAction, text}));
    }
}