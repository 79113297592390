import { isManualMonitoring } from '../components/Helper/ComponentHelper';
import { fetchMonitorData } from '../lib/HealthStatusServices';
import {
  SUBMISSION_STATUS_INITIAL_STATUS,
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_SUCCESS,
  SUBMISSION_STATUS_SUBMITTED
} from '../reducers/BaseReducer';

const initState = {
  monitorData: [],
  systemTabs: [],
  selectedTabIndex: 0,
  datacentersInfoArray: [],
  serviceByCategoryMapArray: [],
  hasCat4Property: false,

  loadMonitorStatus: SUBMISSION_STATUS_INITIAL_STATUS
}

const HEALTH_STATUS_LOAD_MONITOR_DATA = 'HEALTH_STATUS_LOAD_MONITOR_DATA';
export const loadMonitorDataAction = (newData) => ({ type: HEALTH_STATUS_LOAD_MONITOR_DATA, payload: newData });

const HEALTH_STATUS_UPDATE_SYSTEM_TABS = 'HEALTH_STATUS_UPDATE_SYSTEM_TABS';
export const updateSystemTabsAction = (newValue) => ({ type: HEALTH_STATUS_UPDATE_SYSTEM_TABS, payload: newValue });

const HEALTH_STATUS_UPDATE_SELECTED_TAB_INDEX = 'HEALTH_STATUS_UPDATE_SELECTED_TAB_INDEX';
export const updateSelectedTabIndexAction = (newValue) => ({ type: HEALTH_STATUS_UPDATE_SELECTED_TAB_INDEX, payload: newValue });

const HEALTH_STATUS_UPDATE_DATACENTERS_INFO_ARRAY = 'HEALTH_STATUS_UPDATE_DATACENTERS_INFO_ARRAY';
export const updateDatacentersInfoArrayAction = (newValue) => ({ type: HEALTH_STATUS_UPDATE_DATACENTERS_INFO_ARRAY, payload: newValue });

const HEALTH_STATUS_UPDATE_SERVICE_BY_CATEGORY_MAP_ARRAY = 'HEALTH_STATUS_UPDATE_SERVICE_BY_CATEGORY_MAP_ARRAY';
export const updateServiceByCategoryMapArrayAction = (newValue) => ({ type: HEALTH_STATUS_UPDATE_SERVICE_BY_CATEGORY_MAP_ARRAY, payload: newValue });

const HEALTH_STATUS_UPDATE_HAS_CAT_4_PROPERTY = 'HEALTH_STATUS_UPDATE_HAS_CAT_4_PROPERTY';
export const updateHasCat4PropertyAction = (newValue) => ({ type: HEALTH_STATUS_UPDATE_HAS_CAT_4_PROPERTY, payload: newValue });

const HEALTH_STATUS_UPDATE_LOAD_MONITOR_STATUS = 'HEALTH_STATUS_UPDATE_LOAD_MONITOR_STATUS';
export const updateLoadMonitorStatus = (newLogoutStatus) => ({ type: HEALTH_STATUS_UPDATE_LOAD_MONITOR_STATUS, payload: newLogoutStatus });

export const loadMonitorData = (orgId, parentSolution) => {
  return (dispatch) => {
    let org = (isManualMonitoring(parentSolution) ? parentSolution : null);

    dispatch(updateLoadMonitorStatus(SUBMISSION_STATUS_SUBMITTED));
    dispatch(loadMonitorDataAction([]));

    fetchMonitorData(orgId, org)
      .then(data => {
        if (Array.isArray(data)) {
          dispatch(updateLoadMonitorStatus(SUBMISSION_STATUS_SUCCESS));

          dispatch(loadMonitorDataAction(data));
        } else {
          dispatch(loadMonitorDataAction([]));
        }
      })
      .catch(error => {
        dispatch(updateLoadMonitorStatus(SUBMISSION_STATUS_FAILED));
        dispatch(loadMonitorDataAction([]));
      });
  }
}

export const updateSystemTabs = (value) => {
  return (dispatch) => {
    dispatch(updateSystemTabsAction(value));
  }
}

export const updateSelectedTabIndex = (value) => {
  return (dispatch) => {
    dispatch(updateSelectedTabIndexAction(value));
  }
}

export const updateDatacentersInfoArray = (value) => {
  return (dispatch) => {
    dispatch(updateDatacentersInfoArrayAction(value));
  }
}

export const updateServiceByCategoryMapArray = (value) => {
  return (dispatch) => {
    dispatch(updateServiceByCategoryMapArrayAction(value));
  }
}
export const updateHasCat4Property = (value) => {
  return (dispatch) => {
    dispatch(updateHasCat4PropertyAction(value));
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case HEALTH_STATUS_LOAD_MONITOR_DATA:
      return { ...state, monitorData: action.payload }

    case HEALTH_STATUS_UPDATE_SYSTEM_TABS:
      return { ...state, systemTabs: action.payload }

    case HEALTH_STATUS_UPDATE_SELECTED_TAB_INDEX:
      return { ...state, selectedTabIndex: action.payload }

    case HEALTH_STATUS_UPDATE_DATACENTERS_INFO_ARRAY:
      return { ...state, datacentersInfoArray: action.payload }

    case HEALTH_STATUS_UPDATE_SERVICE_BY_CATEGORY_MAP_ARRAY:
      return { ...state, serviceByCategoryMapArray: action.payload }

    case HEALTH_STATUS_UPDATE_HAS_CAT_4_PROPERTY:
      return { ...state, hasCat4Property: action.payload }

    case HEALTH_STATUS_UPDATE_LOAD_MONITOR_STATUS:
      return { ...state, loadMonitorStatus: action.payload }

    default:
      return state;
  }
}
