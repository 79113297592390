import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-react';
import { CSVLink } from 'react-csv';
import { replaceHTMLTags } from '../Helper/ComponentHelper';
import { updateIsDownloadCommunicationsModalOpen } from '../../reducers/CommunicationsReducer';
import { DOWNLOAD_HEADERS } from './HeaderHelper';

const CommunicationsDownloadModal = (props) => {
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    if (props.isDownloadCommunicationsModalOpen) {
      setDownloadData(treatData([...props.data]))
    }
  }, [props.data, props.isDownloadCommunicationsModalOpen]);

  const treatData = (rawData) => {
    rawData.forEach((notification) => {
      if (notification.body !== undefined && notification.body !== "") {
        notification.body = notification.body.replace(/"/g, "'");
        notification.body = replaceHTMLTags(notification.body);
      }
    });

    return rawData;
  }

  const createModalBody = () => {
    return (
      <div>
        <p>
          <span>You will now download all of the {props.isNotificationData ? 'notifications' : 'alerts'} presented in this data table.</span>
          <span> It's important to understand that the filters you selected will be taken in consideration, so, if you wish to download the totality of the notifications of this organization, please, clear the filter before proceeding.</span>
          <br />
          <br />
          <span>Do you wish to proceed?</span>
        </p>
      </div>
    );
  }

  return (
    <ComposedModal
      open={props.isDownloadCommunicationsModalOpen}
      onClose={() => props.updateIsDownloadCommunicationsModalOpen(false)}>
      <ModalHeader
        title='Download Warning'
        iconDescription='Download Modal'
        buttonOnClick={() => props.updateIsDownloadCommunicationsModalOpen(false)} />
      <ModalBody>
        {createModalBody()}
      </ModalBody>
      <ModalFooter>
        <Button
          kind="secondary"
          onClick={() => props.updateIsDownloadCommunicationsModalOpen(false)}>
          Cancel
        </Button>
        <CSVLink
          data={downloadData}
          headers={DOWNLOAD_HEADERS}
          filename={props.isNotificationData ? `notification-information-${props.envSchema.id}.csv` : `alert-information-${props.envSchema.id}.csv`}
          className="downloadCSV"
          target="_blank">
          <Button
            className="downloadButton"
            onClick={() => props.updateIsDownloadCommunicationsModalOpen(false)}>
            {props.isNotificationData ? 'Yes, Download the Notifications' : 'Yes, Download the Alerts'}
          </Button>
        </CSVLink>
      </ModalFooter>
    </ComposedModal>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    isDownloadCommunicationsModalOpen: state.communications.isDownloadCommunicationsModalOpen
  }), {
  updateIsDownloadCommunicationsModalOpen
}
)(CommunicationsDownloadModal);