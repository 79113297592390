import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetStatus, handleSubscription, fetchNotificationAvailableRegions, requestSubscriptionCategories, updateIsSubscriptionModalOpenFlag, resetEmailData, updateAllowEmptyEmail, updateSelectedSubscriptionCategories } from '../../../reducers/SubscriptionReducer';
import { fetchSolutionsType } from '../../../reducers/DashboardsInfoReducer';

import {
  SUBMISSION_STATUS_FAILED,
  SUBMISSION_STATUS_SUCCESS,
  SUBMISSION_STATUS_SUBMITTED
} from '../../../reducers/BaseReducer';

import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'carbon-components-react';

import EmailSubscriptionModalBody from './EmailSubscriptionModalBody';
import { determineInvalidEmail, SUBSCRIPTION_OPTIONS } from './SubscriptionHelper';

class EmailSubscriptionModal extends Component {
  componentDidMount = () => {
    if (this.props.envSchema.id) {
      this.props.fetchNotificationAvailableRegions(this.props.envSchema.id);
      this.props.fetchSolutionsType(this.props.envSchema.id);
    }

    if (this.props.envSchema.solution) {
      this.props.requestSubscriptionCategories(this.props.envSchema.solution);
    } else if (this.props.envSchema.id) {
      this.props.requestSubscriptionCategories(this.props.envSchema.id);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.envSchema.id !== this.props.envSchema.id) {
      this.props.fetchNotificationAvailableRegions(this.props.envSchema.id);
      this.props.fetchSolutionsType(this.props.envSchema.id);

      if (this.props.envSchema.solution) {
        this.props.requestSubscriptionCategories(this.props.envSchema.solution);
      } else if (this.props.envSchema.id) {
        this.props.requestSubscriptionCategories(this.props.envSchema.id);
      }
    }

    if ((prevProps.checkSubscriptionStatus === SUBMISSION_STATUS_SUBMITTED) && (prevProps.checkSubscriptionStatus !== this.props.checkSubscriptionStatus) ||
      (prevProps.subscriptionStatus === SUBMISSION_STATUS_SUBMITTED) && (prevProps.subscriptionStatus !== this.props.subscriptionStatus)) {
      this.setState({ shouldReloadSubscriptionCategoriesMultiSelectors: true });
    }

    if (!prevProps.isModalOpen && this.props.isModalOpen) {
      if (this.props.subscriptionCategories.length > 0 && this.props.envSchema.subscriptionCategories.length > 0) {
        this.props.updateSelectedSubscriptionCategories(this.filterSubscriptionCategories(this.props.subscriptionCategories, this.props.envSchema.subscriptionCategories));
      } else {
        this.props.updateSelectedSubscriptionCategories([...this.props.subscriptionCategories]);
      }
    }
  }

  componentWillUnmount = () => {
    this.props.resetEmailData();
  }

  filterSubscriptionCategories = (originalCategories, orgCategories) => {
    let orgParentCategoryIds = orgCategories.map(orgCat => orgCat.parentCategoryId);
    let orgAttributeCategoryIds = orgCategories.map(orgCat => orgCat.attributeId);
    let filteredByParentCategoryId = originalCategories.filter(cat => orgParentCategoryIds.includes(cat.categoryId));

    for (let index = 0; index < filteredByParentCategoryId.length; index++) {
      filteredByParentCategoryId[index].categoryAttributes =
        filteredByParentCategoryId[index].categoryAttributes.filter(attr => orgAttributeCategoryIds.includes(attr.attributeId));
    }

    return filteredByParentCategoryId;
  }

  determineInvalidCategories = () => {
    return this.props.selectedCategories.length === 0;
  }

  checkBeforeSubmitting = () => {
    if (this.props.allowEmptyEmail) {
      if (new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/).test(this.props.selectedEmail.toLowerCase())) {
        this.props.handleSubscription(this.props.selectedEmail, this.prepareSubscriptionAction(), this.prepareCategories(),
          this.props.subscriptionCategories, this.props.selectedRegion);
      }
      this.props.updateAllowEmptyEmail(false);
    } else if (this.props.subscriptionStatus === SUBMISSION_STATUS_FAILED) {
      this.props.resetStatus();
    } else if (this.props.subscriptionStatus === SUBMISSION_STATUS_SUCCESS) {
      this.closeModal();
    } else {
      this.props.handleSubscription(this.props.selectedEmail, this.prepareSubscriptionAction(), this.prepareCategories(),
        this.props.subscriptionCategories, this.props.selectedRegion);
    }
  }

  prepareSubscriptionAction = () => {
    if (this.props.selectedSubscriptionOption === SUBSCRIPTION_OPTIONS[0]) {
      return 'subscribe';
    } else {
      return 'unsubscribe'
    }
  }

  prepareCategories = () => {
    return this.props.selectedCategories.map(selectedCategory => selectedCategory.id);
  }

  createButtonText = () => {
    if (this.props.subscriptionStatus === SUBMISSION_STATUS_SUCCESS || this.props.subscriptionStatus === SUBMISSION_STATUS_FAILED) {
      return 'Close';
    } else if (this.props.selectedSubscriptionOption === SUBSCRIPTION_OPTIONS[0]) {
      return 'Subscribe';
    } else if (this.props.selectedSubscriptionOption === SUBSCRIPTION_OPTIONS[1]) {
      return 'Unsubscribe';
    } else {
      return '';
    }
  }

  closeModal = () => {
    this.props.updateIsSubscriptionModalOpenFlag(false);

    this.props.resetStatus();
  }

  render() {
    return (
      <ComposedModal
        open={this.props.isModalOpen}
        onClose={() => this.closeModal()}>
        <ModalHeader
          title='E-mail subscription management'
          iconDescription='Close Modal'
          buttonOnClick={() => this.closeModal()} />
        <ModalBody className="modalBodyWithOverflow">
          <EmailSubscriptionModalBody />
        </ModalBody>
        <ModalFooter>
          {this.props.subscriptionStatus !== SUBMISSION_STATUS_SUCCESS && this.props.subscriptionStatus !== SUBMISSION_STATUS_FAILED && <Button
            className="formItems"
            kind="secondary"
            onClick={() => this.closeModal()}>
            Close
          </Button>}
          <Button
            className="formItems"
            disabled={this.determineInvalidCategories() || determineInvalidEmail(this.props.allowEmptyEmail, this.props.selectedEmail)}
            onClick={() => this.checkBeforeSubmitting()}>
            {this.createButtonText()}
          </Button>
        </ModalFooter>
      </ComposedModal>
    );
  }
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    userEmail: state.user.userEmail,
    selectedEmail: state.subscription.selectedEmail,
    allowEmptyEmail: state.subscription.allowEmptyEmail,
    selectedCategories: state.subscription.selectedCategories,
    subscriptionCategories: state.subscription.subscriptionCategories,
    selectedSubscriptionOption: state.subscription.selectedSubscriptionOption,
    selectedRegion: state.subscription.selectedRegion,
    subscriptionStatus: state.subscription.subscriptionStatus,
    isModalOpen: state.subscription.isSubscriptionModalOpen,
    availableRegions: state.subscription.availableRegionsForSubscriptions,
    checkSubscriptionStatus: state.subscription.checkSubscriptionStatus
  }), {
  handleSubscription,
  updateAllowEmptyEmail,
  resetStatus,
  resetEmailData,
  fetchNotificationAvailableRegions,
  updateIsSubscriptionModalOpenFlag,
  requestSubscriptionCategories,
  updateSelectedSubscriptionCategories,
  fetchSolutionsType
}
)(EmailSubscriptionModal);