import 'carbon-components/scss/globals/scss/styles.scss';

import React, { useEffect } from 'react';
import { loadUserGroup, loadOrg, loadLoginUserId, loadUserEmail } from '../../reducers/UserReducer';

import { Loading } from 'carbon-components-react';
import PageHeader from '../App/PageHeader';
import PageFooter from '../App/PageFooter';
import { connect } from 'react-redux';

import Dashboard from '../Dashboard/Dashboard';

import { useParams } from "react-router-dom";

import { SUBMISSION_STATUS_INITIAL_STATUS, SUBMISSION_STATUS_SUBMITTED } from '../../reducers/BaseReducer';

const InitialPage = (props) => {
  let { customerId } = useParams();

  useEffect(() => {
    props.loadUserGroup();
    props.loadOrg();
    props.loadLoginUserId();
    props.loadUserEmail();
  }, []);

  useEffect(() => {
    if (props.currentAccess.headerMessage) {
      document.title = props.currentAccess.headerMessage;
    }
  }, [props.currentAccess]);

  if (props.user.authorizationSubmissionStatus === SUBMISSION_STATUS_INITIAL_STATUS
    || props.user.authorizationSubmissionStatus === SUBMISSION_STATUS_SUBMITTED) {
    return <Loading id="loadingAfterAuthenticationAndAuthorization" active={true} withOverlay={true} />
  }

  return (
    <div className="app">
      <PageHeader />
      <main id="content" className="app-main-content">
        <Dashboard customerId={customerId} />
      </main>
      <PageFooter />
    </div>
  );
}

export default connect(
  (state) => ({
    user: state.user,
    currentAccess: state.user.environmentSchema
  }),
  {
    loadUserGroup,
    loadOrg,
    loadLoginUserId,
    loadUserEmail
  }
)(InitialPage);