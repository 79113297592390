import React from 'react';

import { Button, ComposedModal, ModalBody, ModalFooter, ModalHeader } from 'carbon-components-react';

const SubscriptionAlertModal = (props) => {
  return (
    <ComposedModal
      open={props.isModalOpen}
      onClose={() => props.setIsModalOpen(false)}>
      <ModalHeader
        title='Warning'
        iconDescription='Close Modal'
        buttonOnClick={() => props.setIsModalOpen(false)} />
      <ModalBody>
        <div>You just closed the subscription message. It will keep reappearing if you view an unsubscribed environment again.</div>
        <br />
        <div>Do you wish to stop receiving these warnings in the future?</div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="formItems"
          onClick={() => props.cancelSubscriptionWarning()}>
          Yes, never show me this warning again
        </Button>
      </ModalFooter>
    </ComposedModal>
  );
}

export default SubscriptionAlertModal;