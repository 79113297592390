import React, { useState } from 'react';
import { connect } from 'react-redux';

import { OverflowMenu, OverflowMenuItem, Loading, Button, ComposedModal, ModalHeader, ModalFooter, ModalBody } from 'carbon-components-react';

import './components.css';

const DEFAULT_BACKGROUND_COLOR = '#152935';

const PageHeader = (props) => {
  const [logoutHasBeenPressed, setLogoutHasBeenPressed] = useState(false);
  const [isCompleteLogoutModalOpen, setIsCompleteLogoutModalOpen] = useState(false);

  const getBackgroundColor = () => {
    if (hasHeaderProps() && props.envSchema.header.backgroundcolor) {
      return props.envSchema.header.backgroundcolor;
    } else {
      return DEFAULT_BACKGROUND_COLOR;
    }
  }

  const getTitleStrongMessage = () => {
    if (props.isLoginFailurePage) {
      return 'CCC:';
    } else if (hasHeaderProps()) {
      return props.envSchema.header.strongmsg;
    }
  }

  const getTailMessage = () => {
    if (props.isLoginFailurePage) {
      return ' Client Communication Center';
    } else if (hasHeaderProps()) {
      return props.envSchema.header.tailmsg;
    }
  }

  const getLinks = () => {
    if (props.envSchema.header) {
      return props.envSchema.header.hyperlinks.map(hyperlink =>
        <li key={hyperlink.title}>
          <a
            className='headerLink'
            title={hyperlink.title}
            target="_blank"
            onClick={() => hyperlink.isLogoutButton ? onLogoutClick() : {}}
            href={hyperlink.hyperlink}>
            {hyperlink.desc}
          </a>
        </li>
      );
    }
  }

  const createOverflowMenu = () => {
    return (
      <OverflowMenu
        className='headerOverflowMenu'
        flipped={true}
        floatingMenu={true}>
        {createOverflowMenuItems()}
      </OverflowMenu>
    );
  }

  const createOverflowMenuItems = () => {
    if (props.envSchema.header) {
      return props.envSchema.header.hyperlinks.map((hyperlink, i) =>
        <OverflowMenuItem
          key={i}
          itemText={hyperlink.desc}
          onClick={() => hyperlink.isLogoutButton ? onLogoutClick() : openInNewTabWithoutOpener(hyperlink.hyperlink)} />
      );
    }
  }

  const onLogoutClick = () => {
    setLogoutHasBeenPressed(false);
    callIdaasLogout();
  }

  const openInNewTabWithoutOpener = (hyperlink) => {
    var newTab = window.open();
    newTab.opener = null;
    newTab.location = hyperlink;
  }

  const callIdaasLogout = () => {
    var ibmLogout = window.open("https://idaas.iam.ibm.com/pkmslogout", '_blank');

    setTimeout(function () {
      ibmLogout.close();
      setIsCompleteLogoutModalOpen(true);
    }, 2000);
  }

  const completeLogout = () => {
    var ibmLogout = window.open("https://login.ibm.com/pkmslogout", '_blank');

    setTimeout(function () {
      ibmLogout.close();
      submitInternalLogout()
    }, 3000);
  }

  const submitInternalLogout = () => {
    let arrayUrl = window.location.href.split('.com/');

    window.location.href = arrayUrl[0] + '.com/logout';
  }

  const hasHeaderProps = () => {
    return props.envSchema.header;
  }

  return (
    <div className="app-header" style={{ backgroundColor: getBackgroundColor() }}>
      <nav className="navbar navbar-default">
        <div className="brand-container-title hidden-xs hidden-sm">
          <span className="brand-titlehead">
            {hasHeaderProps() ? props.envSchema.header.headmsg : ''}
          </span>
          <span className="brand-titlestrong">
            {getTitleStrongMessage()}
          </span>
          <span className="brand-tailmessage">
            {getTailMessage()}
          </span>
        </div>

        <div className="brand-container-title hidden-md hidden-lg">
          <span className="brand-titlehead">
            {hasHeaderProps() ? props.envSchema.header.headmsg : ''}
          </span>
          <span className="brand-titlestrong">
            {getTitleStrongMessage()}
          </span>
          <br />
          <span className="brand-tailmessage">
            {getTailMessage()}
          </span>
        </div>

        <div className="brand-container-message hidden-xs hidden-sm">
          <ul className="nav navbar-nav navbar-right">
            {getLinks()}
          </ul>
        </div>

        <div className="brand-container-message hidden-md hidden-lg">
          <ul className="nav navbar-nav navbar-right">
            {createOverflowMenu()}
          </ul>
        </div>
      </nav>

      {logoutHasBeenPressed && <Loading />}

      <ComposedModal
        open={isCompleteLogoutModalOpen}
        onClose={() => completeLogout()}>
        <ModalHeader
          title='Confirm the logout'
          iconDescription='Close Modal'
          buttonOnClick={() => completeLogout()} />
        <ModalBody className="modalBody">
          <div className="modalTextContainer">
            <div>
              Please, click the button below to complete the logout.
            </div>
            &nbsp;
            <div>
              You will be redirected to the IBM page.
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => completeLogout()}>
            Complete the logout
          </Button>
        </ModalFooter>
      </ComposedModal>
    </div>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema
  }),
  {
  }
)(PageHeader);