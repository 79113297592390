import React from 'react';
import { connect } from 'react-redux';

import { ComboBox } from 'carbon-components-react';
import { SUBSCRIPTION_MESSAGE_OPTIONS, SUBSCRIPTION_OPTIONS } from './SubscriptionHelper';
import { updateSelectedRegion } from '../../../reducers/SubscriptionReducer';

const EmailSubscriptionRegionCombobox = (props) => {
  const createRegionOptions = () => {
    return props.availableRegions.map(item => {
      return {
        text: item.name,
        id: item.name
      }
    });
  };

  return (
    <ComboBox
      placeholder={props.selectedSubscriptionOption === SUBSCRIPTION_OPTIONS[0] ? SUBSCRIPTION_MESSAGE_OPTIONS[0] : SUBSCRIPTION_MESSAGE_OPTIONS[1]}
      id='action-type-search-combo'
      items={createRegionOptions()}
      itemToString={item => (item ? item.text : '')}
      onChange={(event) => props.updateSelectedRegion(event.selectedItem ? event.selectedItem.id : '')}
    />
  );
}

export default connect(
  (state) => ({
    selectedSubscriptionOption: state.subscription.selectedSubscriptionOption,
    availableRegions: state.subscription.availableRegionsForSubscriptions
  }), {
  updateSelectedRegion
}
)(EmailSubscriptionRegionCombobox);