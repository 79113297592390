import React from 'react';
import { connect } from 'react-redux';

import { Accordion, AccordionItem, Loading, SkeletonPlaceholder } from 'carbon-components-react';
import CurrentStatusDataDisplay from './CurrentStatusDataDisplay';
import { generateCurrentStatusDownArrow, generateCurrentStatusIcon, generateCurrentStatusUpArrow } from './../Helper/graphIconGenerator';
import CurrentStatusAllServersDataDisplay from './CurrentStatusAllServersDataDisplay';
import { confirmValueExistence } from '../Helper/ComponentHelper';
import { SUBMISSION_STATUS_SUBMITTED } from '../../reducers/BaseReducer';

const CurrentStatusComponent = (props) => {
  const renderCurrentStatus = () => {
    return <div className='performanceTablesDataContainer'>
      <div className='performanceTablesDataDisplay'>
        <CurrentStatusDataDisplay
          title="Server #"
          data={props.currentStatusDataObject.CURRENT_STATUS_QUERY_SYSTEM !== undefined ?
            props.currentStatusDataObject.CURRENT_STATUS_QUERY_SYSTEM : <Loading withOverlay={false} small={true} />}
        />

        <CurrentStatusDataDisplay
          title="JVM #"
          data={props.currentStatusDataObject.CURRENT_STATUS_QUERY_JMX !== undefined ?
            props.currentStatusDataObject.CURRENT_STATUS_QUERY_JMX : <Loading withOverlay={false} small={true} />}
        />

        <CurrentStatusDataDisplay
          title="Site Status"
          data={props.currentStatusDataObject.CURRENT_STATUS_QUERY_HTTP !== undefined ?
            generateCurrentStatusIcon(getSiteStatusColor(props.currentStatusDataObject.CURRENT_STATUS_QUERY_HTTP)) : <Loading withOverlay={false} small={true} />}
        />

        <CurrentStatusDataDisplay
          title="DB Status"
          data={props.currentStatusDataObject.CURRENT_STATUS_QUERY_DB_CONNECTIONS !== undefined ?
            getDBStatusArrow(props.currentStatusDataObject.CURRENT_STATUS_QUERY_DB_CONNECTIONS) : <Loading withOverlay={false} small={true} />}
        />

        <CurrentStatusDataDisplay
          title="Concurrent Users"
          data={props.currentStatusDataObject.CURRENT_STATUS_QUERY_CONCURRENT_USER !== undefined ?
            props.currentStatusDataObject.CURRENT_STATUS_QUERY_CONCURRENT_USER : <Loading withOverlay={false} small={true} />}
        />

        <CurrentStatusDataDisplay
          title="DB Connections"
          data={props.currentStatusDataObject.CURRENT_STATUS_QUERY_DB_CONNECTIONS !== undefined ?
            props.currentStatusDataObject.CURRENT_STATUS_QUERY_DB_CONNECTIONS : <Loading withOverlay={false} small={true} />}
        />

        <CurrentStatusDataDisplay
          title="Long Running Transactions #"
          data={props.currentStatusDataObject.CURRENT_STATUS_QUERY_TRANSACTIONS !== undefined ?
            getValueWithDefault(props.currentStatusDataObject.CURRENT_STATUS_QUERY_TRANSACTIONS, 0) : <Loading withOverlay={false} small={true} />}
          color={getLongRunningAndNonStateColor(getValueWithDefault(props.currentStatusDataObject.CURRENT_STATUS_QUERY_TRANSACTIONS, 0))}
        />

        <CurrentStatusDataDisplay
          title="Non-State JVM #"
          data={props.currentStatusDataObject.CURRENT_STATUS_QUERY_JVM !== undefined ?
            getValueWithDefault(props.currentStatusDataObject.CURRENT_STATUS_QUERY_JVM, 0) : <Loading withOverlay={false} small={true} />}
          color={getLongRunningAndNonStateColor(getValueWithDefault(props.currentStatusDataObject.CURRENT_STATUS_QUERY_JVM, 0))}
        />

        <CurrentStatusDataDisplay
          title="Active Alerts"
          data={props.currentStatusDataObject.CURRENT_STATUS_QUERY_ALERT !== undefined ?
            getValueWithDefault(props.currentStatusDataObject.CURRENT_STATUS_QUERY_ALERT, 0) : <Loading withOverlay={false} small={true} />}
          color={getActiveAlertsColor(getValueWithDefault(props.currentStatusDataObject.CURRENT_STATUS_QUERY_ALERT, 0))}
        />

        <CurrentStatusDataDisplay
          title="Open SR #"
          data={props.currentStatusDataObject.CURRENT_STATUS_QUERY_SR !== undefined ?
            getValueWithDefault(props.currentStatusDataObject.CURRENT_STATUS_QUERY_SR, '-') : <Loading withOverlay={false} small={true} />}
        />
      </div>
    </div>;
  }

  const renderCurrentStatusAllServers = () => {
    return props.currentStatusAllServersObjectArray.map((object, index) =>
      <CurrentStatusAllServersDataDisplay
        key={index}
        title={object.hostName}
        cpuUsage={object.cpuUsage.toFixed(2)}
        memoryUsage={object.memoryUsage.toFixed(2)} />
    );
  }

  const getSiteStatusColor = (value) => {
    switch (value) {
      case 200:
        return "GREEN";
      case 0:
        return "YELLOW";
      case null:
        return "RED";
      default:
        return "RED";
    }
  }

  const showFormattedStatusData = (currentStatusData, queryId, stylingFunction = null) => {
    if (confirmValueExistence(currentStatusData, queryId)) {
      const data = currentStatusData[queryId][0].data;
      return stylingFunction ? stylingFunction(data) : data;
    } else {
      return <Loading withOverlay={false} small={true} />;
    }
  };  

  const getLongRunningAndNonStateColor = (value) => {
    if (value === undefined) {
      return "BLACK";
    } else if (value === null) {
      return "#ac3245";
    } else if (value < 10) {
      return "GREEN";
    } else {
      return "#F53644"
    }
  }

  const getActiveAlertsColor = (value) => {
    if (value === undefined) {
      return "BLACK";
    } if (value === null) {
      return "#ac3245";
    } else if (value === 0) {
      return "#ed8128";
    } else if (value < 20) {
      return "GREEN";
    } else {
      return "#F53644"
    }
  }

  const getDBStatusArrow = (value) => {
    if (value === null) {
      return "-";
    } else if (value < 0) {
      return generateCurrentStatusDownArrow();
    } else {
      return generateCurrentStatusUpArrow();
    }
  }

  const getValueWithDefault = (value, defaultValue) => {
    if (!value) {
      return defaultValue;
    } else {
      return value;
    }
  }

 return(
   <React.Fragment>
      {renderCurrentStatus()}
      &nbsp;
      <Accordion className="accordionContainer backgroundColor">
        <AccordionItem title='Current Status (All Servers)' key={0}>
          {props.loadCurrentStatusAllServersStatus === SUBMISSION_STATUS_SUBMITTED ?
            <div className='serverstatus'>
               <SkeletonPlaceholder />
            </div>
            :
            <div className='performanceTablesDataContainer'>
              <div className='performanceTablesDataDisplay'>
                {renderCurrentStatusAllServers()}
              </div>
            </div>
          }
        </AccordionItem>
      </Accordion>
    </React.Fragment>

 )
        }

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    currentStatusDataObject: state.performanceTables.currentStatusDataObject,
    currentStatusAllServersObjectArray: state.performanceTables.currentStatusAllServersObjectArray,
    loadCurrentStatusAllServersStatus: state.performanceTables.loadCurrentStatusAllServersStatus
  }), {
}
)(CurrentStatusComponent);