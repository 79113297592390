import React from 'react';
import { connect } from 'react-redux';

import {
  ComposedModal,
  ModalHeader,
  ModalBody
} from 'carbon-components-react';

import { NOTIFICATION_HEADERS } from '../HeaderHelper';
import { toggleFilterAreaOpened, updateSearchValue, clearFilters, updateInitialDateFilter, updateInitialTimeFilter, updateFinalDateFilter, updateFinalTimeFilter, updateStatusFiltersControl } from '../../../reducers/CommunicationsReducer';
import CommunicationsDataTable from '../CommunicationsDataTable';
import Banner from '../../Banner/Banner';

const NotificationsDataTableModal = (props) => {
  const createModalBody = () => {
    return (
      <React.Fragment>
        <Banner />
        <CommunicationsDataTable
          isNotificationData={true}
          displayCommunications={props.displayNotifications}
          filteredCommunications={props.filteredNotifications}
          headers={NOTIFICATION_HEADERS}
          initialDateFilter={props.initialDateFilter}
          initialTimeFilter={props.initialTimeFilter}
          finalDateFilter={props.finalDateFilter}
          finalTimeFilter={props.finalTimeFilter}
          updateInitialDateFilter={props.updateInitialDateFilter}
          updateInitialTimeFilter={props.updateInitialTimeFilter}
          updateFinalDateFilter={props.updateFinalDateFilter}
          updateFinalTimeFilter={props.updateFinalTimeFilter}
          statusFilters={props.statusFilters}
          updateStatusFiltersControl={props.updateStatusFiltersControl}
          categoryFilters={props.categoryFilters}
          subCategoryFilters={props.subCategoryFilters}
          searchValue={props.searchValue}
          updateCommunicationSearchValue={props.updateSearchValue}
          loadCommunicationStatus={props.loadNotificationStatus} />
      </React.Fragment>
    );
  }

  const closeModal = () => {
    clearFilters();

    props.closeModal(false);
  }

  const clearFilters = () => {
    if (props.filterAreaOpened) {
      props.toggleFilterAreaOpened();
    }

    props.updateSearchValue('');
    props.clearFilters();
  }

  return (
    <ComposedModal
      open={props.isModalOpen}
      onClose={() => closeModal()}>
      <ModalHeader
        title='Notifications'
        iconDescription='Close Modal'
        buttonOnClick={() => closeModal()} />
      <ModalBody className="modalBody scrollY">
        {createModalBody()}
      </ModalBody>
    </ComposedModal>
  );
}

export default connect(
  (state) => ({
    displayNotifications: state.communications.displayNotifications,
    filteredNotifications: state.communications.filteredNotifications,
    filterAreaOpened: state.communications.filterAreaOpened,
    initialDateFilter: state.communications.initialDateFilter,
    initialTimeFilter: state.communications.initialTimeFilter,
    finalDateFilter: state.communications.finalDateFilter,
    finalTimeFilter: state.communications.finalTimeFilter,
    searchValue: state.communications.searchValue,
    categoryFilters: state.communications.categoryFiltersControl,
    subCategoryFilters: state.communications.subCategoryFiltersControl,
    statusFilters: state.communications.statusFiltersControl,
    loadNotificationStatus: state.communications.loadNotificationStatus
  }), {
  updateSearchValue,
  toggleFilterAreaOpened,
  updateInitialDateFilter,
  updateInitialTimeFilter,
  updateFinalDateFilter,
  updateFinalTimeFilter,
  updateStatusFiltersControl,
  clearFilters
}
)(NotificationsDataTableModal);