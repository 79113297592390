export const IBM_POLICE_URL = 'https://www.ibm.com/privacy/us/en/?lnk=flg-priv-usen';

export const SUBSCRIPTION_OPTIONS = [
  'Subscribe to Notifications',
  'Unsubscribe to Notifications'
];

export const BANNER_CONTENT_SWITCHER_VALUE_ITEMS = [{
  id: 'announcement',
  text: 'Announcement'
}, {
  id: 'maintenance',
  text: 'Maintenance'
}, {
  id: 'incident',
  text: 'Incident'
}, {
  id: 'security',
  text: 'Security'
}, {
  id: 'alert',
  text: 'Alert'
}];

export const SUBSCRIPTION_MESSAGE_OPTIONS = [
  'Please select a region to subscribe to (leave empty if you want to subscribe to all regions)',
  'Please select a region to unsubscribe from (leave empty if you want to unsubscribe from all regions)'
];

export const determineInvalidEmail = (allowEmptyEmail, selectedEmail) => {
  if (!allowEmptyEmail) {
    return selectedEmail && !(new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/).test(selectedEmail.toLowerCase()));
  } else {
    return false;
  }
}