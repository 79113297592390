import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { displayDate, returnHealthStatusUpdateTimeZoneString } from './TimeHelper';

const ORGS_WITH_PERFORMANCE_TABLES_LOCATION_PRIORITY = ['11155'];

export const EXTERNAL_MONITOR_SOLUTIONS = [
  'scis',
  'eis',
  'basre'
];

export const compareAlphabetically = (a, b) => {
  if (a.text && b.text && a.text.toLowerCase() < b.text.toLowerCase())
    return -1;
  if (a.text && b.text && a.text.toLowerCase() > b.text.toLowerCase())
    return 1;
  return 0;
}

export const upperCaseFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const customSort = (cellA, cellB, { sortDirection, sortStates, locale, compare }) => {
  let dateCellA = new Date(cellA);
  let dateCellB = new Date(cellB);
  //"Calendar test X", where X is a number between 1 and 12, is acceptable for dates. It should not.
  let areDateCells = isDate(dateCellA) && isDate(dateCellB) && cellA.indexOf("Calendar test") < 0 && cellB.indexOf("Calendar test") < 0 && cellA.indexOf("calendar test") < 0 && cellB.indexOf("calendar test") < 0;

  if (sortDirection === sortStates.DESC) {
    if (areDateCells) {
      return dateCellB.getTime() - dateCellA.getTime();
    }

    return compare(cellB, cellA, locale);
  }

  if (areDateCells) {
    return dateCellA.getTime() - dateCellB.getTime();
  }

  return compare(cellA, cellB, locale);
}

const isDate = (date) => {
  return date instanceof Date && !isNaN(date);
}

export const cleanHTML = (dirtyHTML) => {
  let cleanValue = sanitizeHtml(dirtyHTML, {
    allowedTags: ['b', 'br', 'hr', 'i', 'em', 's', 'u', 'q', 'sub', 'sup', 'ol', 'li', 'ul', 'p', 'tr', 'td', 'strong', 'a', 'blockquote', 'h1', 'h2', 'h3', 'div', 'span', 'big', 'small', 'pre', 'tt', 'code', 'kbd', 'ins', 'samp', 'var', 'table', 'tbody'],
    allowedAttributes: {
      '*': ['href', 'border', 'cellpadding', 'cellspacing', 'style']
    },
    allowedStyles: {
      '*': {
        'background': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'background-color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'font-size': [/^\d+(?:px|em|%)$/],
        'font-style': [/^[A-Za-z]+$/],
        'font-family': [/^[A-Za-z-, ]+$/],
        'border': [/^\d+(?:px|em|%)\s[a-z]+\s#(0x)?[0-9a-f]+$/i, /^\d+(?:px|em|%)\s[a-z]+\s^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'padding': [/^\d+(?:px|em|%)\s\d+(?:px|em|%)$/],
        'width': [/^\d+(?:px|em|%)$/]
      }
    }
  });

  cleanValue = cleanValue.split('<td>').join('<td className="notificationMessageTD">');
  cleanValue = cleanValue.split('<br />').join('<br/>');

  if (cleanValue.indexOf('<br/>') < 0) {
    cleanValue = cleanValue.replace(/\n/ig, '<br/>');
    cleanValue = cleanValue.split('<br/><br/>').join('<br/>');
  }

  return cleanValue;
}

export const replaceHTMLTags = (htmlMessage) => {
  let returnText = removeHTML(htmlMessage);

  return returnText.replace(/&nbsp;/g, ' ');
}

export const removeHTML = (dirtyHTML) => {
  let removedHTMLValue = sanitizeHtml(dirtyHTML, { allowedTags: [], allowedAttributes: {} });

  return removedHTMLValue;
}

export const createAudienceItems = (audiences) => {
  const audienceItems = Object.keys(audiences).map(audience => {
    return { text: audiences[audience].name, id: audiences[audience].id };
  });

  return audienceItems;
}

export const filterComboItem = (object) => {
  if (object.inputValue) {
    if (object.item.text.toLowerCase().includes(object.inputValue.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}

export const createLastUpdateText = (date) => {
  return (<div className="titleDate">
    Last updated: {displayDate(returnHealthStatusUpdateTimeZoneString(date))}
  </div>)
}

export const confirmValueExistence = (object, value) => {
  return Object.keys(object).includes(value);
}

export const getTagType = (notificationCategory) => {
  switch (notificationCategory) {
    case 'Announcement':
      return 'magenta';
    case 'Incident':
      return 'purple';
    case 'Maintenance':
      return 'cyan';
    case 'Banner':
      return 'third-party';
    case 'Security':
      return 'green';
    case 'Alert':
      return 'red';
    default:
      return 'cool-gray';
  }
}

export const isCDS = (solution) => {
  return solution === 'cds';
}

export const isSCIS = (solution) => {
  return solution === 'scis';
}

export const isEIS = (solution) => {
  return solution === 'eis';
}

export const isManualMonitoring = (solution) => {
  return EXTERNAL_MONITOR_SOLUTIONS.includes(solution);
}

export const shouldShowPerformanceTablesAboveCommunications = (orgId) => {
  return ORGS_WITH_PERFORMANCE_TABLES_LOCATION_PRIORITY.includes(orgId);
}