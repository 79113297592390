import React from 'react';
import { connect } from 'react-redux';

import { Button } from 'carbon-components-react';
import { CalendarIcon, ClearFilterIcon, DownloadIcon, FilterIcon } from './ButtonIcons';
import { clearFilters, updateIsCommunicationsCalendarModalOpen, updateIsDownloadCommunicationsModalOpen } from '../../../reducers/CommunicationsReducer';

const CommunicationsToolbarButtonsRow = (props) => {
  const verifyEmptyFilters = () => {
    let isStatusFilterEmpty = props.statusFilters.every(value => value === false);
    let isCategoryFilterEmpty = props.categoryFilters.every(value => value === false);
    let isInitialDateFilterEmpty = !props.initialDateFilter && !props.initialTimeFilter;
    let isFinalDateFilterEmpty = !props.finalDateFilter && !props.finalTimeFilter;
    let isSearchBarEmpty = !props.searchValue;

    return (isStatusFilterEmpty && isCategoryFilterEmpty && isInitialDateFilterEmpty && isFinalDateFilterEmpty && isSearchBarEmpty);
  }

  const prepareToClearFilters = () => {
    if (props.filterAreaOpened) {
      props.toggleFilterAreaOpened();
    }

    props.onChangeSearch('');
    props.clearFilters();
  }

  return (
    <div className="toolbarButtonsRow">
      <div>
        <Button
          className="toolbarButton"
          renderIcon={CalendarIcon}
          iconDescription="Calendar Button"
          onClick={() => props.updateIsCommunicationsCalendarModalOpen(true)}>
          Calendar
        </Button>
        <Button
          className="toolbarButton"
          renderIcon={DownloadIcon}
          iconDescription="Download Notification Button"
          onClick={() => props.updateIsDownloadCommunicationsModalOpen(true)}>
          Download
        </Button>
      </div>
      <div>
        <Button
          className="toolbarButton"
          renderIcon={FilterIcon}
          iconDescription="Filter Button"
          onClick={() => props.toggleFilterAreaOpened()}>
          Filters
        </Button>
        <Button
          disabled={verifyEmptyFilters()}
          className="toolbarButton"
          renderIcon={ClearFilterIcon}
          iconDescription="Clear Filter Button"
          onClick={() => prepareToClearFilters()}>
          Clear
        </Button>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    initialDateFilter: state.communications.initialDateFilter,
    initialTimeFilter: state.communications.initialTimeFilter,
    finalDateFilter: state.communications.finalDateFilter,
    finalTimeFilter: state.communications.finalTimeFilter,
    categoryFilters: state.communications.categoryFiltersControl,
    subCategoryFilters: state.communications.subCategoryFiltersControl,
    statusFilters: state.communications.statusFiltersControl
  }), {
  updateIsCommunicationsCalendarModalOpen,
  updateIsDownloadCommunicationsModalOpen,
  clearFilters
}
)(CommunicationsToolbarButtonsRow);