import React, { Component } from 'react';
import { connect } from 'react-redux';

import { loadMonitorData, updateDatacentersInfoArray, updateServiceByCategoryMapArray, updateSystemTabs } from '../../reducers/HealthStatusReducer';
import { updateDate, updateMustUpdateFlag } from '../../reducers/UserReducer';

import './HealthStatus.css';
import '../Dashboard/Dashboard.css';
import HealthStatusModal from './HealthStatusModal';
import { isCDS, onlyUnique } from '../Helper/ComponentHelper';
import TitleComponent from '../Helper/TitleComponent';
import HealthStatusTabsComponent from './HealthStatusTabsComponent';

class HealthStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      monitorMap: null,
      allowedToUpdate: true,
      isHealthStatusModalOpen: window.localStorage.getItem('isHealthStatusModalOpen') === "true" || false
    };

    this.setOpen = this.setOpen.bind(this);
    this.setModalOpen = this.setModalOpen.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  componentDidUpdate(prevProps) {
    if (((!prevProps.updateFlag && this.props.updateFlag) || this.state.allowedToUpdate) && this.props.serviceType.datacenters) {
      this.setState({ allowedToUpdate: false });
      this.props.updateMustUpdateFlag(false);

      this.formatHealthStatusData(this.props.serviceType.datacenters, this.props.envSchema.solution);
    }

    if (prevProps.envSchema.id !== this.props.envSchema.id) {
      new Promise((resolve) => resolve(this.props.loadMonitorData(this.props.envSchema.id, this.props.envSchema.solution)))
        .then(() => {
          this.setState({ allowedToUpdate: true });
          clearInterval(this.state.intervalId);

          this.setState({ intervalId: setInterval(this.requestNewMonitorStatus, 60000) });
        });
    }
  }

  formatHealthStatusData = (datacenters, solution) => {
    let datacentersInfoArray = [];
    let serviceByCategoryMapArray = [];
    let systemTabs = [];

    //First, we need to determine the systemTabs
    for (var index = 0; index < datacenters.length; index++) {
      let datacenterSystemsCategory3 = datacenters[index].monitors.map(monitor => monitor.categories.cat3);

      datacenterSystemsCategory3.filter(onlyUnique);

      for (var indexJ = 0; indexJ < datacenterSystemsCategory3.length; indexJ++) {
        if (!systemTabs.includes(datacenterSystemsCategory3[indexJ])) {
          systemTabs.push(datacenterSystemsCategory3[indexJ]);
        }
      }
    }

    for (let index = 0; index < systemTabs.length; index++) {
      if (Array.isArray(datacenters)) {
        let monitorMap = new Map();
        let serviceNameAndDescriptionByCategoryMap = new Map();
        let dataInfo = datacenters.sort();
        let lastCategory = null;

        dataInfo.forEach(function (dc) {
          for (var monitor of dc.monitors) {
            //Filter the datacenter for only the category3 of the systemTabs
            if (monitor.categories.cat3 === systemTabs[index]) {
              //check if the monitor already exists in the map
              if (monitorMap.has(monitor.id)) {
                monitorMap.get(monitor.id).set(monitor.id, monitor);
                monitorMap.get(monitor.id).set(dc.id, monitor);
              } else {
                var monitorByDatacenter = new Map();
                monitorByDatacenter.set(monitor.id, monitor);
                monitorByDatacenter.set(dc.id, monitor);
                monitorMap.set(monitor.id, monitorByDatacenter);
              }
            }
          }
        });

        monitorMap.forEach(
          function (monitorByDC) {
            dataInfo.forEach(
              function (dc) {
                if (!monitorByDC.has(dc.id)) {
                  monitorByDC.set(dc.id, { id: "dummy" });
                }
              }
            );
          }
        );

        monitorMap.forEach(function (monitorByDC, monitorKey) {
          if (lastCategory === null) {
            lastCategory = monitorByDC.get(monitorKey).categories.cat3;
          }

          var serviceNameAndDescription = {
            id: monitorKey,
            name: monitorByDC.get(monitorKey).name,
            description: monitorByDC.get(monitorKey).description,
            monitorMap: monitorByDC
          };

          if (serviceNameAndDescriptionByCategoryMap.has(
            monitorByDC.get(monitorKey).categories.cat3
          )) {
            serviceNameAndDescriptionByCategoryMap
              .get(monitorByDC.get(monitorKey).categories.cat3)
              .push(serviceNameAndDescription);
          } else {
            var monitorArrayByCategory = [];

            monitorArrayByCategory.push(serviceNameAndDescription);

            serviceNameAndDescriptionByCategoryMap.set(
              monitorByDC.get(monitorKey).categories.cat3,
              monitorArrayByCategory
            );
          }
        });

        if (isCDS(solution)) {
          serviceNameAndDescriptionByCategoryMap = new Map([...serviceNameAndDescriptionByCategoryMap.entries()].sort(function (a, b) {
            if (a[0] > b[0]) {
              return -1;
            } else {
              return 1;
            }
          }));
        }

        datacentersInfoArray.push(dataInfo);
        serviceByCategoryMapArray.push(serviceNameAndDescriptionByCategoryMap);
      } else {
        datacentersInfoArray.push([]);
        serviceByCategoryMapArray.push([]);
      }
    }

    this.props.updateSystemTabs(systemTabs);
    this.props.updateDatacentersInfoArray(datacentersInfoArray);
    this.props.updateServiceByCategoryMapArray(serviceByCategoryMapArray);
  }

  requestNewMonitorStatus = () => {
    this.props.loadMonitorData(this.props.envSchema.id, this.props.envSchema.solution);

    this.props.updateDate();
  }

  getHealthStatusTitleMessage = () => {
    if (this.props.envSchema.customizedTitles && this.props.envSchema.customizedTitles.customizedHealthStatusTitle) {
      return this.props.envSchema.customizedTitles.customizedHealthStatusTitle;
    } else {
      return "Health Status";
    }
  }

  setOpen = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  }

  setModalOpen = (value) => {
    window.localStorage.setItem('isHealthStatusModalOpen', value);

    this.setState({ isHealthStatusModalOpen: value });
  }

  render() {
    return (
      <div className="pageComponentArea">
        <TitleComponent
          open={this.state.open}
          setOpen={this.setOpen}
          message={this.getHealthStatusTitleMessage()}
          setModalOpen={this.setModalOpen} />
        {this.state.open &&
          <HealthStatusTabsComponent />
        }

        <HealthStatusModal
          isModalOpen={this.state.isHealthStatusModalOpen}
          closeModal={this.setModalOpen} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    serviceType: state.user.selectedServiceType,
    updateFlag: state.user.mustUpdateHealthStatus,
    monitorData: state.healthStatus.monitorData,
    tabIndex: state.healthStatus.tabIndex,
    updateDatacentersInfoArray: state.healthStatus.updateDatacentersInfoArray
  }), {
  loadMonitorData,
  updateSystemTabs,
  updateMustUpdateFlag,
  updateDate,
  updateDatacentersInfoArray,
  updateServiceByCategoryMapArray
}
)(HealthStatus);