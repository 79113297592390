import React from 'react';

const moment = require('moment-timezone');

export const MONTH_NAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const SHORT_MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const localeTimeZone = moment.tz.guess();

export const determineInitialPredeterminedDate = (initialDate, timeValue) => {
  if (Number.isInteger(timeValue)) {
    return new Date(initialDate.setHours(initialDate.getHours() - timeValue));
  } else {
    if (timeValue === 'yesterday') {
      initialDate.setDate(initialDate.getDate() - 1);
    } else if (timeValue === 'this-week') {
      let day = initialDate.getDay();
      let diff = initialDate.getDate() - day + (day === 0 ? -6 : 1);

      initialDate.setDate(diff);
    } else if (timeValue === 'this-month') {
      initialDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1);
    } else if (timeValue === 'last-6-months') {
      initialDate = new Date(initialDate.getFullYear(), initialDate.getMonth() - 6, initialDate.getDate());
    } else if (timeValue === 'last-12-months') {
      initialDate = new Date(initialDate.getFullYear() - 1, initialDate.getMonth(), initialDate.getDate());
    } else if (timeValue === 'this-year') {
      initialDate = new Date(initialDate.getFullYear(), 0, 1);
    }

    initialDate.setHours(0);
    initialDate.setMinutes(0);
    initialDate.setSeconds(0);

    return initialDate;
  }
}

export const checkSameDay = (date1, date2) => {
  return (date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear());
}

export const getMonday = (dateInMilliseconds) => {
  let day, diff;
  let date = new Date(dateInMilliseconds);

  day = date.getDay();
  diff = date.getDate() - day + (day === 0 ? -6 : 1);

  date.setDate(diff);

  return date.getTime();
}

export const displayTimeFormat = (date) => {
  return SHORT_MONTH_NAMES[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
}

export const returnLocaleTimeZoneString = (date) => {
  let localeDateString = moment.tz(date, localeTimeZone).format("MMM D, YYYY, h:mm:ss A");

  return localeDateString;
}

export const returnHealthStatusUpdateTimeZoneString = (date) => {
  let localeDateString = moment.tz(date, localeTimeZone).format("MM/DD/YYYY, h:mm A");

  return localeDateString;
}

export const returnTimeZone = () => {
  return localeTimeZone.split('/')[1].split('_').join(' ') + ' Time';
}

export const displayDate = (dateString) => {
  let timezoneDisplay = localeTimeZone.split('/')[1].split('_').join(' ') + ' Time';

  return <div title={timezoneDisplay}>
    {dateString}
  </div>
}