import React from 'react';
import { connect } from 'react-redux';

import { Tile } from 'carbon-components-react';

const CurrentStatusAllServersDataDisplay = (props) => {
  const determineColor = () => {
    if (props.cpuUsage >= 95 || props.memoryUsage >= 95) {
      return "#F53636";
    } else if (props.cpuUsage >= 90 || props.memoryUsage >= 90) {
      return "#ED8128";
    } else if (props.cpuUsage === null || props.memoryUsage >= 90) {
      return "#808080";
    } else {
      return "GREEN";
    }
  }

  return (
    <Tile key={props.key} className='dataAllServersTile' style={{ backgroundColor: determineColor() }}>
      <div className='dataAllServersTitle'>
        {props.title}
      </div>
      <div className='dataAllServersValue'>
        CPU Usage: {props.cpuUsage} %
      </div>
      <div className='dataAllServersValue'>
        Memory Usage: {props.memoryUsage} %
      </div>
    </Tile>
  );
}

export default connect(
  (state) => ({
  }), {
}
)(CurrentStatusAllServersDataDisplay);