import React from 'react';
import { connect } from "react-redux";

import { Button } from 'carbon-components-react';
import { getMonitorNamesData, toggleTimeControlAreaOpened, toggleTooltipEnabled, updateTriggerToUpdateCharts } from '../../reducers/StatisticsChartsReducer';
import { TIME_CONTROL_OPTIONS } from './TimeControlHelper';

const StatisticsChartButtonsRow = (props) => {
  const createTimeControlButtonText = () => {
    if (props.selectedTimeOptionTab === 1 && props.initialFilterDate && props.initialFilterTime && props.finalFilterDate && props.finalFilterTime) {
      let initialDate = new Date(props.initialFilterDate);
      let finalDate = new Date(props.finalFilterDate);

      initialDate.setHours(props.initialFilterTime.split(':')[0]);
      initialDate.setMinutes(props.initialFilterTime.split(':')[1]);
      finalDate.setHours(props.finalFilterTime.split(':')[0]);
      finalDate.setMinutes(props.finalFilterTime.split(':')[1]);

      return initialDate.toLocaleDateString() + ' ' + initialDate.toLocaleTimeString() + ' - ' + finalDate.toLocaleDateString() + ' ' + finalDate.toLocaleTimeString();
    } else {
      return TIME_CONTROL_OPTIONS[props.selectedTimeControlOption].text;
    }
  }

  const refreshCharts = () => {
    props.getMonitorNamesData(props.envSchema.id, true);

    props.updateTriggerToUpdateCharts(true);
  }

  return (
    <div className="reverseRow statisticButtonRow">
      <Button
        className="statisticButton"
        onClick={() => refreshCharts()}>
        Refresh
      </Button>
      <Button
        className="statisticButton"
        onClick={() => props.toggleTimeControlAreaOpened()}
        icon={props.timeControlAreaOpened ? 'caret--up' : 'caret--down'}>
        {createTimeControlButtonText()}
      </Button>
      <Button
        className="statisticButton"
        onClick={() => props.toggleTooltipEnabled()}>
        {props.tooltipEnabled ? 'Disable Tooltips' : 'Enable Tooltips'}
      </Button>
    </div>
  );
}

export default connect(
  (state) => ({
    envSchema: state.user.environmentSchema,
    monitorNames: state.statisticCharts.monitorNamesData,
    selectedTimeOptionTab: state.statisticCharts.selectedTimeOptionTab,
    selectedTimeControlOption: state.statisticCharts.selectedTimeControlOption,
    tooltipEnabled: state.statisticCharts.tooltipEnabled,
    timeControlAreaOpened: state.statisticCharts.timeControlAreaOpened,
    initialFilterDate: state.statisticCharts.initialFilterDate,
    initialFilterTime: state.statisticCharts.initialFilterTime,
    finalFilterDate: state.statisticCharts.finalFilterDate,
    finalFilterTime: state.statisticCharts.finalFilterTime,
    loadChartDataStatus: state.statisticCharts.loadChartDataStatus
  }), {
  getMonitorNamesData,
  updateTriggerToUpdateCharts,
  toggleTooltipEnabled,
  toggleTimeControlAreaOpened
}
)(StatisticsChartButtonsRow);