export const treatEnvironment = (environment) => {
  var serviceTypesMap = new Map();

  for (var j = 0; j < environment.datacenters.length; j++) {
    for (var k = 0; k < environment.datacenters[j].monitors.length; k++) {
      //get the serviceType from category3	
      var serviceTypeName = environment.datacenters[j].monitors[k].categories.cat1;
      var serviceType, monitors;

      //if(environment.id === "sncf") break;	
      //check if does not exists the key the service type map	
      if (!serviceTypesMap.has(serviceTypeName)) {
        //create a new Monitor with the current monitor data	
        //create a monitors Set	
        monitors = [];
        monitors.push(environment.datacenters[j].monitors[k]);
        //add the monitor to the monitors	
        var datacenter = {
          id: environment.datacenters[j].id,
          name: environment.datacenters[j].name,
          desc: environment.datacenters[j].desc,
          monitors: monitors
        };
        //create a new datacenter Set	
        var datacenters = new Map();
        datacenters.set(environment.datacenters[j].id, datacenter);
        //add the monitors Set to the Datancenter object	
        //add a new Datacenter to the datacenter Set	
        //create a new ServiceType object	
        //add the datacenters set to the	
        serviceType = {
          name: serviceTypeName,
          datacenters: datacenters
        };
        //create the key	
        serviceTypesMap.set(serviceTypeName, serviceType);
      } else {
        //get the ServiceType	
        serviceType = serviceTypesMap.get(serviceTypeName);
        var dcs = serviceType.datacenters;
        //get the datacenters	
        //check if the current datacenter is in the list	
        if (dcs.has(environment.datacenters[j].id)) {
          var dc = dcs.get(environment.datacenters[j].id);
          dc.monitors.push(environment.datacenters[j].monitors[k]);
        } else {
          monitors = [];
          monitors.push(environment.datacenters[j].monitors[k]);
          dcs.set(environment.datacenters[j].id, {
            id: environment.datacenters[j].id,
            name: environment.datacenters[j].name,
            desc: environment.datacenters[j].desc,
            monitors: monitors
          });
        }
      }
    }
  }

  environment.serviceTypes = serviceTypesMap;

  return environment;
}

export const createSchema = (environment) => {
  var org = treatEnvironment(environment);
  var headers = org.header;
  var serviceTypesJsonArray = [];

  if (org.type === 'dedicated') {
    if (headers.hyperlinks) {
      headers.hyperlinks.push({
        desc: 'Logout',
        title: 'Logout of CDS',
        isLogoutButton: true
      });
    } else {
      headers.hyperlinks = [{
        desc: 'Logout',
        title: 'Logout of CDS',
        isLogoutButton: true
      }]
    }
  }

  for (var [key, serviceType] of org.serviceTypes.entries()) {
    var datacentersJsonArray = [];

    for (var [dcKey, dc] of serviceType.datacenters.entries()) {

      var datacentersJson = {
        "id": dc.id,
        "name": dc.name,
        "desc": dc.desc,
        "monitors": dc.monitors
      };

      datacentersJsonArray.push(datacentersJson);
    }

    var serviceTypeJson = {
      "name": serviceType.name,
      "datacenters": datacentersJsonArray
    };

    serviceTypesJsonArray.push(serviceTypeJson);
  }

  return {
    "org": org.org,
    "id": org.id,
    "type": org.type,
    "dashboardType": org.dashboardType,
    "announcements": org.announcements,
    "customerType": org.customerType,
    "tabs": org.tabs,
    "name": org.name,
    "serviceTypes": serviceTypesJsonArray,
    "headerMessage": org.headerMessage,
    "header": headers,
    "footer": org.footer,
    "logo": org.logo,
    "solution": org.solution,
    "customizedTitles": org.customizedTitles,
    "visibility": org.visibility,
    "performanceTables": org.performanceTables,
    "healthStatusDefinition": org.healthStatusDefinition,
    "subscriptionCategories": org.subscriptionCategoryAttributes ? org.subscriptionCategoryAttributes : []
  };
}