import React from 'react';
import { connect } from 'react-redux';

import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ComboBox,
  Link
} from 'carbon-components-react';

import { closeModal, updateFileTypeAction } from '../../reducers/NoticeAPIReducer';

const filetypes = [
  { id: 'json', text: 'JSON' },
  { id: 'xml', text: 'XML' },
  { id: 'yaml', text: 'YAML' }
];

const NoticeAPIModal = (props) => {
  const createBody = () => {
    return (
      <div className="modalTextContainer">
        <div>
          The Notice API will allow the retrieval of notifications in the format of your choice.
        </div>
        &nbsp;
        <div className='noticeComboboxArea'>
          <ComboBox
            className="filetypeCombobox"
            placeholder='Select the file type'
            id='file-type-combo'
            items={filetypes}
            light
            initialSelectedItem={filetypes.filter(value => value.id === props.fileType)[0]}
            itemToString={item => (item ? item.text : '')}
            onChange={(event) => checkBeforeRequestingFileType(event.selectedItem)}
          />
        </div>
        &nbsp;
        <div>
          The link for the API is:
        </div>
        &nbsp;
        <div>
          {createDisplayUrl()}
        </div>
      </div>
    );
  }

  const createDisplayUrl = () => {
    let url = window.location.href;
    let displayUrl = '';

    if (props.fileType) {
      displayUrl += url.split('.com')[0] + '.com/announcement/iotm/api/v1/ext/' + props.encryptedEnvId + '/notices.' + props.fileType;
    }

    return (
      <Link
        className="formItems"
        href={displayUrl}
        target="_blank">
        {displayUrl}
      </Link>
    );
  }

  const checkBeforeRequestingFileType = (selectedItem) => {
    if (selectedItem) {
      props.updateFileTypeAction(selectedItem.id);
    } else {
      props.updateFileTypeAction();
    }
  }

  return (
    <ComposedModal
      open={props.openModal}
      onClose={() => props.closeModal()}>
      <ModalHeader
        title='Get Notices'
        iconDescription='Close Modal'
        buttonOnClick={() => props.closeModal()} />
      <ModalBody className="noticeAPIModal">
        {createBody()}
      </ModalBody>
    </ComposedModal>
  );
}

export default connect(
  (state) => ({
    openModal: state.noticeAPI.openNoticeModal,
    url: state.noticeAPI.url,
    fileType: state.noticeAPI.fileType,
    encryptedEnvId: state.noticeAPI.encryptedEnvId
  }), {
  closeModal,
  updateFileTypeAction
}
)(NoticeAPIModal);