import { fetchBigTables, fetchCurrentServices, fetchCurrentServicesAllServers, fetchWarningDetails } from "../lib/PerformanceTablesServices"
import { SUBMISSION_STATUS_FAILED, SUBMISSION_STATUS_INITIAL_STATUS, SUBMISSION_STATUS_SUBMITTED, SUBMISSION_STATUS_SUCCESS } from "./BaseReducer";

const initState = {
  bigTableValues: [],
  currentStatusDataObject: {},
  currentStatusAllServersObjectArray: [],
  warningDetailsDataObject: {},
  selectedMonitorId: '',
  loadBigTableStatus: SUBMISSION_STATUS_INITIAL_STATUS,
  loadCurrentStatusAllServersStatus: SUBMISSION_STATUS_INITIAL_STATUS,
  loadWarningDetailsStatus: SUBMISSION_STATUS_INITIAL_STATUS,
  bigTableFilterAreaOpened: false,
  bigTableInitialDateFilter: '',
  bigTableInitialTimeFilter: '',
  bigTableFinalDateFilter: '',
  bigTableFinalTimeFilter: '',
  bigTableCaption: 'Displaying latest values',
  isDownloadModalOpen: false
}

const PERFORMANCE_TABLES_LOAD_BIG_TABLE_VALUES = 'PERFORMANCE_TABLES_LOAD_BIG_TABLE_VALUES';
export const loadBigTableValuesAction = (newValue) => ({ type: PERFORMANCE_TABLES_LOAD_BIG_TABLE_VALUES, payload: newValue });

const PERFORMANCE_TABLES_LOAD_CURRENT_STATUS_VALUES = 'PERFORMANCE_TABLES_LOAD_CURRENT_STATUS_VALUES';
export const loadCurrentStatusValuesAction = (newValueObject) => ({ type: PERFORMANCE_TABLES_LOAD_CURRENT_STATUS_VALUES, payload: newValueObject });

const PERFORMANCE_TABLES_LOAD_CURRENT_STATUS_ALL_SERVERS_VALUES = 'PERFORMANCE_TABLES_LOAD_CURRENT_STATUS_ALL_SERVERS_VALUES';
export const loadCurrentStatusAllServersValuesAction = (newValueObject) => ({ type: PERFORMANCE_TABLES_LOAD_CURRENT_STATUS_ALL_SERVERS_VALUES, payload: newValueObject });

const PERFORMANCE_TABLES_LOAD_WARNING_DETAILS_VALUES = 'PERFORMANCE_TABLES_LOAD_WARNING_DETAILS_VALUES';
export const loadWarningDetailsValuesAction = (newValueObject) => ({ type: PERFORMANCE_TABLES_LOAD_WARNING_DETAILS_VALUES, payload: newValueObject });

const PERFORMANCE_TABLES_UPDATE_SELECTED_MONITOR_ID = 'PERFORMANCE_TABLES_UPDATE_SELECTED_MONITOR_ID';
export const updateSelectedMonitorIdAction = (newValue) => ({ type: PERFORMANCE_TABLES_UPDATE_SELECTED_MONITOR_ID, payload: newValue });

const PERFORMANCE_TABLES_UPDATE_BIG_TABLE_DATA_STATUS = 'PERFORMANCE_TABLES_UPDATE_BIG_TABLE_DATA_STATUS';
export const updateBigTableDataStatusAction = (newStatus) => ({ type: PERFORMANCE_TABLES_UPDATE_BIG_TABLE_DATA_STATUS, payload: newStatus });

const PERFORMANCE_TABLES_UPDATE_CURRENT_STATUS_ALL_SERVERS_STATUS = 'PERFORMANCE_TABLES_UPDATE_CURRENT_STATUS_ALL_SERVERS_STATUS';
export const updateCurrentStatusAllServersStatusAction = (newStatus) => ({ type: PERFORMANCE_TABLES_UPDATE_CURRENT_STATUS_ALL_SERVERS_STATUS, payload: newStatus });

const PERFORMANCE_TABLES_UPDATE_WARNING_DETAILS_STATUS = 'PERFORMANCE_TABLES_UPDATE_WARNING_DETAILS_STATUS';
export const updateWarningDetailsAction = (newStatus) => ({ type: PERFORMANCE_TABLES_UPDATE_WARNING_DETAILS_STATUS, payload: newStatus });

const PERFORMANCE_TABLES_RESET_DATA = 'PERFORMANCE_TABLES_RESET_DATA';
export const resetDataAction = () => ({ type: PERFORMANCE_TABLES_RESET_DATA, payload: null });

const PERFORMANCE_TABLES_TOGGLE_BIG_TABLE_FILTER_AREA_OPENED = 'PERFORMANCE_TABLES_TOGGLE_BIG_TABLE_FILTER_AREA_OPENED';
export const toggleBigTableFilterAreaOpenedAction = () => ({ type: PERFORMANCE_TABLES_TOGGLE_BIG_TABLE_FILTER_AREA_OPENED, payload: '' });

const PERFORMANCE_TABLES_UPDATE_BIG_TABLE_INITIAL_DATE_FILTER = 'PERFORMANCE_TABLES_UPDATE_BIG_TABLE_INITIAL_DATE_FILTER';
export const updateBigTableInitialDateFilterAction = (newInitialFilterDate) => ({ type: PERFORMANCE_TABLES_UPDATE_BIG_TABLE_INITIAL_DATE_FILTER, payload: newInitialFilterDate });

const PERFORMANCE_TABLES_UPDATE_BIG_TABLE_INITIAL_TIME_FILTER = 'PERFORMANCE_TABLES_UPDATE_BIG_TABLE_INITIAL_TIME_FILTER';
export const updateBigTableInitialTimeFilterAction = (newInitialFilterTime) => ({ type: PERFORMANCE_TABLES_UPDATE_BIG_TABLE_INITIAL_TIME_FILTER, payload: newInitialFilterTime });

const PERFORMANCE_TABLES_UPDATE_BIG_TABLE_FINAL_DATE_FILTER = 'PERFORMANCE_TABLES_UPDATE_BIG_TABLE_FINAL_DATE_FILTER';
export const updateBigTableFinalDateFilterAction = (newFinalFilterDate) => ({ type: PERFORMANCE_TABLES_UPDATE_BIG_TABLE_FINAL_DATE_FILTER, payload: newFinalFilterDate });

const PERFORMANCE_TABLES_UPDATE_BIG_TABLE_FINAL_TIME_FILTER = 'PERFORMANCE_TABLES_UPDATE_BIG_TABLE_FINAL_TIME_FILTER';
export const updateBigTableFinalTimeFilterAction = (newFinalFilterTime) => ({ type: PERFORMANCE_TABLES_UPDATE_BIG_TABLE_FINAL_TIME_FILTER, payload: newFinalFilterTime });

const PERFORMANCE_TABLES_UPDATE_BIG_TABLE_FILTERS = 'PERFORMANCE_TABLES_UPDATE_BIG_TABLE_FILTERS';
export const updateBigTableFiltersAction = (newBigTableFilters) => ({ type: PERFORMANCE_TABLES_UPDATE_BIG_TABLE_FILTERS, payload: newBigTableFilters });

const PERFORMANCE_TABLES_UPDATE_BIG_TABLE_CAPTION = 'PERFORMANCE_TABLES_UPDATE_BIG_TABLE_CAPTION';
export const updateBigTableCaptionAction = (newBigTableCaption) => ({ type: PERFORMANCE_TABLES_UPDATE_BIG_TABLE_CAPTION, payload: newBigTableCaption });

const PERFORMANCE_TABLES_UPDATE_IS_DOWNLOAD_MODAL_OPEN = 'PERFORMANCE_TABLES_UPDATE_IS_DOWNLOAD_MODAL_OPEN';
export const updateIsDownloadModalOpenAction = (newValue) => ({ type: PERFORMANCE_TABLES_UPDATE_IS_DOWNLOAD_MODAL_OPEN, payload: newValue });


const transformCurrentStatusData = (currentStatusData) => {
  const transformedData = {};

  Object.keys(currentStatusData).forEach(key => {
    const entry = currentStatusData[key];
    if (entry.length === 1 && 'data' in entry[0]) {
      transformedData[key] = entry[0].data;
    } else {
      transformedData[key] = 0;
    }
  });

  return transformedData;
}

export const requestBigTableValues = (monitorId, startDate, endDate) => {
  return (dispatch) => {
    dispatch(updateBigTableDataStatusAction(SUBMISSION_STATUS_SUBMITTED));

    fetchBigTables(monitorId, startDate, endDate)
      .then(data => {
        if (Array.isArray(data) && typeof (data[0]) === 'object') {
          dispatch(updateBigTableDataStatusAction(SUBMISSION_STATUS_SUCCESS));

          dispatch(loadBigTableValuesAction(data));
        } else {
          dispatch(updateBigTableDataStatusAction(SUBMISSION_STATUS_FAILED));
          dispatch(loadBigTableValuesAction([]));
        }
      })
      .catch(error => {
        dispatch(updateBigTableDataStatusAction(SUBMISSION_STATUS_FAILED));
        dispatch(loadBigTableValuesAction([]));
      });
  }
}

export const requestCurrentStatusData = (monitorId) => {
  return (dispatch) => {
    fetchCurrentServices(monitorId)
      .then(data => {
        if (data !== null) {
          dispatch(loadCurrentStatusValuesAction(transformCurrentStatusData(data)));
        } else {
          dispatch(loadCurrentStatusValuesAction([]));
        }
      })
      .catch(error => {
        dispatch(loadCurrentStatusValuesAction([]));
      });
  }
}



export const requestCurrentStatusAllServersData = (monitorId) => {
  return (dispatch) => {
    dispatch(updateCurrentStatusAllServersStatusAction(SUBMISSION_STATUS_SUBMITTED));

    fetchCurrentServicesAllServers(monitorId)
      .then(data => {
        if (Array.isArray(data) && typeof (data[0]) === 'object') {
          dispatch(loadCurrentStatusAllServersValuesAction(data));
          dispatch(updateCurrentStatusAllServersStatusAction(SUBMISSION_STATUS_SUCCESS));
        } else {
          dispatch(loadCurrentStatusAllServersValuesAction([]));
          dispatch(updateCurrentStatusAllServersStatusAction(SUBMISSION_STATUS_FAILED));
        }
      })
      .catch(error => {
        dispatch(loadCurrentStatusAllServersValuesAction([]));
        dispatch(updateCurrentStatusAllServersStatusAction(SUBMISSION_STATUS_FAILED));
      });
  }
} 
export const requestWarningStatusData = (monitorId) => {
  return (dispatch) => {
    dispatch(updateWarningDetailsAction(SUBMISSION_STATUS_SUBMITTED));

    fetchWarningDetails(monitorId)
      .then(data => {
        if (data !== null) {
          dispatch(loadWarningDetailsValuesAction(data));
          dispatch(updateWarningDetailsAction(SUBMISSION_STATUS_SUCCESS));
        } else {
          dispatch(loadWarningDetailsValuesAction([]));
          dispatch(updateWarningDetailsAction(SUBMISSION_STATUS_FAILED));
        }
      })
      .catch(error => {
        dispatch(loadWarningDetailsValuesAction([]));
        dispatch(updateWarningDetailsAction(SUBMISSION_STATUS_FAILED));
      });
  }
}

export const treatWarningData = (data) => {
  let result = [];

  for (let index = 0; index < data.length; index++) {
    let label = data[index].label;

    for (let indexJ = 0; indexJ < data[index].data.length; indexJ++) {
      if (index === 0) {
        result.push({ [label]: data[index].data[indexJ] });
      } else {
        result[indexJ][label] = data[index].data[indexJ];
      }
    }
  }

  return result;
}

export const fetchBigTable = (filters) => {
  return (dispatch) => {
    dispatch(updateBigTableFiltersAction(filters));
  }
}

export const clearBigTableFilters = () => {
  return (dispatch) => {
    dispatch(updateBigTableInitialDateFilterAction(''));
    dispatch(updateBigTableInitialTimeFilterAction(''));
    dispatch(updateBigTableFinalDateFilterAction(''));
    dispatch(updateBigTableFinalTimeFilterAction(''));
    dispatch(updateBigTableCaptionAction('Displaying latest values'));
  }
}

export const updateSelectedMonitorId = (value) => {
  return (dispatch) => {
    dispatch(updateSelectedMonitorIdAction(value));
  }
}

export const resetPerformanceTablesData = () => {
  return (dispatch) => {
    dispatch(resetDataAction());
  }
}

export const toggleBigTableFilterAreaOpened = () => {
  return (dispatch) => {
    dispatch(toggleBigTableFilterAreaOpenedAction());
  }
}

export const updateBigTableInitialDateFilter = (value) => {
  return (dispatch) => {
    dispatch(updateBigTableInitialDateFilterAction(value));
  }
}

export const updateBigTableInitialTimeFilter = (value) => {
  return (dispatch) => {
    dispatch(updateBigTableInitialTimeFilterAction(value));
  }
}

export const updateBigTableFinalDateFilter = (value) => {
  return (dispatch) => {
    dispatch(updateBigTableFinalDateFilterAction(value));
  }
}

export const updateBigTableFinalTimeFilter = (value) => {
  return (dispatch) => {
    dispatch(updateBigTableFinalTimeFilterAction(value));
  }
}

export const updateBigTableCaption = (value) => {
  return (dispatch) => {
    dispatch(updateBigTableCaptionAction(value));
  }
}

export const updateIsDownloadModalOpen = (value) => {
  return (dispatch) => {
    dispatch(updateIsDownloadModalOpenAction(value));
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case PERFORMANCE_TABLES_UPDATE_BIG_TABLE_DATA_STATUS:
      return { ...state, loadBigTableStatus: action.payload }

    case PERFORMANCE_TABLES_UPDATE_CURRENT_STATUS_ALL_SERVERS_STATUS:
      return { ...state, loadCurrentStatusAllServersStatus: action.payload }

    case PERFORMANCE_TABLES_UPDATE_WARNING_DETAILS_STATUS:
      return { ...state, loadWarningDetailsStatus: action.payload }

    case PERFORMANCE_TABLES_LOAD_BIG_TABLE_VALUES:
      return { ...state, bigTableValues: action.payload }

    case PERFORMANCE_TABLES_LOAD_CURRENT_STATUS_VALUES:
      return { ...state, currentStatusDataObject: { ...state.currentStatusDataObject, ...action.payload } }

    case PERFORMANCE_TABLES_LOAD_CURRENT_STATUS_ALL_SERVERS_VALUES:
      return { ...state, currentStatusAllServersObjectArray: action.payload }

    case PERFORMANCE_TABLES_LOAD_WARNING_DETAILS_VALUES:
      return { ...state, warningDetailsDataObject: { ...state.warningDetailsDataObject, ...action.payload } }

    case PERFORMANCE_TABLES_UPDATE_SELECTED_MONITOR_ID:
      return { ...state, selectedMonitorId: action.payload }

    case PERFORMANCE_TABLES_TOGGLE_BIG_TABLE_FILTER_AREA_OPENED:
      return { ...state, bigTableFilterAreaOpened: !state.bigTableFilterAreaOpened }

    case PERFORMANCE_TABLES_UPDATE_BIG_TABLE_INITIAL_DATE_FILTER:
      return { ...state, bigTableInitialDateFilter: action.payload }

    case PERFORMANCE_TABLES_UPDATE_BIG_TABLE_INITIAL_TIME_FILTER:
      return { ...state, bigTableInitialTimeFilter: action.payload }

    case PERFORMANCE_TABLES_UPDATE_BIG_TABLE_FINAL_DATE_FILTER:
      return { ...state, bigTableFinalDateFilter: action.payload }

    case PERFORMANCE_TABLES_UPDATE_BIG_TABLE_FINAL_TIME_FILTER:
      return { ...state, bigTableFinalTimeFilter: action.payload }

    case PERFORMANCE_TABLES_UPDATE_BIG_TABLE_CAPTION:
      return { ...state, bigTableCaption: action.payload }

    case PERFORMANCE_TABLES_RESET_DATA:
      return { ...initState }

    case PERFORMANCE_TABLES_UPDATE_IS_DOWNLOAD_MODAL_OPEN:
      return { ...state, isDownloadModalOpen: action.payload }

    default:
      return state;
  }
}