import React from 'react';
import { connect } from 'react-redux';

import { Tile } from 'carbon-components-react';

const CurrentStatusDataDisplay = (props) => {
  const generateStyle = () => {
    if (props.color) {
      return { color: props.color };
    } else {
      return {};
    }
  }

  return (
    <Tile className='dataTile'>
      <div className='dataTitle'>
        {props.title}
      </div>
  
      <br></br>
      <div className='dataValue' style={generateStyle()}>
        {props.data}
      </div>
    </Tile>
  );
}

export default connect(
  (state) => ({
  }), {
}
)(CurrentStatusDataDisplay);