import { returnLocaleTimeZoneString } from "../components/Helper/TimeHelper";
import { fetchIncidents } from "../lib/IncidentServices";
import { SUBMISSION_STATUS_FAILED, SUBMISSION_STATUS_INITIAL_STATUS, SUBMISSION_STATUS_SUBMITTED, SUBMISSION_STATUS_SUCCESS } from "./BaseReducer";

const initState = {
  incidentList: [],
  incidentFetchStatus: SUBMISSION_STATUS_INITIAL_STATUS
}

const INCIDENT_MANAGER_LOAD_INCIDENT_LIST = 'INCIDENT_MANAGER_LOAD_INCIDENT_LIST';
export const loadIncidentListAction = (incidentList) => ({ type: INCIDENT_MANAGER_LOAD_INCIDENT_LIST, payload: incidentList });

const INCIDENT_MANAGER_UPDATE_INCIDENT_FETCH_STATUS = 'INCIDENT_MANAGER_UPDATE_INCIDENT_FETCH_STATUS';
export const updateIncidentFetchStatus = (newStatus) => ({ type: INCIDENT_MANAGER_UPDATE_INCIDENT_FETCH_STATUS, payload: newStatus });

export const getIncidentList = (solutionId) => {
  return (dispatch) => {
    dispatch(updateIncidentFetchStatus(SUBMISSION_STATUS_SUBMITTED));

    fetchIncidents(solutionId)
      .then(data => {
        if (Array.isArray(data)) {
          let formattedData = data.map(model => {
            return {
              ...model,
              id: model.incidentId,
              incidentTitle: 'Test',
              realUpdatedBy: model.updatedBy ? model.updatedBy : model.createdBy,
              startDate: returnLocaleTimeZoneString(model.startDate),
              updateDate: model.updateDate ? returnLocaleTimeZoneString(model.updateDate) : undefined,
              realDate: model.updateDate ? model.updateDate : model.startDate
            }
          });

          dispatch(loadIncidentListAction(formattedData));
          dispatch(updateIncidentFetchStatus(SUBMISSION_STATUS_SUCCESS));
        } else {
          dispatch(loadIncidentListAction([]));
          dispatch(updateIncidentFetchStatus(SUBMISSION_STATUS_FAILED));
        }
      }).catch(error => {
        dispatch(loadIncidentListAction([]));
        dispatch(updateIncidentFetchStatus(SUBMISSION_STATUS_FAILED));
      });
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case INCIDENT_MANAGER_LOAD_INCIDENT_LIST:
      return {
        ...state,
        incidentList: action.payload
      }
    case INCIDENT_MANAGER_UPDATE_INCIDENT_FETCH_STATUS:
      return {
        ...state,
        incidentFetchStatus: action.payload
      }
    default:
      return state;
  }
}